import { createGlobalStyle } from 'styled-components';
import Roboto500 from './roboto-v30-latin-500.woff';
import Roboto500_2 from './roboto-v30-latin-500.woff2';
import Roboto700 from './roboto-v30-latin-700.woff';
import Roboto700_2 from './roboto-v30-latin-700.woff2';
import RobotoRegular from './roboto-v30-latin-regular.woff';
import RobotoRegular_2 from './roboto-v30-latin-regular.woff2';
import Roboto100 from './roboto-v30-latin-100.woff';
import Roboto100_2 from './roboto-v30-latin-100.woff2';

export const CustomFonts = createGlobalStyle`
    /* roboto-regular - latin */
    @font-face {
        font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        src: url(${RobotoRegular_2}) format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
            url(${RobotoRegular}) format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }
    /* roboto-500 - latin */
    @font-face {
        font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        src: url(${Roboto500_2}) format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
            url(${Roboto500}) format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }
    /* roboto-700 - latin */
    @font-face {
        font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        src: url(${Roboto700_2}) format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
            url(${Roboto700}) format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }

    /* roboto-100 */
    @font-face {
        font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 100;
        src: url(${Roboto100_2}) format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
            url(${Roboto100}) format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }

        /* roboto-300 */
        @font-face {
        font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        src: url(${Roboto100_2}) format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
            url(${Roboto100}) format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }
`;
