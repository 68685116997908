import { PrsonalWrapper } from '@prsonal/website/shared/components';
import { Grid, Collapse, Col, Row } from 'antd';
import styled from 'styled-components';
import CollapseLogo from './../../icons/collapse.svg';
import ExpandsLogo from './../../icons/expand.svg';
import lowRiskLogo from './../../icons/low-risk.svg';
import mediumRiskLogo from './../../icons/medium-risk.svg';
import heightenedRiskLogo from './../../icons/heightened-risk.svg';
import highRiskLogo from './../../icons/high-risk.svg';
import riskPercentage from './../../icons/risk-percent.svg';
import CollapsableContent from './components/collapsable-content/collapsable-content';
import TextContent from './components/text-content/text-content';

const { useBreakpoint } = Grid;

const expandableText = {
  lav: 'Kvinder med lav risiko bliver inviteret til screening hvert fjerde år – i stedet for hvert andet år.',
  mellem:
    'Kvinder med mellem risiko bliver inviteret til screening hvert andet år, ligesom de gør i det nuværende program.',
  forhøjet:
    'Kvinder med forhøjet risiko bliver inviteret til screening hvert år og undersøgt med både almindelig og udvidet mammografi (3D-mammografi).',
  høj: 'Kvinder med høj risiko bliver inviteret til mammografi hvert år og vil få supplerende MR-scanning af brystet (MR-mammografi).',
};

const collapsableContentArray = [
  {
    logo: lowRiskLogo,
    risk: 'Lav',
    mammografi: '4.',
    mr: 'M',
    expandableText: expandableText['lav'],
  },
  {
    logo: mediumRiskLogo,
    risk: 'Mellem',
    mammografi: '2.',
    mr: 'M',
    expandableText: expandableText['mellem'],
  },
  {
    logo: heightenedRiskLogo,
    risk: 'Forhøjet',
    mammografi: '',
    mr: 'M',
    expandableText: expandableText['forhøjet'],
  },
  {
    logo: highRiskLogo,
    risk: 'Høj',
    mammografi: '',
    mr: 'MR-m',
    expandableText: expandableText['høj'],
  },
];

/* eslint-disable-next-line */
export interface RiskGroupSectionProps {}

export function RiskGroupSection(props: RiskGroupSectionProps) {
  const screens = useBreakpoint();
  return (
    <div id="risk-group-section">
      {screens.lg ? (
        <PrsonalWrapper backgroundColor="var(--neutral-2)">
          <TextContent />
          <Row gutter={0}>
            <Col xl={14} sm={24}>
              {collapsableContentArray.map(
                ({ logo, risk, mammografi, mr, expandableText }, index) => (
                  <div
                    style={{
                      display: 'grid',
                      justifyContent: `${screens.xl ? '' : 'center'}`,
                    }}
                  >
                    <StyledCollapsable
                      expandIcon={({ isActive }) =>
                        isActive ? (
                          <StyledLogo src={CollapseLogo} />
                        ) : (
                          <StyledLogo src={ExpandsLogo} />
                        )
                      }
                      expandIconPosition="end"
                      items={[
                        {
                          key: index,
                          label: (
                            <CollapsableContent
                              logo={logo}
                              risk={risk}
                              mammografi={screens.lg ? mammografi : ''}
                              mr={screens.lg ? mr : ''}
                            />
                          ),
                          children: <p>{expandableText}</p>,
                        },
                      ]}
                    />
                    <StyledGap />
                  </div>
                )
              )}
            </Col>

            <Col xl={10} sm={0}>
              <StyledImage src={riskPercentage} alt="" />
            </Col>
          </Row>
        </PrsonalWrapper>
      ) : (
        <PrsonalWrapper backgroundColor="var(--neutral-2)">
          <TextContent />
          {collapsableContentArray.map(
            ({ logo, risk, expandableText }, index) => (
              <div
              // style={{
              //   display: 'flex',
              //   padding: '1rem',
              //   justifyContent: 'center',
              // }}
              >
                <StyledCollapsable
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <StyledMobileLogo src={CollapseLogo} />
                    ) : (
                      <StyledMobileLogo src={ExpandsLogo} />
                    )
                  }
                  expandIconPosition="end"
                  items={[
                    {
                      key: index,
                      label: <CollapsableContent logo={logo} risk={risk} />,
                      children: <p>{expandableText}</p>,
                    },
                  ]}
                />
                <StyledGap />
              </div>
            )
          )}
        </PrsonalWrapper>
      )}
    </div>
  );
}

export default RiskGroupSection;

const StyledCollapsable = styled(Collapse)`
  border: 1px solid var(--neutral-5);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  background-color: var(--neutral-1);
  border-radius: 2px;
  .ant-collapse-item:last-child {
    border-radius: 2px;
  }
  .ant-collapse-header {
    align-items: center;
  }
  @media screen and (min-width: 992px) {
    width: 41rem;
  }
`;

const StyledImage = styled.img`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 2.375rem;
  padding-top: 4.2rem;
`;

const StyledLogo = styled.img`
  display: flex;
  flex-direction: column;
  margin-top: 2.75rem;
  margin-right: 1.25rem;
`;

const StyledMobileLogo = styled.img`
  display: flex;
  width: 0.9375rem;
  height: 0.9375rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const StyledGap = styled.div`
  @media screen and (max-width: 992px) {
    padding-bottom: 0.5rem;
  }
  @media screen and (min-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 1.6875rem;
  }
`;
