import styled from 'styled-components';
import ArrowDown from '../../../../icons/ant-menu-submenu-arrow-down.svg';
import { Button, Typography } from 'antd';

const { Title } = Typography;

export interface ContentLinkButtonProps {
  text: string;
  link: string;
}

export function ContentLinkButton(props: ContentLinkButtonProps) {
  return (
    <>
      <StyledButton href={props.link}>
        <Title level={5}>{props.text}</Title>
        <StyledSpace />
        <img src={ArrowDown} alt="" />
      </StyledButton>
      <StyledGap />
    </>
  );
}

export default ContentLinkButton;

const StyledButton = styled(Button)`
  border-left: 3px solid var(--cyan-8);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 2.375rem 1.45rem;
  justify-content: space-between;
  align-items: center;
  white-space: normal;
  text-align: left;
`;

const StyledGap = styled.div`
  padding-bottom: 3.125rem;
`;

const StyledSpace = styled.div`
  padding-right: 0.5rem;
`;
