import styled from 'styled-components';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import MedStudConsentCheckboxes from './consents/med-stud-consent-checkboxes';
import { useConsent } from './utils/consent.hooks';
import { BloodSampleConsent } from './consents/blood-sample-consent';
import { useState } from 'react';
import { FirstConsent } from './consents/first-consent';
import { User } from '@prsonal/website/shared/data-access';

export function ConsentPage() {
  const { consent } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const user = location.state as User;
  const [
    giveFirstConsent,
    giveInformedBloodSampleConsent,
    givePrsonalConsent,
    giveBloodSampleConsent,
  ] = useConsent(user);
  const [canNavigateFromConsentOne, setCanNavigateFromConsentOne] =
    useState(false);

  function getConsent() {
    const navigationBlocked = (navigate: boolean) => {
      setCanNavigateFromConsentOne(navigate);
    };

    switch (consent) {
      case 'stud-med-samtykke-1':
        return (
          <MedStudConsentCheckboxes
            canNavigate={
              canNavigateFromConsentOne ? 'forward' : 'forwardDisabled'
            }
            onCancel={() => navigate(`/padmin/${location.state?.id}`)}
            projectIdentification={false}
            onConsentGiven={() =>
              navigate('/samtykke/stud-med-samtykke-2', {
                state: location.state,
              })
            }
            onNavigate={() =>
              navigate('/samtykke/stud-med-samtykke-2', {
                state: location.state,
              })
            }
            hideSignButton
            onCheckboxClicked={navigationBlocked}
          />
        );
      case 'stud-med-samtykke-2':
        return (
          <FirstConsent
            readonlyMedStudSignature={false}
            onCancel={() => navigate(`/padmin/${location.state?.id}`)}
            onConsentGiven={(signature, _) => {
              giveFirstConsent(signature);
              navigate('/samtykke/stud-med-samtykke-3', {
                state: location.state,
              });
            }}
            participantName={user?.name}
          />
        );
      case 'stud-med-samtykke-3':
        return (
          <BloodSampleConsent
            readonlyMedStudSignature={false}
            onCancel={() => navigate(`/padmin/${location.state.id}`)}
            onConsentGiven={(_, signature) =>
              giveInformedBloodSampleConsent(signature)
            }
            participantName={user?.name}
          />
        );
      case 'prsonal-samtykke':
        return (
          <FirstConsent
            readonlyMedStudSignature
            onCancel={() => navigate(`/padmin/${location.state?.id}`)}
            onConsentGiven={(_, wantInfo) => {
              givePrsonalConsent(!!wantInfo);
              navigate('/samtykke/samtykke-blod', {
                state: location.state,
              });
            }}
            consent={user?.consent}
            participantName={user?.name}
          />
        );
      case 'samtykke-blod':
        return (
          <BloodSampleConsent
            readonlyMedStudSignature
            onCancel={() => navigate(`/bruger`)}
            onConsentGiven={(wantInfo) => giveBloodSampleConsent(wantInfo)}
            consent={user?.consent}
            participantName={user?.name}
          />
        );
      default:
        return null;
    }
  }

  return <StyledConsentPage>{getConsent()}</StyledConsentPage>;
}

export default ConsentPage;

const StyledConsentPage = styled.div``;
