import styled from 'styled-components';
import { Button, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ReadMoreSection } from './components/read-more-section';
import { useState } from 'react';
import { PrsonalWrapper } from '@prsonal/website/shared/components';
import { CantConsentYet } from './components/cant-consent-yet';
import { useAuthentication } from '@prsonal/website/feature-auth';

const { Text } = Typography;

export function ReadMorePage() {
  const navigate = useNavigate();
  const [isInterested, setIsInterested] = useState(false);
  const { login } = useAuthentication();

  return (
    <PrsonalWrapper backgroundColor="var(--cyan-2)" fullHeightBackground>
      <StyledReadMorePage>
        <StyledTextButton
          type="text"
          onClick={() => navigate('/')}
          id="read-more"
        >
          <Text strong>Tilbage til forsiden</Text>
        </StyledTextButton>
        <StyledReadMoreContent>
          {!isInterested ? (
            <ReadMoreSection
              onInterested={() => setIsInterested(!isInterested)}
            />
          ) : (
            <CantConsentYet onContinue={login} />
          )}
        </StyledReadMoreContent>
      </StyledReadMorePage>
    </PrsonalWrapper>
  );
}

const StyledReadMorePage = styled.div``;

const StyledReadMoreContent = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledTextButton = styled(Button)`
  padding: 0;
`;
