import {
  Middleware,
  MiddlewareAPI,
  configureStore,
  isFulfilled,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { baseSplitApi as api } from './base-api';
import { notification } from 'antd';

const rtkQueryErrorHandlingMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      const status = action.payload.status;
      const exceptionCode = action.payload?.data?.exceptionCode;
      switch (status) {
        case 400: {
          notification.error({
            message: exceptionCode ? exceptionCode : 'Ugyldigt input',
            placement: 'bottomRight',
          });
          break;
        }
        case 401: {
          notification.error({
            message: 'Du har ikke tilladelse til dette',
            placement: 'bottomRight',
          });
          break;
        }
        case 500: {
          notification.error({
            message: exceptionCode ? exceptionCode : 'En intern fejl opstod',
            placement: 'bottomRight',
          });
          break;
        }
        default: {
          notification.error({
            message: exceptionCode ? exceptionCode : 'En fejl opstod',
            placement: 'bottomRight',
          });
          break;
        }
      }
    } else if (isFulfilled(action)) {
      if (action.meta.baseQueryMeta.response.status === 201)
        notification.success({
          message: 'Gemt korrekt',
          placement: 'bottomRight',
        });
    }
    return next(action);
  };

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [api.reducerPath]: api.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      api.middleware,
      rtkQueryErrorHandlingMiddleware,
    ]),
});

//TODO: Make this work
// api.enhanceEndpoints({
//   endpoints: {
//     updateOneConsent: {
//       invalidatesTags: ['user'],
//     },
//   },
// });
// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
