import { PrsonalInputNumber } from '@prsonal/website/shared/components';
import { User } from '@prsonal/website/shared/data-access';
import { Sizes } from '@prsonal/website/shared/utils';
import { Button, InputNumber, Typography } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { PadminProps } from '../../padmin.interfaces';
import { isEqual } from 'lodash';
import { RoleEnum } from '@prsonal/shared/utils/types';

const { Text } = Typography;

export function BMIInput(
  props: PadminProps & {
    userRole?: RoleEnum;
  }
) {
  const [user, setUser] = useState<Pick<User, 'height' | 'weight'>>({
    height: props.user.height,
    weight: props.user.weight,
  });
  const [heightError, setHeightError] = useState(false);
  const [weightError, setWeightError] = useState(false);

  const unsavedData = !isEqual(user, {
    height: props.user.height,
    weight: props.user.weight,
  });

  const disabledSave =
    !user?.weight ||
    !user?.height ||
    weightError ||
    heightError ||
    !unsavedData ||
    (!!props.user?.riskCalculation?.riskCalculationReleased &&
      props.userRole !== 'Admin');

  return (
    <StyledBMIInput>
      <StyledInputs>
        <StyledInputContainer>
          <Text>{`Højde (cm)`}</Text>
          <InputNumber
            status={heightError ? 'error' : undefined}
            onChange={(e) => {
              if (e == null || e > 250 || e < 50) {
                setHeightError(true);
              } else {
                setHeightError(false);
              }
              setUser((v) => ({ ...v, height: e ? e : undefined }));
            }}
            placeholder={'Indtast højde'}
            style={{ width: 225 }}
            value={user?.height}
            disabled={props.disabled}
            precision={1}
            // For some reason antd doesnt allow to return undefined or null even though the input can be and the component will display placeholder if undefined is returned...
            //eslint-disable-next-line
            //@ts-ignore
            parser={(value) => (value ? +value?.replace(',', '.') : undefined)}
          />
        </StyledInputContainer>
        <StyledInputContainer>
          <Text>{`Vægt (kg)`}</Text>
          <InputNumber
            status={weightError ? 'error' : undefined}
            onChange={(e) => {
              if (e == null || e > 500 || 20 > e) {
                setWeightError(true);
              } else {
                setWeightError(false);
              }
              setUser((v) => ({ ...v, weight: e ? e : undefined }));
            }}
            placeholder={'Indtast vægt'}
            style={{ width: 225 }}
            value={user?.weight}
            disabled={props.disabled}
            precision={1}
            // For some reason antd doesnt allow to return undefined or null even though the input can be and the component will display placeholder if undefined is returned...
            //eslint-disable-next-line
            //@ts-ignore
            parser={(value) => (value ? +value?.replace(',', '.') : undefined)}
          />
        </StyledInputContainer>
        <StyledInputContainer>
          <Text>{`BMI (i kg/m^2)`}</Text>
          <PrsonalInputNumber
            onValueChange={(e) => props.onValueChange}
            inputProps={{
              disabled: true,
              value: props.user?.bmi,
              placeholder: 'BMI',
            }}
          />
        </StyledInputContainer>
      </StyledInputs>
      <Button
        disabled={disabledSave}
        onClick={() =>
          props.onValueChange({
            ...user,
            bmi:
              user?.weight && user?.height
                ? calculateBMI(user.weight, user.height)?.toFixed(2)
                : undefined,
          } as User)
        }
      >
        Gem
      </Button>
    </StyledBMIInput>
  );
}

const StyledBMIInput = styled.div`
  border: ${Sizes.SMALL_BORDER} solid var(--neutral-6);
  box-shadow: 0px 0.25rem ${Sizes.STANDARD_GAP}rem 0px var(--shadow);
  display: grid;
  align-items: center;
  grid-template-columns: 6fr 0.5fr;
  padding: ${Sizes.LARGE_GAP}rem;
  background-color: var(--neutral-1);
`;

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes.STANDARD_GAP}rem;
`;

const StyledInputs = styled.div`
  display: flex;
  gap: ${Sizes.LARGE_GAP}rem;
`;

function calculateBMI(weight: number, height: number): number | undefined {
  return weight / Math.pow(height / 100, 2);
}
