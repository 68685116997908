import styled from 'styled-components';
import { Button, Checkbox, GlobalToken, Input, theme, Typography } from 'antd';
import { useState } from 'react';
import { User } from '@prsonal/website/shared/data-access';
import { isEmpty } from 'lodash';
import { Sizes } from '@prsonal/website/shared/utils';
import { differenceInCalendarYears, differenceInYears } from 'date-fns';
import { RoleEnum } from '@prsonal/shared/utils/types';

const { useToken } = theme;
const { Text } = Typography;

export interface UserInformationProps {
  user: User;
  onUpdateUser: (data: User) => void;
  title: string;
  admin?: RoleEnum;
  shadow?: boolean;
}

export function UserInformation(props: UserInformationProps) {
  const { token } = useToken();
  const [editable, setEditable] = useState<boolean>(false);
  const [user, setUser] = useState<User>(props.user);

  function saveData(save: boolean) {
    if (save) {
      props.onUpdateUser(user);
    } else {
      setUser(props.user);
    }
    setEditable(false);
  }

  return (
    <StyledUserInformation shadow={props.shadow ?? true}>
      <StyledTitleContainer token={token}>{props.title}</StyledTitleContainer>
      <StyledContentContainer>
        <Text>Navn:</Text>
        <StyledValueContainer>
          <Text type="secondary">{`${props.user.name}`}</Text>
        </StyledValueContainer>
      </StyledContentContainer>
      <StyledContentContainer>
        <Text>CPR:</Text>
        <StyledValueContainer>
          <Text type="secondary">
            {props.user.cpr.slice(0, 6) + '-' + props.user.cpr.slice(6)}
          </Text>
        </StyledValueContainer>
      </StyledContentContainer>
      {props.admin ? (
        <StyledContentContainer>
          <Text>Alder:</Text>
          <StyledValueContainer>
            <Text type="secondary">
              {differenceInYears(new Date(), new Date(props.user?.birthDate))}{' '}
              år
            </Text>
          </StyledValueContainer>
        </StyledContentContainer>
      ) : null}
      <StyledContentContainer>
        <Text>Tlf.:</Text>
        <StyledValueContainer>
          {!editable ? (
            <Text type="secondary">{props.user.phone}</Text>
          ) : (
            <Input
              inputMode="tel"
              value={user.phone}
              onChange={(e) => setUser({ ...user, phone: e.target.value })}
            />
          )}
        </StyledValueContainer>
      </StyledContentContainer>
      <StyledContentContainer>
        <Text>E-Mail:</Text>
        <StyledValueContainer>
          {!editable ? (
            <Text type="secondary">{props.user.email}</Text>
          ) : (
            <Input
              inputMode="email"
              value={user.email}
              onChange={(e) => setUser({ ...user, email: e.target.value })}
            />
          )}
        </StyledValueContainer>
      </StyledContentContainer>
      {props.admin === RoleEnum.Admin ? (
        <StyledContentContainer checkboxContainer>
          <Text>Afsluttet?:</Text>
          <StyledValueContainer>
            <Checkbox
              checked={props.user.isMammographyProcessCompleted}
              onChange={(e) =>
                props.onUpdateUser({
                  ...props.user,
                  isMammographyProcessCompleted: e.target.checked,
                })
              }
            />
          </StyledValueContainer>
        </StyledContentContainer>
      ) : null}
      <StyledEditContainer>
        {!props.admin ? (
          <StyledValueContainer>
            {!editable ? (
              <Button type="link" onClick={() => setEditable(true)}>
                Rediger profil
              </Button>
            ) : (
              <StyledButtonContainer>
                <Button type="default" onClick={() => saveData(false)}>
                  Annuller
                </Button>
                <Button
                  type="primary"
                  onClick={() => saveData(true)}
                  disabled={isEmpty(user.email) || isEmpty(user.phone)}
                >
                  Gem ændringer
                </Button>
              </StyledButtonContainer>
            )}
          </StyledValueContainer>
        ) : null}
      </StyledEditContainer>
    </StyledUserInformation>
  );
}

export default UserInformation;

const StyledUserInformation = styled.div<{ shadow: boolean }>`
  border: 1px solid var(--neutral-6);
  ${(props) =>
    props.shadow ? 'box-shadow: 0px 4px 10px 0px var(--shadow)' : ''};
  max-height: 20rem;
`;

const StyledTitleContainer = styled.div<{ token: GlobalToken }>`
  color: ${(props) => props.token.colorTextHeading};
  display: flex;
  background-color: ${(props) => props.token.colorFillSecondary};
  padding: ${Sizes.SUBTITLE_SIZE * 1.5}rem ${Sizes.SUBTITLE_SIZE}rem;
  margin-bottom: ${Sizes.STANDARD_GAP}rem;
  font-weight: 500;
`;

const StyledValueContainer = styled.div`
  display: flex;
  justify-content: end;
  text-align: right;
`;

const StyledEditContainer = styled.div`
  padding-top: ${Sizes.STANDARD_GAP}rem;
`;

const StyledContentContainer = styled.div<{ checkboxContainer?: boolean }>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.checkboxContainer ? '2fr 1fr' : '1fr 1.5fr'};
  padding: 0.188rem ${Sizes.SUBTITLE_SIZE}rem;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  padding: ${Sizes.SUBTITLE_SIZE}rem ${Sizes.SUBTITLE_SIZE * 2}rem;
`;
