import { DiagnosedWithCancerEnum } from '@prsonal/shared/utils/types';
import { FamilyMember } from '@prsonal/website/shared/data-access';
import { Sizes } from '@prsonal/website/shared/utils';
import { Radio } from 'antd';
import styled from 'styled-components';

export interface TableCancerSelectorProps {
  diagnosedWithCancer?: DiagnosedWithCancerEnum;
  onFamilyMemberChanged: (updatedFamilyMember: Partial<FamilyMember>) => void;
  disabled: boolean;
}

export function TableCancerSelector(props: TableCancerSelectorProps) {
  return (
    <StyledTableCancerSelector
      value={props.diagnosedWithCancer}
      disabled={props.disabled}
    >
      <Radio
        value={DiagnosedWithCancerEnum.Ja}
        onChange={(e) =>
          props.onFamilyMemberChanged({ hadCancer: e.target?.value })
        }
      >
        Ja
      </Radio>
      <Radio
        value={DiagnosedWithCancerEnum.Nej}
        onChange={(e) =>
          props.onFamilyMemberChanged({
            hadCancer: e.target?.value,
            cancerDiagnoses: null,
          })
        }
      >
        Nej
      </Radio>
      <Radio
        value={DiagnosedWithCancerEnum.VedIkke}
        onChange={(e) =>
          props.onFamilyMemberChanged({
            hadCancer: e.target?.value,
            cancerDiagnoses: null,
          })
        }
      >
        Ved ikke
      </Radio>
    </StyledTableCancerSelector>
  );
}

export default TableCancerSelector;

const StyledTableCancerSelector = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  gap: ${Sizes.LARGE_GAP}rem;
`;
