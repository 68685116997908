import { GenderEnum } from '@prsonal/shared/utils/types';
import {
  CancerDiagnoses,
  DiagnosisAge,
  FamilyMember,
} from '@prsonal/website/shared/data-access';
import { Breakpoints, Sizes } from '@prsonal/website/shared/utils';
import { Checkbox, InputNumber, Select, Typography } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useState } from 'react';
import styled from 'styled-components';

const { Text } = Typography;

export interface TableCancerDiagnosisSelectorProps {
  disabled: boolean;
  cancerDiagnoses?: Partial<CancerDiagnoses> | null;
  onFamilyMemberChange: (updatedFamilyMember: Partial<FamilyMember>) => void;
  gender: GenderEnum;
  display: 'checkboxOnly' | 'diagnosisPickerOnly' | 'fullDisplay';
}

type CancerDiagnosisKeys = keyof Pick<
  CancerDiagnoses,
  'breast' | 'otherBreast' | 'ovaries'
>;

const cancerPlacementsWithoutOvary: CancerDiagnosisKeys[] = [
  'breast',
  'otherBreast',
];

const cancerPlacementsWithOvary: CancerDiagnosisKeys[] = [
  'breast',
  'otherBreast',
  'ovaries',
];

const cancerPlacementNames: Record<CancerDiagnosisKeys, string> = {
  breast: 'Det ene bryst',
  otherBreast: 'Det andet bryst',
  ovaries: 'Æggestok(ke)',
};

const approxAgeOptions: DefaultOptionType[] = [
  { value: '', label: 'Fjern' },
  { value: '0-10 år', label: 'Under 10 år' },
  { value: '10-19 år', label: '10-19 år' },
  { value: '20-29 år', label: '20-29 år' },
  { value: '30-39 år', label: '30-39 år' },
  { value: '40-49 år', label: '40-49 år' },
  { value: '50-59 år', label: '50-59 år' },
  { value: '60-69 år', label: '60-69 år' },
  { value: '70-80 år', label: '70-80 år' },
  { value: '80-90 år', label: 'Over 80 år' },
];

export function TableCancerDiagnosisSelector(
  props: TableCancerDiagnosisSelectorProps
) {
  const [ageError, setAgeError] = useState(false);

  const cancerPlacements =
    props.gender === 'Female'
      ? cancerPlacementsWithOvary
      : cancerPlacementsWithoutOvary;

  const handleChange = (
    cancerPlacement: CancerDiagnosisKeys,
    diagnosisAge: DiagnosisAge,
    removeDiagnosis?: boolean
  ) => {
    if (!removeDiagnosis) {
      props.onFamilyMemberChange({
        cancerDiagnoses: {
          ...props.cancerDiagnoses,
          [cancerPlacement]: {
            ...props.cancerDiagnoses?.[cancerPlacement],
            ...diagnosisAge,
          },
        },
      });
    } else {
      props.onFamilyMemberChange({
        cancerDiagnoses: { ...props.cancerDiagnoses, [cancerPlacement]: null },
      });
    }
  };
  return (
    <StyledTableCancerDiagnosisSelector>
      {cancerPlacements.map((cancerPlacement) => (
        <StyledCancerDiagnosisContainer
          key={cancerPlacement}
          bothDisplay={props.display === 'fullDisplay'}
        >
          {props.display === 'checkboxOnly' ||
          props.display === 'fullDisplay' ? (
            <Checkbox
              onChange={(e) => {
                handleChange(cancerPlacement, {}, !e.target?.checked);
              }}
              checked={props.cancerDiagnoses?.[cancerPlacement] != null}
              disabled={props.disabled}
            >
              {cancerPlacementNames[cancerPlacement]}
            </Checkbox>
          ) : null}
          {props.display === 'diagnosisPickerOnly' ||
          props.display === 'fullDisplay' ? (
            <StyledInputAgeContainer>
              <InputNumber
                style={{ width: 150 }}
                status={ageError ? 'error' : undefined}
                onChange={(e) => {
                  if (e != null) {
                    if (e > 125 || e < 0) {
                      setAgeError(true);
                    } else {
                      setAgeError(false);
                    }
                  }
                  handleChange(cancerPlacement, { preciseAge: e ?? null });
                }}
                value={props.cancerDiagnoses?.[cancerPlacement]?.preciseAge}
                placeholder={'Præcis alder'}
                disabled={
                  props.cancerDiagnoses?.[cancerPlacement]?.unknown ||
                  props.cancerDiagnoses?.[cancerPlacement]?.approximateAge !=
                    null ||
                  props.cancerDiagnoses?.[cancerPlacement] == null ||
                  props.disabled
                }
              />
              <StyledText type="secondary">eller</StyledText>
              <Select
                disabled={
                  props.cancerDiagnoses?.[cancerPlacement]?.unknown ||
                  props.cancerDiagnoses?.[cancerPlacement]?.preciseAge !=
                    null ||
                  props.cancerDiagnoses?.[cancerPlacement] == null ||
                  props.disabled
                }
                placeholder="Ca. alder"
                options={approxAgeOptions}
                value={props.cancerDiagnoses?.[cancerPlacement]?.approximateAge}
                onChange={(e: string) => {
                  handleChange(cancerPlacement, {
                    approximateAge: e.length ? e : null,
                  });
                }}
              />
            </StyledInputAgeContainer>
          ) : null}
        </StyledCancerDiagnosisContainer>
      ))}
    </StyledTableCancerDiagnosisSelector>
  );
}

export default TableCancerDiagnosisSelector;

const StyledCancerDiagnosisContainer = styled.div<{ bothDisplay: boolean }>`
  @media screen and (min-width: ${Breakpoints.sm}px) {
    display: grid;
    ${(props) => (props.bothDisplay ? 'grid-template-columns: 1fr 3fr' : '')};
    align-items: center;
  }
`;

const StyledTableCancerDiagnosisSelector = styled.div`
  display: grid;
  gap: ${Sizes.STANDARD_GAP}rem;
`;

const StyledText = styled(Text)`
  display: none;
  @media screen and (min-width: ${Breakpoints.xs}px) {
    display: flex;
  }
`;

const StyledInputAgeContainer = styled.div`
  display: grid;
  align-items: center;
  gap: 0.313rem;
  grid-template-columns: 6.563rem 7rem 5rem;
  @media screen and (min-width: ${Breakpoints.xs}px) {
    grid-template-columns: 10rem 3rem 10rem;
  }
`;
