import { GenderEnum } from '@prsonal/shared/utils/types';
import {
  FamilyMember,
  RiskQuestionnaire,
} from '@prsonal/website/shared/data-access';
import { FamilyMemberCounterProps } from '../components/family-member-counter/family-member-counter';

export const initialFamilyMembers: FamilyMember[] = [
  {
    familyMemberKey: 'Mor',
    lifeState: undefined,
    hadCancer: undefined,
    cancerDiagnoses: {},
    gender: 'Female',
  },
  {
    familyMemberKey: 'Far',
    lifeState: undefined,
    hadCancer: undefined,
    cancerDiagnoses: {},
    gender: 'Male',
  },
  {
    familyMemberKey: 'Farmor',
    lifeState: undefined,
    hadCancer: undefined,
    cancerDiagnoses: {},
    gender: 'Female',
  },
  {
    familyMemberKey: 'Farfar',

    lifeState: undefined,
    hadCancer: undefined,
    cancerDiagnoses: {},
    gender: 'Male',
  },
  {
    familyMemberKey: 'Mormor',

    lifeState: undefined,
    hadCancer: undefined,
    cancerDiagnoses: {},
    gender: 'Female',
  },
  {
    familyMemberKey: 'Morfar',
    lifeState: undefined,
    hadCancer: undefined,
    cancerDiagnoses: {},
    gender: 'Male',
  },
];

export function getInitialFamilyMembers(kids?: FamilyMember[]): FamilyMember[] {
  return initialFamilyMembers
    .filter((member) => member.familyMemberKey !== 'Childrens father')
    .concat(kids ?? []);
}

export function getCounterProps(
  setFamilyMembers: (value: Partial<RiskQuestionnaire>) => void,
  familyMembers?: FamilyMember[],
  noMales?: boolean
): FamilyMemberCounterProps[] {
  if (familyMembers) {
    const counterProps: FamilyMemberCounterProps[] = [
      {
        familyMembers: familyMembers,
        familyMemberKey: 'Søster',
        onFamilyMembersChanged: (updatedfamilyMembers) =>
          setFamilyMembers({
            familyMembers: updatedfamilyMembers,
          }),

        gender: GenderEnum.Female,
        title: 'Søstre',
      },
      {
        familyMembers: familyMembers,
        familyMemberKey: 'Moster',
        onFamilyMembersChanged: (familyMembers) =>
          setFamilyMembers({ familyMembers: familyMembers }),
        gender: GenderEnum.Female,

        title: 'Mostre',
      },
      {
        familyMembers: familyMembers,
        familyMemberKey: 'Faster',
        onFamilyMembersChanged: (familyMembers) =>
          setFamilyMembers({ familyMembers: familyMembers }),
        gender: GenderEnum.Female,

        title: 'Fastre',
      },
      {
        familyMembers: familyMembers,
        familyMemberKey: 'Bror',
        onFamilyMembersChanged: (familyMembers) =>
          setFamilyMembers({ familyMembers: familyMembers }),
        gender: GenderEnum.Male,

        title: 'Brødre',
      },
      {
        familyMembers: familyMembers,
        familyMemberKey: 'Morbror',
        onFamilyMembersChanged: (familyMembers) =>
          setFamilyMembers({ familyMembers: familyMembers }),
        gender: GenderEnum.Male,

        title: 'Morbrødre',
      },
      {
        familyMembers: familyMembers,
        familyMemberKey: 'Farbror',
        onFamilyMembersChanged: (familyMembers) =>
          setFamilyMembers({ familyMembers: familyMembers }),
        gender: GenderEnum.Male,

        title: 'Farbrødre',
      },
    ];
    return noMales
      ? counterProps.filter((counterProp) => counterProp.gender !== 'Male')
      : counterProps;
  } else return [];
}
