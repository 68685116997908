export enum IndexEnum {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
}

export enum LermanAnswersEnum {
  Never = 'Never',
  Rarely = 'Rarely',
  Sometimes = 'Sometimes',
  AlmostAllTheTime = 'AlmostAllTheTime',
}

export enum PromisAnswersEnum {
  Never = 'Never',
  Rarely = 'Rarely',
  Sometimes = 'Sometimes',
  Often = 'Often',
  Always = 'Always',
}

export enum MovementAnswersEnum {
  NoProblemWalking = 'NoProblemWalking',
  LittleProblemWalking = 'LittleProblemWalking',
  ModerateProblemWalking = 'ModerateProblemWalking',
  BigProblemWalking = 'BigProblemWalking',
  CantWalk = 'CantWalk',
}

export enum HygieneAnswersEnum {
  NoProblemHygiene = 'NoProblemHygiene',
  LittleProblemHygiene = 'LittleProblemHygiene',
  ModerateProblemHygiene = 'ModerateProblemHygiene',
  BigProblemHygiene = 'BigProblemHygiene',
  CantHygiene = 'CantHygiene',
}

export enum ActivitiesAnswersEnum {
  NoProblemActivities = 'NoProblemActivities',
  LittleProblemActivities = 'LittleProblemActivities',
  ModerateProblemActivities = 'ModerateProblemActivities',
  BigProblemActivities = 'BigProblemActivities',
  CantActivities = 'CantActivities',
}

export enum PainsAnswersEnum {
  NoPain = 'NoPain',
  LittlePain = 'LittlePain',
  ModeratePain = 'ModeratePain',
  StrongPain = 'StrongPain',
  ExtremePain = 'ExtremePain',
}

export enum AnxietyAnswersEnum {
  NoAnxiety = 'NoAnxiety',
  LittleAnxiety = 'LittleAnxiety',
  ModerateAnxiety = 'ModerateAnxiety',
  MuchAnxiety = 'MuchAnxiety',
  ExtremeAnxiety = 'ExtremeAnxiety',
}

export enum LastPeriodEnum {
  Ja = 'Ja',
  Nej = 'Nej',
  JegHarHormonspiral = 'Jeg har hormonspiral',
}

export enum HormoneTreatedEnum {
  Ja = 'Ja',
  Nej = 'Nej',
  VedIkke = 'Ved ikke',
}

export enum DiagnosedWithCancerEnum {
  Ja = 'Ja',
  Nej = 'Nej',
  VedIkke = 'Ved ikke',
}

export enum CancerPlacementEnum {
  Breast = 'Bryst',
  OtherBreast = 'Andet bryst',
  Ovaries = 'Æggestokke',
}

export enum RoleEnum {
  Participant = 'Participant',
  MedicalStudent = 'MedicalStudent',
  Admin = 'Admin',
}

export enum CsvTypeEnum {
  PRS = 'PRS',
  BIRADS = 'BiRADS',
  ALL = 'ALL',
}

export enum GroupEnum {
  InterventionGroup = 'InterventionGroup',
  ControlGroup = 'ControlGroup',
}

export enum QuestionnaireStatusEnum {
  FilledOut = 'FilledOut',
  Open = 'Open',
  Closed = 'Closed',
}

export enum GenderEnum {
  Female = 'Female',
  Male = 'Male',
}

export enum FamilyMemberType {
  Target = 'Target',
  Child = 'Child',
  Sibling = 'Sibling',
  Father = 'Father',
  Mother = 'Mother',
  MothersMother = 'MothersMother',
  MothersFather = 'MothersFather',
  FathersMother = 'FathersMother',
  FathersFather = 'FathersFather',
  MothersSibling = 'MothersSibling',
  FathersSibling = 'FathersSibling',
}
