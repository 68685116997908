import { CriiptoVerifyProvider, useCriiptoVerify } from '@criipto/verify-react';
import { User, prsonalApi } from '@prsonal/website/shared/data-access';
import { baseEnvironment } from '@prsonal/website/shared/util-environment';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { createContext, useContext } from 'react';

interface AuthContextInterface {
  user?: User;
  isAuthenticated: boolean;
  isFetchingUser: boolean;
  isLoadingClaims: boolean;
  login: () => void;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextInterface>(
  {} as AuthContextInterface
);

export const useAuthentication = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const { claims, isLoading, loginWithRedirect, logout } = useCriiptoVerify();

  const { data: user, isLoading: isFetchingUser } =
    prsonalApi.useGetCurrentUserQuery(claims ? undefined : skipToken);

  return (
    <AuthContext.Provider
      value={{
        user: user,
        isFetchingUser: isFetchingUser,
        isLoadingClaims: isLoading,
        login: () =>
          loginWithRedirect({
            acrValues:
              baseEnvironment.ENV === 'production'
                ? 'urn:grn:authn:dk:mitid:substantial'
                : 'urn:grn:authn:dk:mitid:low',
            redirectUri: `${window.location.origin}/bruger`,
          }),
        logout: () => logout({ redirectUri: window.location.origin }),
        isAuthenticated: !!claims,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const PrsonalProvider = ({ children }: { children: JSX.Element }) => {
  return (
    <CriiptoVerifyProvider
      domain={baseEnvironment.CRIIPTO_DOMAIN}
      clientID={baseEnvironment.CRIIPTO_CLIENT_ID}
      sessionStore={window.sessionStorage}
      scope="openid ssn email phone"
    >
      <AuthProvider>{children}</AuthProvider>
    </CriiptoVerifyProvider>
  );
};
