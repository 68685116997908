import PlayCircle from '@ant-design/icons/PlayCircleOutlined';
import { PrsonalWrapper } from '@prsonal/website/shared/components';
import { Button, Col, Grid, Row, Typography } from 'antd';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import Vid from '../../videos/project-banner-video.mp4';

const { Title } = Typography;
const { Text } = Typography;
const { useBreakpoint } = Grid;

export interface ProjectBannerProps {
  onReadMore: () => void;
}

export function ProjectBanner(props: ProjectBannerProps) {
  const screens = useBreakpoint();
  return screens.xl ? (
    <PrsonalWrapper backgroundColor="var(--cyan-2)">
      <Row gutter={50}>
        <Col span={12}>
          <StyledTitle level={1}>Forskningsprojektet</StyledTitle>
          <StyledGap />
          <Text>
            Alle kvinder mellem 50 og 70 år inviteres til brystkræftscreening
            hvert andet år. Metoden redder liv. Screeningen er ens for alle, men
            i dag ved vi,{' '}
            <StyledBoldText>
              at kvinder har meget forskellig risiko for brystkræft.
            </StyledBoldText>
            <br />
            <br />
            Forskningsprojektet PRSONAL vil sammenligne det nuværende
            screeningsprogram med et program, der er tilpasset den enkelte
            kvindes risiko, så hun får tilbudt screening sjældnere eller oftere
            end i dag.
            <br />
            <br />
            Inden du kan deltage, er det vigtigt at du forstår hvad projektet
            går ud på, og hvad det indebærer at deltage. Det kan du læse mere om
            her på siden.
          </Text>
          <StyledGap />
          <StyledParticipationButton
            type="primary"
            onClick={props.onReadMore}
            size="large"
          >
            Læs mere om at deltage
          </StyledParticipationButton>
        </Col>

        <Col span={12}>
          <ReactPlayer
            url={Vid}
            controls={true}
            playIcon={<PlayCircle rev={''} />}
          />
          <br />
          <StyledSubText>
            Hør mere om personlig brystkræftscreening i denne video.
          </StyledSubText>
        </Col>
      </Row>
    </PrsonalWrapper>
  ) : (
    <PrsonalWrapper backgroundColor="var(--cyan-2)">
      <ReactPlayer
        url={Vid}
        controls={true}
        playIcon={<PlayCircle rev={''} />}
        width="100%"
        height="100%"
      />
      <StyledGap />
      <StyledGap />
      <StyledGap />
      <StyledTitle level={3}>Forskningsprojektet</StyledTitle>
      <StyledGap />
      <StyledGap />
      <StyledMobileText>
        Alle kvinder mellem 50 og 70 år inviteres til brystkræftscreening hvert
        andet år. Metoden redder liv. Screeningen er ens for alle, men i dag ved
        vi,{' '}
        <StyledBoldText>
          at kvinder har meget forskellig risiko for brystkræft.
        </StyledBoldText>
        <br />
        <br />
        Forskningsprojektet PRSONAL vil sammenligne det nuværende
        screeningsprogram med et program, der er tilpasset den enkelte kvindes
        risiko, så hun får tilbudt screening sjældnere eller oftere end i dag.
        <br />
        <br />
        Inden du kan deltage, er det vigtigt at du forstår hvad projektet går ud
        på, og hvad det indebærer at deltage. Det kan du læse mere om her på
        siden.
      </StyledMobileText>
      <StyledGap />
      <StyledGap />
      <StyledParticipationButton onClick={props.onReadMore} type="primary">
        Læs mere om at deltage
      </StyledParticipationButton>
    </PrsonalWrapper>
  );
}

export default ProjectBanner;

const StyledBoldText = styled(Text)`
  color: var(--cyan-9);
  font-weight: 700;
`;

const StyledMobileText = styled(Text)`
  font-size: 0.875rem;
  line-height: 1.375rem; /* 157.143% */
`;

const StyledGap = styled.div`
  @media screen and (max-width: 992px) {
    padding-bottom: 0.5rem;
  }
  @media screen and (min-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 1.6875rem;
  }
`;

const StyledParticipationButton = styled(Button)`
  border: 1px solid var(--primary-6);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
`;

const StyledTitle = styled(Title)`
  margin-top: 0;
`;

const StyledSubText = styled(Text)`
  font-size: 0.875rem;
`;
