import { Sizes } from '@prsonal/website/shared/utils';
import ConsentTemplate from './consent-template';
import { useState } from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { Consent } from '@prsonal/website/shared/data-access';
import { format } from 'date-fns';

const { Text, Title } = Typography;
export interface BloodSampleConsentProps {
  onCancel: () => void;
  onConsentGiven: (wantInfo: boolean, signature?: string) => void;
  consent?: Consent;
  readonlyMedStudSignature: boolean;
  participantName?: string;
}

export function BloodSampleConsent(props: BloodSampleConsentProps) {
  const [extraInfoNotWanted, setExtraInfoNotWanted] = useState(false);
  const [medStudSignature, setMedStudSignature] = useState<string>();

  function onConsentGiven() {
    props.onConsentGiven(
      extraInfoNotWanted,
      !props.readonlyMedStudSignature ? medStudSignature : undefined
    );
  }

  return (
    <ConsentTemplate
      canSign={!props.readonlyMedStudSignature ? !!medStudSignature : true}
      onCancel={props.onCancel}
      onConsentGiven={onConsentGiven}
      projectIdentification={false}
      canNavigate="forwardDisabled"
      consentDisplay={
        <StyledContainer>
          <StyledBloodSampleConsent>
            <StyledTitle level={2}>
              Informeret samtykke til opbevaring af blodprøve i biobank til
              fremtidig forskning
            </StyledTitle>
            <br />
            <br />
            <br />
            <Text>PRSONAL</Text>
            <br />
            <br />
            <Text>
              Population-based Randomized Study Of a Novel breast cancer risk
              Algorithm and stratified screening
            </Text>
            <br />
            <br />
            <Text>Revisionsnummer: 3.0</Text>
            <br />
            <br />
            <Text>Dato: 8. maj 2023</Text>
            <br />
            <br />
            <Text>Videnskabsetisk komites journalnummer: H-23017474</Text>
            <br />
            <br />
            <Text>Clinicaltrials.gov Number: None</Text>
            <StyledProjectIdentificationContainer>
              <Text>Projektidentifikation</Text>
              <Text>Projektnummer ved Videnskabsetisk Komité: H-23017474</Text>
              <Text>
                Projektet er registreret under Region Hovedstadens
                paraplyanmeldelse for sundhedsvidenskabelig
              </Text>
              <Text>forskning med lokalt journalnummer: P-2022-554</Text>
            </StyledProjectIdentificationContainer>
            <StyledTitleContainer>
              <Title level={3}>Samtykkeerklæring</Title>
            </StyledTitleContainer>
            <br />
            <br />
            <StyledTitleContainer>
              <Title level={4}>
                Informeret samtykke til opbevaring af blodprøve i biobank til
                fremtidig forskning
              </Title>
            </StyledTitleContainer>
            <br />
            <StyledTitleContainer>
              <Text>
                Forskningsprojektets titel: PRSONAL - Population-based
                Randomized Study Of a Novel breast cancer risk Algorithm and
                stratified screening
              </Text>
            </StyledTitleContainer>
            <br />
            <br />
            <Text strong>Erklæring fra forsøgspersonen:</Text>
            <br />
            <br />
            <Text>
              Jeg har fået skriftlig og mundtlig information, og jeg ved nok om
              formål, fordele og ulemper ved at sige ja til at afgive en
              blodprøve til opbevaring i biobank til fremtidig forskning. Jeg
              ved, at det er frivilligt at deltage, og at jeg altid kan trække
              mit samtykke tilbage uden at miste mine nuværende eller fremtidige
              rettigheder til behandling. Jeg giver samtykke til, at mit
              biologiske materiale udtages med henblik på opbevaring i en
              biobank til fremtidig forskning, hvorfra det kan anvendes i nye
              forskningsprojekter godkendt i det videnskabsetiske komité-system,
              uden at jeg afgiver yderligere samtykke. Jeg har fået en kopi af
              dette samtykkeark samt en kopi af den skriftlige information om
              projektet til eget brug.{' '}
            </Text>
            <StyledInputContainer>
              <StyledLabel htmlFor="participantName">
                Forsøgspersonens navn:
              </StyledLabel>
              <StyledInput
                id="participantName"
                readOnly
                value={props.participantName}
              />
            </StyledInputContainer>
            <br />
            <br />
            <StyledInputContainer>
              <div style={{ maxWidth: 250 }}>
                <StyledLabel htmlFor="participantDate">Dato:</StyledLabel>
                <StyledInput
                  id="participantDate"
                  readOnly
                  value={
                    props.consent?.prsonalConsentDate
                      ? format(
                          new Date(props.consent.prsonalConsentDate),
                          'dd/MM/yyyy'
                        )
                      : format(new Date(), 'dd/MM/yyyy')
                  }
                />
              </div>
              <StyledLabel htmlFor="participantSignature">
                Underskrift:
              </StyledLabel>
              <StyledInput id="participantSignature" italic />
            </StyledInputContainer>
            <br />
            <br />
            <Text>
              Hvis der kommer nye, væsentlige helbredsoplysninger frem om dig i
              fremtidige forskningsprojekter, vil du blive informeret.
            </Text>
            <br />
            <br />
            <Text>
              Vil du frabede dig information om nye, væsentlige
              helbredsoplysninger, som kommer frem i fremtidige
              forskningsprojekter, beder vi dig markere her:
            </Text>
            <StyledCheckboxLabel>
              <StyledCheckbox
                checked={extraInfoNotWanted}
                onChange={() => setExtraInfoNotWanted(!extraInfoNotWanted)}
              />
              <StyledCheckboxCustom />
            </StyledCheckboxLabel>
            (sæt x)
            <br />
            <br />
            <br />
            <br />
            <Text strong>Erklæring fra den, der afgiver information:</Text>
            <br />
            <br />
            <Text>
              Jeg erklærer, at forsøgspersonen har modtaget mundtlig og
              skriftlig information om forsøget. Efter min overbevisning er der
              givet tilstrækkelig information til, at der kan træffes beslutning
              om afgivelse af blodprøve til opbevaring i biobank til fremtidig
              forskning.
            </Text>
            <br />
            <Text>Navnet på den, der har afgivet information:</Text>
            <br />
            <br />
            <StyledInputContainer>
              <div style={{ maxWidth: 250 }}>
                <StyledLabel htmlFor="medStudDate">Dato:</StyledLabel>
                <StyledInput
                  id="medStudDate"
                  readOnly
                  value={
                    props.consent?.informedConsentDate
                      ? format(
                          new Date(props.consent.informedConsentDate),
                          'dd/MM/yyyy'
                        )
                      : format(new Date(), 'dd/MM/yyyy')
                  }
                />
              </div>
              <StyledLabel htmlFor="medStudSignature">Underskrift:</StyledLabel>
              <StyledInput
                id="medStudSignature"
                readOnly={props.readonlyMedStudSignature}
                italic
                value={props.consent?.medStudBloodSampleSignature}
                onChange={(e) => setMedStudSignature(e.target.value)}
              />
            </StyledInputContainer>
            <br />
            <br />
            <br />
          </StyledBloodSampleConsent>
        </StyledContainer>
      }
    />
  );
}
const StyledTitle = styled(Title)`
  color: black;
`;

const StyledLargeBlock = styled.div`
  height: 600px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledBloodSampleConsent = styled.div`
  width: ${Sizes.PDF_WIDTH}px;
`;

const StyledProjectIdentificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${Sizes.EXTRA_LARGE_GAP}rem;
`;

const StyledInputContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

const StyledLabel = styled.label``;

const StyledInput = styled.input<{ italic?: boolean }>`
  flex: 1;
  border: none;
  outline: none;
  border-bottom: 1px solid #000;
  padding: 5px;
  box-sizing: border-box;
  ${(props) => (props.italic ? 'font-style: italic' : '')};
`;

const StyledCheckboxLabel = styled.label`
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  user-select: none;
`;

const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border: none;
`;

const StyledCheckboxCustom = styled.span`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #000;

  ${StyledCheckbox}:checked + &::after {
    content: '✕'; /* 'x' character */
    position: absolute;
    left: 0;
    bottom: -1px; /* Adjust the vertical position to overlap with the underline */
    color: #000; /* Color of the 'x' */
  }
`;
