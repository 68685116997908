import styled from 'styled-components';
import { Typography } from 'antd';
import { Sizes } from '@prsonal/website/shared/utils';
const { Text } = Typography;

export interface QuestionProps {
  title: string;
  subTitle?: string;
  children: JSX.Element;
  breaklineBeforeSubTitle?: boolean;
}

export function Question(props: QuestionProps) {
  return (
    <StyledQuestion>
      <Text strong>
        {props.title}
        {props.breaklineBeforeSubTitle ? <br /> : null}
        {props.subTitle ? (
          <StyledSubTitle type="secondary"> {props.subTitle}</StyledSubTitle>
        ) : null}
      </Text>
      <StyledChildrenContainer>{props.children}</StyledChildrenContainer>
    </StyledQuestion>
  );
}

export default Question;

const StyledQuestion = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSubTitle = styled(Text)`
  font-size: ${Sizes.SUBTITLE_SIZE}rem;
`;
const StyledChildrenContainer = styled.div`
  margin-top: ${Sizes.STANDARD_GAP}rem;
`;
