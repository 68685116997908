import { PrsonalWrapper } from '@prsonal/website/shared/components';
import { Button, Typography, Grid } from 'antd';
import styled from 'styled-components';
import SurveyTableRow from '../../components/survey-table-row/survey-table-row';
import { QualityWorryQuestionnaireProps } from '../../quality-worry-questionnaire.interfaces';
import { Breakpoints } from '@prsonal/website/shared/utils';
import { Lerman } from '@prsonal/website/shared/data-access';
const { Title, Text, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const lermanQuestions = [
  'Hvor ofte har du tænkt over risikoen for at få brystkræft?',
  'Har disse tanker påvirket dit humør?',
  'Har disse tanker forstyrret din evne til at udføre daglige aktiviteter?',
  'Hvor bekymret er du over risikoen for at få brystkræft på et tidspunkt?',
  'Hvor ofte bekymrer du dig om at udvikle brystkræft?',
  'Hvor stort et problem er denne bekymring?',
];

const lermanFields = [
  'thoughtsOfCancer',
  'mood',
  'dailyActivities',
  'worryOverRisk',
  'howOften',
  'problem',
];

const isDisabled = (lerman?: Lerman): boolean => {
  if (!lerman) return true;
  return (
    lermanQuestions.filter(
      (_, index) => lerman[lermanFields[index] as keyof Lerman] == null
    ).length !== 0
  );
};

export function LermanSurvey(props: QualityWorryQuestionnaireProps) {
  const options = ['Aldrig', 'Sjældent', 'Nogle gange', 'Næsten hele tiden'];

  const screens = useBreakpoint();
  return (
    <PrsonalWrapper
      backgroundColor="#F7FBFA"
      verticalPadding={screens.lg ? undefined : 0.3}
      horizontalPadding={screens.lg ? undefined : 0.3}
      fullHeightBackground
    >
      <StyledLermanContainer>
        <StyledLermanHeader>
          <StyledTitle level={4}>Bekymring for brystkræft</StyledTitle>
        </StyledLermanHeader>
        <StyledLermanTable>
          <Paragraph>
            <MediumText>Instruktioner</MediumText>
            <Text>
              : Markér det felt, der bedst beskriver din nuværende grad af
              bekymring for, om du får brystkræft på et tidspunkt.
            </Text>
          </Paragraph>
          <SurveyTableRow
            title=""
            optionText={options}
            index={0}
            surveyType="lerman"
          />
          {lermanQuestions.map((question, index) => (
            <SurveyTableRow
              key={index}
              title={question}
              optionText={options}
              index={index + 1}
              $footer={index === lermanQuestions.length - 1}
              surveyType="lerman"
              checked={
                props.answers?.lerman?.[lermanFields[index] as keyof Lerman]
              }
              onValueChange={(value) =>
                props.onValueChange({
                  lerman: {
                    ...props.answers?.lerman,
                    [lermanFields[index]]: value,
                  },
                })
              }
            />
          ))}
        </StyledLermanTable>
      </StyledLermanContainer>
      <StyledButtonContainer>
        <Button
          type="primary"
          onClick={() => {
            props.onChangePage(1, true);
          }}
          disabled={isDisabled(props.answers?.lerman)}
        >
          Gem og luk
        </Button>
      </StyledButtonContainer>
    </PrsonalWrapper>
  );
}

const StyledLermanContainer = styled.div`
  border: 1px solid var(--neutral-6, #bfbfbf);
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.25rem;
`;

const StyledLermanHeader = styled.div`
  border-bottom: 1px solid var(--neutral-5, #d9d9d9);
  padding: 1.33rem 3.44rem;
  @media screen and (min-width: ${Breakpoints.lg}px) {
    padding: 1.33rem 3.44rem;
  }
  padding: 0.625rem;
`;

const StyledTitle = styled(Title)`
  margin-top: 0;
  margin-bottom: 0;
`;

const StyledLermanTable = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.25rem 3.44rem 3.44rem 3.44rem;
  @media screen and (min-width: ${Breakpoints.lg}px) {
    padding: 1.25rem 3.44rem 3.44rem 3.44rem;
  }
  padding: 0.625rem;
`;

const MediumText = styled(Text)`
  font-weight: 500;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.25rem;
`;

export default LermanSurvey;
