import {
  Alcohol,
  RiskQuestionnaire,
} from '@prsonal/website/shared/data-access';

export interface RiskQuestionnaireProps {
  id: string;
  answers: Partial<RiskQuestionnaire>;
  onChangePage: (indexChange: number) => void;
  disabledNavigationButton?: boolean;
  onValueChange: (value: Partial<RiskQuestionnaire>) => void;
  currentUserIsMedStudOrAdmin?: boolean;
}

export type AlcoholPickerType = {
  key: keyof Omit<
    Alcohol,
    'id' | 'riskQuestionnaireId' | 'updated' | 'created'
  >;
  type: string;
  amountText: AmountText;
};

export type AmountText =
  | 'Angiv antal flasker/dåser'
  | 'Angiv antal glas'
  | 'Angiv antal genstande';

export const alcoholTypes: Record<
  keyof Pick<
    Alcohol,
    | 'lightBeer'
    | 'regularBeer'
    | 'strongBeer'
    | 'redWine'
    | 'whiteWine'
    | 'hardWine'
    | 'liquor'
    | 'alcopops'
  >,
  string
> = {
  lightBeer: 'Let øl (2,5%)',
  regularBeer: 'Almindelig øl (4,6%)',
  strongBeer: 'Stærk øl (>5%)',
  redWine: 'Rødvin',
  whiteWine: 'Hvidvin og rosévin',
  hardWine: 'Hedvin (f.eks. portvin)',
  liquor: 'Spiritus (f.eks. snaps eller vodka)',
  alcopops: 'Alcopops (f.eks. Barcardi Breezer eller Somersby)',
};
