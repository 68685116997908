import { PrsonalWrapper } from '@prsonal/website/shared/components';
import { Breakpoints } from '@prsonal/website/shared/utils';
import { Button, Col, Grid, Row, Typography } from 'antd';
import styled from 'styled-components';
import { QualityWorryQuestionnaireProps } from '../quality-worry-questionnaire.interfaces';
const { useBreakpoint } = Grid;
const { Text } = Typography;

export function LermanIntroduction(props: QualityWorryQuestionnaireProps) {
  const screens = useBreakpoint();
  return (
    <PrsonalWrapper
      backgroundColor="#F7FBFA"
      horizontalPadding={screens.lg ? 14.5 : 0.4}
    >
      <Row justify="center">
        <Col xs={24}>
          <StyledContainer>
            <Text>
              De næste spørgsmål handler om bekymring for at få brystkræft.
              Markér det felt, der bedst beskriver din nuværende grad af
              bekymring for, om du får brystkræft på et tidspunkt.
            </Text>
          </StyledContainer>
        </Col>
      </Row>

      <Row gutter={10} justify="center">
        <Col>
          <Button onClick={() => props.onChangePage(-1)}>Tilbage</Button>
        </Col>
        <Col>
          <Button type="primary" onClick={() => props.onChangePage(1)}>
            Næste
          </Button>
        </Col>
      </Row>
    </PrsonalWrapper>
  );
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.88rem;
  border: 1px solid var(--neutral-6, #bfbfbf);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  @media screen and (min-width: ${Breakpoints.lg}px) {
    padding: 3.4375rem;
    padding-top: 1.88rem;
    padding-bottom: 1.88rem;
  }
  padding: 0.625rem;
  max-width: ${Breakpoints.lg}px;
  margin: auto;
  margin-bottom: 1.88rem;
  box-sizing: border-box;
`;

export default LermanIntroduction;
