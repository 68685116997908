import {
  FamilyMember,
  RiskQuestionnaire,
} from '@prsonal/website/shared/data-access';
import { Breakpoints, Sizes } from '@prsonal/website/shared/utils';
import { Button, Radio, Typography } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import Question from '../../components/question/question';
import { RiskQuestionnaireProps } from '../../risk-questionnaire-page.interface';
import FamilyMemberCounter from './components/family-member-counter/family-member-counter';
import FamilyTreeTable from './components/family-tree-table/family-tree-table';
import {
  getCounterProps,
  getInitialFamilyMembers,
} from './utils/family-history-memberlist.utils';
import { AlertContainer } from '../../components/alert-container/alert-container';
import {
  areAllRiskQAnswered,
  areFamilyHistoryAnswersFilledOut,
  showDataMissingMessage,
} from '../../utils/valid-check.utils';
import { Loader } from '@prsonal/website/shared/components';

const { Text } = Typography;

export interface FamilyHistoryProps extends RiskQuestionnaireProps {
  onSaveAndClose: (value: Partial<RiskQuestionnaire>) => void;
  fetchingRiskQ: boolean;
}

/**
 * Returns a component that lets the user select cancer diagnostic history for chosen family members.
 *
 * @param {FamilyHistoryProps} propsRadio.Group
 * @returns {JSX.Element}
 */
export function FamilyHistory(props: FamilyHistoryProps) {
  const [familyHistoryAnswers, setFamilyHistoryAnswers] = useState<
    Pick<RiskQuestionnaire, 'familyMembers' | 'kids' | 'maleCancerHistory'>
  >({
    familyMembers: props.answers?.familyMembers,
    kids: props.answers?.kids,
    maleCancerHistory: props.answers?.maleCancerHistory,
  });

  function handleMaleCancerChange(value: boolean) {
    //TODO: This can be removed next patch, when we are certain initialFamilyMembers are added at consent
    const filteredFamilyMembers =
      familyHistoryAnswers?.familyMembers == null ||
      familyHistoryAnswers?.familyMembers?.filter((f) => !f.isChildOfTarget)
        .length === 0
        ? getInitialFamilyMembers(
            familyHistoryAnswers.familyMembers?.filter((f) => f.isChildOfTarget)
          )
        : familyHistoryAnswers.familyMembers;

    setFamilyHistoryAnswers((v) => ({
      ...v,
      maleCancerHistory: value,
      familyMembers: filteredFamilyMembers.map((member) =>
        member.gender === 'Male'
          ? {
              ...member,
              lifeState: {
                ...member.lifeState,
                age: member.isChildOfTarget ? member.lifeState?.age : null,
                alive: null,
                birthYear: null,
              },
              cancerDiagnoses: null,
              hadCancer: null,
            }
          : member
      ),
    }));
  }

  function handleFamilyMemberChange(
    updatedFamilyMember: Partial<FamilyMember>
  ) {
    const updatedFamilyMembers = familyHistoryAnswers?.familyMembers?.map(
      (familyMember: FamilyMember) =>
        familyMember.familyMemberKey === updatedFamilyMember.familyMemberKey
          ? { ...familyMember, ...updatedFamilyMember }
          : familyMember
    );
    setFamilyHistoryAnswers((v) => ({
      ...v,
      familyMembers: updatedFamilyMembers,
    }));
  }

  return (
    <StyledFamilyHistory>
      {!areFamilyHistoryAnswersFilledOut(familyHistoryAnswers) &&
      showDataMissingMessage(props) ? (
        <AlertContainer
          text={
            <>
              <p>Der mangler at blive udfyldt data på denne side. Tjek:</p>
              <p>
                For alle familiemedlemmer SKAL "Ja", "Nej" eller "Ved ikke" være
                valgt.
              </p>
              <p>
                Hvis der er sagt "Ja" til kræft, SKAL mindst én kræftform være
                valgt.
              </p>
              <p>
                Hvis der er sagt "Ja" til kræft, SKAL "Levende" eller "Død" være
                valgt.
              </p>
              <p>
                For hver valgt kræftform SKAL "Præcis alder" eller "Ca. alder"
                være være udfyldt.'
              </p>
            </>
          }
        />
      ) : null}
      <Text>
        I skemaet nedenfor bedes du tilføje dine familiemedlemmer (kun
        biologiske). Du bedes desuden svare på, om de har haft brystkræft eller
        æggestokkræft eller ej.
      </Text>
      <StyledFamilyHistoryContainer>
        <Question title="Har du kendskab til mandlige familiemedlemmer, der har haft brystkræft (kun biologiske)?">
          <StyledRadioGroup
            value={familyHistoryAnswers?.maleCancerHistory}
            disabled={props.answers.status !== 'Open'}
          >
            <Radio
              value={true}
              onChange={(e) => handleMaleCancerChange(e.target?.value)}
            >
              Ja
            </Radio>
            <Radio
              value={false}
              onChange={(e) => handleMaleCancerChange(e.target?.value)}
            >
              Nej
            </Radio>
          </StyledRadioGroup>
        </Question>
      </StyledFamilyHistoryContainer>
      {props.fetchingRiskQ ? (
        <Loader />
      ) : familyHistoryAnswers?.maleCancerHistory != null ? (
        <>
          <StyledFamilyHistoryContainer>
            <Question title="Her kan du tilføje dine familiemedlemmer (kun biologiske). Vi har indsat børn, forældre og bedsteforældre.">
              <StyledRowContainer>
                <StyledRow>
                  {getCounterProps(
                    (value) =>
                      setFamilyHistoryAnswers((v) => ({ ...v, ...value })),
                    familyHistoryAnswers?.familyMembers,
                    !familyHistoryAnswers.maleCancerHistory
                  ).map((familyMemberCounterProp) => (
                    <FamilyMemberCounter
                      key={familyMemberCounterProp.familyMemberKey}
                      {...familyMemberCounterProp}
                      disabled={props.answers.status !== 'Open'}
                    />
                  ))}
                </StyledRow>
              </StyledRowContainer>
            </Question>
          </StyledFamilyHistoryContainer>
          <StyledFamilyHistoryContainer>
            <FamilyTreeTable
              disabled={props.answers.status !== 'Open'}
              familyMembers={
                familyHistoryAnswers.maleCancerHistory
                  ? familyHistoryAnswers?.familyMembers
                  : familyHistoryAnswers.familyMembers?.filter(
                      (member) => member.gender === 'Female'
                    )
              }
              onFamilyMemberChanged={handleFamilyMemberChange}
            />
          </StyledFamilyHistoryContainer>
        </>
      ) : null}
      <ButtonContainer>
        <Button
          onClick={() => {
            props.onValueChange(familyHistoryAnswers);
            props.onChangePage(-1);
          }}
        >
          Forrige
        </Button>
        <Button
          type="primary"
          onClick={() => {
            props.onValueChange(familyHistoryAnswers);
            props.onChangePage(1);
          }}
        >
          Gem
        </Button>
        <Button
          type="primary"
          onClick={() => {
            props.onSaveAndClose(familyHistoryAnswers);
            props.onChangePage(1);
          }}
          disabled={
            !props.currentUserIsMedStudOrAdmin ||
            !areAllRiskQAnswered(familyHistoryAnswers, props.answers)
          }
        >
          Gem og luk
        </Button>
      </ButtonContainer>
    </StyledFamilyHistory>
  );
}

export default FamilyHistory;
const StyledFamilyHistory = styled.div``;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${Sizes.STANDARD_GAP}rem;
  padding: ${Sizes.STANDARD_GAP}rem;
`;

const StyledFamilyHistoryContainer = styled.div`
  display: flex;
  justify-content: start;
  margin: 2rem 0px 2.5rem;
`;

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  justify-content: start;
`;

const StyledRowContainer = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (min-width: ${Breakpoints.lg}px) {
    justify-content: start;
  }
`;

const StyledRow = styled.div`
  @media screen and (min-width: ${Breakpoints.md}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (min-width: ${Breakpoints.lg}px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
