export const envTypes = ['development', 'test', 'production', 'ci'] as const;
export interface BaseEnvironment {
  ENV: (typeof envTypes)[number];
  API_URL: string;
  CRIIPTO_DOMAIN: string;
  CRIIPTO_CLIENT_ID: string;
}

export function createAndValidateEnvironment(): BaseEnvironment {
  const environment = {
    API_URL: import.meta.env.VITE_NX_API_URL,
    ENV: envTypes.find((e) => e === import.meta.env.VITE_NX_ENV),
    CRIIPTO_DOMAIN: import.meta.env.VITE_NX_CRIIPTO_DOMAIN,
    CRIIPTO_CLIENT_ID: import.meta.env.VITE_NX_CRIIPTO_CLIENT_ID,
  };

  if (
    !environment.API_URL ||
    !environment.ENV ||
    !environment.CRIIPTO_DOMAIN ||
    !environment.CRIIPTO_CLIENT_ID
  )
    throw new Error(
      `Environment variables are not set correctly ${JSON.stringify(
        environment
      )}`
    );

  return environment as BaseEnvironment;
}

export const baseEnvironment = createAndValidateEnvironment();
