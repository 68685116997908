import { FamilyMember, LifeState } from '@prsonal/website/shared/data-access';
import { Sizes } from '@prsonal/website/shared/utils';
import { DatePicker, InputNumber, Radio } from 'antd';
import dayjs from 'dayjs';
import styled from 'styled-components';

/* eslint-disable-next-line */
export interface TableAgeSelectorProps {
  lifeState?: LifeState | null;
  onFamilyMemberChanged: (updatedFamilyMember: Partial<FamilyMember>) => void;
  disabled: boolean;
}

const StyledTableAgeSelector = styled.div``;

/**
 * Returns an age selector component designed for familyTreeTable where the user can select if the family member is dead or alive, age and if dead, birth year
 * @param {TableAgeSelectorProps} props
 * @param {LifeState} [props.lifeState] - props.lifeState
 */
export function TableAgeSelector(props: TableAgeSelectorProps) {
  return (
    <StyledTableAgeSelector>
      <Radio.Group value={props.lifeState?.alive} disabled={props.disabled}>
        <Radio
          value={true}
          onChange={(e) =>
            props.onFamilyMemberChanged({
              lifeState: { ...props.lifeState, alive: e?.target?.value },
            })
          }
        >
          Levende
        </Radio>
        <Radio
          value={false}
          onChange={(e) =>
            props.onFamilyMemberChanged({
              lifeState: { ...props.lifeState, alive: e?.target?.value },
            })
          }
        >
          Død
        </Radio>
      </Radio.Group>
      <StyledInputContainer>
        <StyledContainer>
          <InputNumber
            placeholder={
              props.lifeState?.alive === false ? 'Dødsalder' : 'Alder'
            }
            addonAfter={'år'}
            value={props.lifeState?.age}
            disabled={props.disabled}
            onChange={(e) => {
              props.onFamilyMemberChanged({
                lifeState: { ...props.lifeState, age: e },
              });
            }}
          />
        </StyledContainer>
        {props.lifeState?.alive === false ? (
          <DatePicker
            disabled={props.disabled}
            placeholder="Fødselsår"
            picker="year"
            defaultPickerValue={dayjs('1920-09', 'YYYY')}
            value={
              props.lifeState?.birthYear
                ? dayjs(props.lifeState?.birthYear)
                : undefined
            }
            onChange={(e) =>
              props.onFamilyMemberChanged({
                lifeState: { ...props.lifeState, birthYear: e?.toISOString() },
              })
            }
            inputReadOnly
          />
        ) : null}
      </StyledInputContainer>
    </StyledTableAgeSelector>
  );
}

export default TableAgeSelector;

const StyledInputContainer = styled.div`
  width: 9.875rem;
`;

const StyledContainer = styled.div`
  margin: ${Sizes.STANDARD_GAP}rem 0px ${Sizes.STANDARD_GAP}rem;
`;
