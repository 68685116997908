import styled from 'styled-components';
import { Layout, Typography, Grid } from 'antd';
import arrowIcon from './../../../../icons/arrow.svg';

const { Content } = Layout;
const { Text } = Typography;
const { useBreakpoint } = Grid;

export interface CollapsableContentProps {
  logo: string;
  risk: string;
  mammografi?: string;
  mr?: string;
}

export function CollapsableContent(props: CollapsableContentProps) {
  const screens = useBreakpoint();
  if (screens.lg) {
    return (
      <StyledContent>
        <img src={props.logo} alt="" />
        <StyledTextBox1>{props.risk} risikogruppe</StyledTextBox1>
        <img src={arrowIcon} alt="" />
        <StyledTextBox2>
          <b>
            {props.mr}ammografi hvert {props.mammografi} år
          </b>
        </StyledTextBox2>
      </StyledContent>
    );
  } else {
    return (
      <StyledContent>
        <StyledMobileLogo src={props.logo} alt="" />
        {props.risk} risikogruppe
      </StyledContent>
    );
  }
}

const StyledContent = styled(Content)`
  @media screen and (min-width: 992px) {
    padding: 0.75rem 1rem;
  }
  display: flex;
  align-items: center;
  gap: 1.25rem;
`;

const StyledTextBox1 = styled(Text)`
  width: 11.25rem;
`;

const StyledTextBox2 = styled(Text)`
  width: 13.99221rem; //12.55221rem;
`;

const StyledMobileLogo = styled.img`
  width: 1.45019rem;
  height: 1.45019rem;
  flex-shrink: 0;
`;

export default CollapsableContent;
