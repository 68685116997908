import { LifeQualityWorryQuestionnaire } from '@prsonal/website/shared/data-access';
import SurveyQuestion from '../../components/survey-question/survey-question';
import { QualityWorryQuestionnaireProps } from '../../quality-worry-questionnaire.interfaces';

const options = [
  { text: 'Jeg er ikke ængstelig eller deprimeret', value: 'NoAnxiety' },
  { text: 'Jeg er lidt ængstelig eller deprimeret', value: 'LittleAnxiety' },
  {
    text: 'Jeg er moderat ængstelig eller deprimeret',
    value: 'ModerateAnxiety',
  },
  { text: 'Jeg er meget ængstelig eller deprimeret', value: 'MuchAnxiety' },
  {
    text: 'Jeg er ekstremt ængstelig eller deprimeret',
    value: 'ExtremeAnxiety',
  },
];

const title = 'Angst/depression';

export function EqAnxiety(props: QualityWorryQuestionnaireProps) {
  return (
    <SurveyQuestion
      title={title}
      options={options}
      onChangePage={props.onChangePage}
      onValueChange={(value: string) =>
        props.onValueChange({
          anxiety: value as LifeQualityWorryQuestionnaire['anxiety'],
        })
      }
      currentChecked={props.answers?.anxiety}
    />
  );
}

export default EqAnxiety;
