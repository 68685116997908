import { calculateTotalAlcoholIntake } from '@prsonal/shared/utils/util-alcohol-calculation';
import {
  PrsonalInputNumber,
  PrsonalTable,
} from '@prsonal/website/shared/components';
import {
  Alcohol,
  RiskQuestionnaire,
} from '@prsonal/website/shared/data-access';
import { Breakpoints, Sizes } from '@prsonal/website/shared/utils';
import {
  Button,
  Checkbox,
  Grid,
  Radio,
  Select,
  Tooltip,
  Typography,
} from 'antd';
import Column from 'antd/es/table/Column';
import { useState } from 'react';
import styled from 'styled-components';
import Question from '../../components/question/question';
import {
  AmountText,
  RiskQuestionnaireProps,
  alcoholTypes,
} from '../../risk-questionnaire-page.interface';
import { alcoholList, selectOption } from './alcohol-intake.utils';
import { AlertContainer } from '../../components/alert-container/alert-container';
import {
  isAlcoholFilledOut,
  showDataMissingMessage,
} from '../../utils/valid-check.utils';

const { Text } = Typography;
const { useBreakpoint } = Grid;

type AlcoholPickerType = {
  key: keyof Omit<
    Alcohol,
    'id' | 'riskQuestionnaireId' | 'updated' | 'created'
  >;
  type: string;
  amountText: AmountText;
};

export function AlcoholIntake(props: RiskQuestionnaireProps) {
  const { lg } = useBreakpoint();
  const [alcoholAnswers, setAlcoholAnswers] = useState<
    Pick<RiskQuestionnaire, 'alcohol' | 'doYouDrink'>
  >({ alcohol: props.answers?.alcohol, doYouDrink: props.answers?.doYouDrink });

  const alcoholGrPerDay = calculateTotalAlcoholIntake(alcoholAnswers?.alcohol);

  function handleAlcoholTypeSelect(
    checked: boolean,
    alcoholType: keyof Alcohol
  ) {
    if (checked) {
      setAlcoholAnswers((v) => ({
        ...v,
        alcohol: {
          ...v?.alcohol,
          [alcoholType]: {},
        },
      }));
    } else {
      setAlcoholAnswers((v) => ({
        ...v,
        alcohol: {
          ...v?.alcohol,
          [alcoholType]: null,
        },
      }));
    }
  }

  return (
    <StyledAlcohol>
      {!isAlcoholFilledOut(alcoholAnswers) && showDataMissingMessage(props) ? (
        <AlertContainer />
      ) : null}
      <StyledSlottedContainer>
        <Question title="Drikker du alkohol?">
          <Radio.Group
            value={alcoholAnswers?.doYouDrink}
            disabled={props.answers.status !== 'Open'}
          >
            <Radio
              value={true}
              onChange={(e) =>
                setAlcoholAnswers({
                  doYouDrink: e?.target?.value,
                })
              }
            >
              Ja
            </Radio>
            <Radio
              value={false}
              onChange={(e) => {
                setAlcoholAnswers({
                  alcohol: undefined,
                  doYouDrink: e?.target?.value,
                });
              }}
            >
              Nej
            </Radio>
          </Radio.Group>
        </Question>
      </StyledSlottedContainer>
      {alcoholAnswers?.doYouDrink ? (
        lg ? (
          <StyledTableContainer>
            <StyledTextContainer>
              <Tooltip title={`${alcoholGrPerDay.toFixed(2)} gram per dag`}>
                <Text strong>
                  Hvor ofte drikker du følgende typer øl, vin og spiritus?
                </Text>
              </Tooltip>
            </StyledTextContainer>
            <PrsonalTable dataSource={alcoholList} rowKey={'type'}>
              <Column
                title="Alkohol type"
                dataIndex="key"
                render={(_, alcohol: AlcoholPickerType) => (
                  <Text>{alcohol.type}</Text>
                )}
              />
              <Column
                title="Hyppighed"
                render={(_, alcohol: AlcoholPickerType) => (
                  <Select
                    disabled={props.answers.status !== 'Open'}
                    style={{ width: '100%' }}
                    placeholder="Hvor ofte drikker du"
                    options={selectOption}
                    value={alcoholAnswers?.alcohol?.[alcohol.key]?.howOften}
                    onChange={(e) => {
                      setAlcoholAnswers((v) => ({
                        ...v,
                        alcohol: {
                          ...v?.alcohol,
                          [alcohol.key]: {
                            ...v?.alcohol?.[alcohol.key],
                            howOften: e,
                          },
                        },
                      }));
                    }}
                  />
                )}
              />
              <Column
                title="Mængde"
                dataIndex="amountText"
                width={'33%'}
                render={(
                  amountText: AmountText,
                  alcohol: AlcoholPickerType
                ) => (
                  <PrsonalInputNumber
                    onValueChange={(e) => {
                      setAlcoholAnswers((v) => ({
                        ...v,
                        alcohol: {
                          ...v?.alcohol,
                          [alcohol.key]: {
                            ...v?.alcohol?.[alcohol.key],
                            amount: e,
                          },
                        },
                      }));
                    }}
                    inputProps={{
                      disabled:
                        alcoholAnswers?.alcohol?.[alcohol.key]?.howOften ==
                          null ||
                        alcoholAnswers?.alcohol?.[alcohol.key]?.howOften ===
                          'Aldrig' ||
                        props.answers.status !== 'Open',
                      maxLength: 3,
                      value: alcoholAnswers?.alcohol?.[alcohol.key]?.amount,
                      prefix: `${alcohol.amountText}: `,
                    }}
                  />
                )}
              />
            </PrsonalTable>
          </StyledTableContainer>
        ) : (
          <StyledMobileAlcoholTable>
            <StyledTextContainer>
              <Text strong>
                Hvor ofte drikker du følgende typer øl, vin og spiritus?
              </Text>
            </StyledTextContainer>
            {alcoholList.map((alcohol, index) => (
              <StyledAlcoholContainer even={index % 2 !== 0} key={index}>
                <Checkbox
                  disabled={props.answers.status !== 'Open'}
                  checked={
                    alcoholAnswers?.alcohol?.[alcohol.key] !==
                    (null || undefined)
                  }
                  onChange={(e) =>
                    handleAlcoholTypeSelect(e?.target?.checked, alcohol.key)
                  }
                >
                  {alcoholTypes[alcohol.key]}
                </Checkbox>
                {alcoholAnswers?.alcohol?.[alcohol.key] ? (
                  <StyledAlcoholDetailContainer>
                    <Select
                      disabled={props.answers.status !== 'Open'}
                      placeholder="Hvor ofte drikker du"
                      options={selectOption}
                      value={alcoholAnswers?.alcohol?.[alcohol.key]?.howOften}
                      onChange={(e) => {
                        setAlcoholAnswers((v) => ({
                          ...v,
                          alcohol: {
                            ...v?.alcohol,
                            [alcohol.key]: {
                              ...v?.alcohol?.[alcohol.key],
                              howOften: e,
                            },
                          },
                        }));
                      }}
                    />
                    <PrsonalInputNumber
                      onValueChange={(e) => {
                        setAlcoholAnswers((v) => ({
                          ...v,
                          alcohol: {
                            ...v?.alcohol,
                            [alcohol.key]: {
                              ...v?.alcohol?.[alcohol.key],
                              amount: e,
                            },
                          },
                        }));
                      }}
                      inputProps={{
                        disabled: props.answers.status !== 'Open',
                        maxLength: 3,
                        value: alcoholAnswers?.alcohol?.[alcohol.key]?.amount,
                        prefix: `${alcohol.amountText}: `,
                      }}
                    />
                  </StyledAlcoholDetailContainer>
                ) : null}
              </StyledAlcoholContainer>
            ))}
          </StyledMobileAlcoholTable>
        )
      ) : null}
      <StyledButtonContainer>
        <Button
          onClick={() => {
            props.onValueChange(alcoholAnswers);
            props.onChangePage(-1);
          }}
        >
          Forrige
        </Button>
        <Button
          type="primary"
          onClick={() => {
            props.onValueChange(alcoholAnswers);
            props.onChangePage(1);
          }}
        >
          Næste
        </Button>
      </StyledButtonContainer>
    </StyledAlcohol>
  );
}

export default AlcoholPickerType;

const StyledAlcohol = styled.div``;

const StyledMobileAlcoholTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
`;

const StyledAlcoholDetailContainer = styled.div`
  display: grid;
  padding: ${Sizes.STANDARD_GAP}rem 0px;
  gap: ${Sizes.STANDARD_GAP}rem;
`;

const StyledAlcoholContainer = styled.div<{ even: boolean }>`
  border-top: ${Sizes.SMALL_BORDER}rem solid var(--neutral-5);
  padding: ${Sizes.SUBTITLE_SIZE}rem;
  ${(props) => (props.even ? 'background: #f7fbfa;' : '')}
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${Sizes.STANDARD_GAP}rem;
  padding: ${Sizes.STANDARD_GAP}rem;
`;

const StyledSlottedContainer = styled.div`
  display: flex;
  justify-content: start;
  margin: 0px 0px 2.5rem;
`;

const StyledTableContainer = styled.div`
  border: ${Sizes.SMALL_BORDER}rem solid var(--neutral-5, #d9d9d9);
  border-radius: 0.313rem;
  padding: 1rem;
`;

const StyledTextContainer = styled.div`
  padding: 0px 0px ${Sizes.STANDARD_GAP}rem;
  @media screen and (min-width: ${Breakpoints.lg}px) {
    padding: ${Sizes.STANDARD_GAP}rem 0.125rem 1.25rem;
  }
`;
