import { LifeQualityWorryQuestionnaire } from '@prsonal/website/shared/data-access';
import SurveyQuestion from '../../components/survey-question/survey-question';
import { QualityWorryQuestionnaireProps } from '../../quality-worry-questionnaire.interfaces';

const options = [
  { text: 'Jeg har ingen smerter eller ubehag', value: 'NoPain' },
  { text: 'Jeg har lidt smerter eller ubehag', value: 'LittlePain' },
  { text: 'Jeg har moderate smerter eller ubehag', value: 'ModeratePain' },
  { text: 'Jeg har stærke smerter eller ubehag', value: 'StrongPain' },
  { text: 'Jeg har ekstreme smerter eller ubehag', value: 'ExtremePain' },
];

const title = 'Smerter og ubehag';

export function EqPain(props: QualityWorryQuestionnaireProps) {
  return (
    <SurveyQuestion
      title={title}
      options={options}
      onChangePage={props.onChangePage}
      onValueChange={(value: string) =>
        props.onValueChange({
          pain: value as LifeQualityWorryQuestionnaire['pain'],
        })
      }
      currentChecked={props.answers?.pain}
    />
  );
}

export default EqPain;
