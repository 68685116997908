import { ConfigProvider, Grid } from 'antd';
import { createGlobalStyle } from 'styled-components';
import { Colors, CustomFonts } from '@prsonal/website/shared/utils';
const GlobalStyles = createGlobalStyle`
  body {
    padding:0;
    font-size: 14px;
    overflow-y:scroll;
    font-family: 'Roboto', 'Inter';
  }
  html {
    scroll-behavior: smooth;
  }
`;
const { useBreakpoint } = Grid;
export function ThemedApp(props: { children: JSX.Element }) {
  const screens = useBreakpoint();
  return (
    <>
      <Colors />
      <CustomFonts />
      <GlobalStyles />
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#08979C',
            colorFillSecondary: '#E8F4F0',
            colorBgContainer: '#FFFFFF',
            colorLink: '#006D75',
            fontFamily: 'Roboto',
            fontSize: screens.lg ? 16 : 14,
            lineHeight: screens.lg ? 1.5 : 1.57143,
          },
          components: {
            Layout: {
              headerHeight: 80,
            },
            Alert: {
              borderRadius: 2,
            },
            Typography: {
              fontSizeHeading1: 38,
              fontSizeHeading2: 30,
              fontSizeHeading3: 24,
              fontSizeHeading4: 20,
              fontSizeHeading5: 16,
              lineHeightHeading1: 1.21053,
              lineHeightHeading2: 1.333,
              lineHeightHeading3: 1.333,
              lineHeightHeading4: 1.4,
              lineHeightHeading5: 1.57143,
              fontWeightStrong: 500,
              titleMarginBottom: 0,
              titleMarginTop: 0,
              colorText: '#434343',
              colorTextHeading: '#006D75',
            },
            Button: {
              borderRadius: 2,
              borderRadiusLG: 2,
              colorPrimary: '#1890FF',
              colorPrimaryHover: '#40A9FF',
              colorPrimaryActive: '#0958D9',
            },
            Radio: { borderRadius: 2 },
            Dropdown: {
              borderRadius: 2,
            },
            Checkbox: {
              borderRadius: 2,
            },
            Select: {
              borderRadius: 2,
            },
            Input: {
              borderRadius: 2,
            },
            InputNumber: {
              borderRadius: 2,
            },
            Table: {
              fontWeightStrong: 500,
            },
          },
        }}
      >
        {props.children}
      </ConfigProvider>
    </>
  );
}
