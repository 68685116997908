import styled from 'styled-components';
import { Typography } from 'antd';
const { Title } = Typography;

export interface CollapseContentProps {
  text: string;
}

export function CollapseContent(props: CollapseContentProps) {
  return <StyledTitle level={5}>{props.text}</StyledTitle>;
}

export default CollapseContent;

const StyledTitle = styled(Title)`
  margin-top: 0rem;
`;
