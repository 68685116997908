import { ReactNode } from 'react';
import HighRiskVideo from './risk-videos/high-risk.mp4';
import IncreasedRiskVideo from './risk-videos/increased-risk.mp4';
import LowRiskVideo from './risk-videos/low-risk.mp4';
import MediumRiskVideo from './risk-videos/medium-risk.mp4';
import { RiskLevel } from '@prsonal/website/shared/utils';

export const riskDetails: Record<
  RiskLevel,
  { text: (name?: string) => ReactNode; videoUrl: string }
> = {
  'low-risk': {
    videoUrl: LowRiskVideo,
    text: (name) => (
      <>
        <p>{`Kære ${name ?? ''},`}</p>
        <p>
          Vi har nu udregnet din risiko. Du tilhører gruppen af kvinder med lav
          risiko for at udvikle brystkræft. Det betyder, at din risiko er lavere
          end andre kvinders. Knap halvdelen af kvinderne i PRSONAL er i den
          lave risikogruppe.
        </p>

        <p>
          Kvinder med lav risiko kan nøjes med at blive screenet hvert fjerde år
          og altså ikke hvert andet år som i dag. Det vil sige, at din næste
          invitation kommer om 4 år. Når du bliver screenet i forhold til din
          risiko, vil du opleve færre ulemper som f.eks. udsættelse for
          røntgenstråling.
        </p>

        <p>Lav risiko er dog ikke det samme som ingen risiko. Hvis du inden næste invitation mærker en forandring i dit bryst, skal du naturligvis gå til din egen læge og blive undersøgt.</p>

        <p>
          Hvis du gerne vil høre mere om, hvorfor vi anbefaler screening hvert
          fjerde år eller har andre spørgsmål, kan du ringe til projektets
          telefonlinje på tlf. <a href="tel:21140334">21 14 03 34</a>.
        </p>
      </>
    ),
  },
  'medium-risk': {
    videoUrl: MediumRiskVideo,
    text: (name) => (
      <>
        <p>{`Kære ${name ?? ''},`}</p>
        <p>
          Vi har nu udregnet din risiko og kan se, at du er i gruppen af kvinder
          med mellem risiko for at udvikle brystkræft. Det vil sige, at din
          risiko er som gennemsnittet af andre kvinders. I PRSONAL har lidt
          under halvdelen af kvinder mellem risiko.
        </p>
        <p>
          Du vil derfor blive inviteret til screening igen om 2 år – ligesom du
          plejer. Hvis du inden da mærker en forandring i dit bryst, skal du
          naturligvis gå til din egen læge og blive undersøgt.
        </p>
        <p>
          Hvis du har spørgsmål, kan du ringe til projektets telefonlinje på
          tlf. <a href="tel:21140334">21 14 03 34</a>.
        </p>
      </>
    ),
  },
  'increased-risk': {
    videoUrl: IncreasedRiskVideo,
    text: (name) => (
      <>
        <p>{`Kære ${name ?? ''},`}</p>
        <p>
          Vi har nu udregnet din risiko, og du er i gruppen af kvinder med
          forhøjet risiko for at udvikle brystkræft. Det vil sige, at din risiko
          er forhøjet i forhold til gennemsnittet af andre kvinders. Det er dog
          ikke det samme som, at du vil få brystkræft, men vi vil gerne følge
          dig lidt oftere. I PRSONAL har ca. 8 pct. en forhøjet risiko.
        </p>

        <p>
          Du får en ny invitation til mammografi om ca. 9 måneder og derefter
          hvert år. Ud over den almindelige mammografi får du også en såkaldt
          tomosyntese. Det er en særlig teknik, hvor der tages tredimensionelle
          billeder af brystet.
        </p>

        <p>
          Hvis du inden næste invitation mærker en forandring i dit bryst, skal
          du naturligvis gå til din egen læge og blive undersøgt.
        </p>

        <p>
          Vi ved, at kvinder kan blive bekymrede over at få at vide, at deres
          risiko er forhøjet. Hvis du har spørgsmål eller har brug for at tale
          med nogen, kan du ringe til projektets telefonlinje på tlf.{' '}
          <a href="tel:21140334">21 14 03 34</a>.
        </p>
      </>
    ),
  },
  'high-risk': {
    videoUrl: HighRiskVideo,
    text: (name) => (
      <>
        <p>{`Kære ${name ?? ''},`}</p>
        <p>
          Vi har nu udregnet din risiko, og du er i gruppen af kvinder med høj
          risiko for at udvikle brystkræft. Det vil sige, at din risiko er
          højere end gennemsnittet af andre kvinders. En ud af 50 kvinder, der
          deltager i PRSONAL, har høj risiko.
        </p>

        <p>
          Høj risiko er ikke det samme som, at du vil få brystkræft, og vi vil
          gerne invitere dig til en samtale på Gentofte Hospital. Du kan
          bestille tid på tlf. <a href="tel:21140334">2114 0334</a>.
        </p>

        <p>
          Du får en ny invitation til mammografi om ca. 9 måneder og derefter
          hvert år. Vi vil nemlig gerne følge dig lidt oftere. Ud over selve
          mammografien vil du også få taget en MR-scanning af brystet.
        </p>

        <p>
          Hvis du inden næste invitation mærker en forandring i dit bryst, er
          det vigtigt, at du går til din egen læge og bliver undersøgt.
        </p>

        <p>
          Du er altid velkommen til at ringe til projektets telefonlinje på tlf.{' '}
          <a href="tel:21140334">2114 0334</a>.
        </p>
      </>
    ),
  },
};
