import { RoleEnum } from '@prsonal/shared/utils/types';
import { useAuthentication } from '@prsonal/website/feature-auth';
import { Loader, PrsonalWrapper } from '@prsonal/website/shared/components';
import {
  RiskQuestionnaire,
  prsonalApi,
} from '@prsonal/website/shared/data-access';
import { Breakpoints, Sizes } from '@prsonal/website/shared/utils';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { GlobalToken, Progress, Typography, theme } from 'antd';
import { divide, inRange } from 'lodash';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Introduction from './introduction/introduction';
import { AlcoholIntake } from './questions/alcohol/alcohol-intake';
import BirthControl from './questions/birth-control/birth-control';
import FamilyHistory from './questions/family-history/family-history';
import HormoneTreatment from './questions/hormone-treatment/hormone-treatment';
import Children from './questions/kids/kids';
import Period from './questions/period/period';

const { useToken } = theme;
const { Text, Title } = Typography;

const titles = [
  'Alkoholindtag',
  'Menstruation',
  'Prævention',
  'Hormonbehandling',
  'Børn',
  'Familiehistorik',
];

export function RiskQuestionnairePage() {
  const currentUser = useAuthentication().user;
  const navigate = useNavigate();
  const { userId } = useParams();
  const { token } = useToken();

  const { data: user } = prsonalApi.useGetOneUserQuery(
    userId ? { id: userId, join: ['riskQuestionnaire'] } : skipToken
  );
  const {
    currentData: riskQuestionnaire,
    isFetching: fetchingRiskQuestionnaire,
    isLoading: loadingRiskQuestionnaire,
  } = prsonalApi.useGetOneRiskQuestionnaireQuery(
    user?.riskQuestionnaire?.id ? { id: user.riskQuestionnaire.id } : skipToken
  );
  const [activeComponentIndex, setActiveComponentIndex] = useState(0);

  const [questionnaireActive, setQuestionnaireActive] = useState(false);

  const riskQuestionnaireId = riskQuestionnaire?.id ?? '';

  const [updateRiskQuestionnaire] =
    prsonalApi.useUpdateOneRiskQuestionnaireMutation();

  if (!riskQuestionnaire || loadingRiskQuestionnaire) return <Loader />;

  const key = riskQuestionnaire?.updated;
  const isMedStudOrAdmin =
    currentUser?.role === 'MedicalStudent' || currentUser?.role === 'Admin';

  const components = [
    <AlcoholIntake
      key={key}
      id={riskQuestionnaireId}
      answers={riskQuestionnaire}
      onChangePage={handleChangePageClick}
      onValueChange={onValueChange}
      currentUserIsMedStudOrAdmin={isMedStudOrAdmin}
    />,
    <Period
      key={key}
      id={riskQuestionnaireId}
      answers={riskQuestionnaire}
      onChangePage={handleChangePageClick}
      onValueChange={onValueChange}
      currentUserIsMedStudOrAdmin={isMedStudOrAdmin}
    />,
    <BirthControl
      key={key}
      id={riskQuestionnaireId}
      answers={riskQuestionnaire}
      onChangePage={handleChangePageClick}
      onValueChange={onValueChange}
      currentUserIsMedStudOrAdmin={isMedStudOrAdmin}
    />,
    <HormoneTreatment
      key={key}
      id={riskQuestionnaireId}
      answers={riskQuestionnaire}
      onChangePage={handleChangePageClick}
      onValueChange={onValueChange}
      currentUserIsMedStudOrAdmin={isMedStudOrAdmin}
    />,
    <Children
      key={key}
      id={riskQuestionnaireId}
      answers={riskQuestionnaire}
      onChangePage={handleChangePageClick}
      onValueChange={onValueChange}
      currentUserIsMedStudOrAdmin={isMedStudOrAdmin}
    />,
    <FamilyHistory
      key={key}
      id={riskQuestionnaireId}
      answers={riskQuestionnaire}
      onChangePage={handleChangePageClick}
      onValueChange={onValueChange}
      currentUserIsMedStudOrAdmin={isMedStudOrAdmin}
      onSaveAndClose={setAsFilledOut}
      fetchingRiskQ={fetchingRiskQuestionnaire}
    />,
  ];

  function onValueChange(value: Partial<RiskQuestionnaire>) {
    if (riskQuestionnaire?.id) {
      updateRiskQuestionnaire({
        id: riskQuestionnaire.id,
        riskQuestionnaire: value as RiskQuestionnaire,
      });
    }
  }

  async function setAsFilledOut(value: Partial<RiskQuestionnaire>) {
    if (riskQuestionnaire?.id) {
      updateRiskQuestionnaire({
        id: riskQuestionnaire.id,
        riskQuestionnaire: {
          ...value,
          status: 'FilledOut',
        } as RiskQuestionnaire,
      });
      navigate(
        currentUser?.role === RoleEnum.Participant ? '/' : `/padmin/${user?.id}`
      );
    }
  }

  function handleChangePageClick(indexChange: number) {
    if (inRange(activeComponentIndex + indexChange, 0, components.length)) {
      setActiveComponentIndex(activeComponentIndex + indexChange);
    } else if (activeComponentIndex + indexChange < 0) {
      setQuestionnaireActive(false);
    } else {
      navigate(
        currentUser?.role === RoleEnum.Participant ? '/' : `/padmin/${user?.id}`
      );
    }
  }

  return (
    <PrsonalWrapper>
      <StyledRiskQuestionnairePage token={token}>
        {questionnaireActive ? (
          <>
            <TopBar>
              <StyledBackToMySideLink
                href={
                  currentUser?.role === RoleEnum.MedicalStudent
                    ? `/padmin/${user?.id}`
                    : '/'
                }
              >
                <Text>Tilbage til Minside</Text>
              </StyledBackToMySideLink>

              <StyledProgressBar>
                <Progress
                  strokeColor={token.colorPrimary}
                  percent={
                    +divide(
                      (activeComponentIndex + 1) * 100,
                      components.length
                    ).toFixed(0)
                  }
                />
              </StyledProgressBar>
            </TopBar>
            <QuestionnaireTitleContainer>
              <Title level={4}>
                {`
              ${titles[activeComponentIndex]}`}
              </Title>
            </QuestionnaireTitleContainer>
            <StyledQuestionnaireContainer>
              {components[activeComponentIndex]}
            </StyledQuestionnaireContainer>
          </>
        ) : (
          <IntroductionContainer>
            <Introduction
              onStartQuestionnaire={() => setQuestionnaireActive(true)}
              onGoBack={() => navigate(-1)}
              name={user?.name}
            />
          </IntroductionContainer>
        )}
      </StyledRiskQuestionnairePage>
    </PrsonalWrapper>
  );
}

export default RiskQuestionnairePage;

const StyledBackToMySideLink = styled.a`
  display: none;
  @media screen and (min-width: ${Breakpoints.lg}px) {
    display: flex;
    justify-content: left;
    text-decoration: none;
    color: black;
    padding: 0px 0px 1rem;
  }
`;

const IntroductionContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TopBar = styled.div`
  display: grid;
  @media screen and (min-width: ${Breakpoints.lg}px) {
    grid-template-columns: 4fr 1fr;
  }
  grid-template-columns: 2fr 1fr;
`;

const StyledQuestionnaireContainer = styled.div`
  @media screen and (min-width: ${Breakpoints.xl}px) {
    border: ${Sizes.SMALL_BORDER}rem solid var(--neutral-6);
    padding: 3.125rem;
  }
`;

const QuestionnaireTitleContainer = styled.div`
  border-top: ${Sizes.SMALL_BORDER}rem solid var(--neutral-6);
  border-bottom: ${Sizes.SMALL_BORDER}rem solid var(--neutral-6);
  padding: 1rem 1rem 1rem;
  @media screen and (min-width: ${Breakpoints.xl}px) {
    border: ${Sizes.SMALL_BORDER}rem solid var(--neutral-6);
    border-bottom: 0;
  }
`;

const StyledRiskQuestionnairePage = styled.div<{ token: GlobalToken }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledProgressBar = styled.div`
  display: none;
  @media screen and (min-width: ${Breakpoints.lg}px) {
    display: flex;
    justify-content: right;
    align-items: end;
  }
`;
