interface Indiv {
  target?: boolean;
  targetFather?: boolean;
  targetMother?: boolean;
}

function generateIndivIDS() {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const results: string[] = [];
  for (const char of chars) {
    results.push(char.repeat(4));
  }
  return results;
}

const indivIDs = generateIndivIDS();

export function generateIndivID(indiv?: Indiv) {
  if (indiv?.target) {
    return 'ch1';
  } else if (indiv?.targetFather) {
    return 'm21';
  } else if (indiv?.targetMother) {
    return 'f21';
  }
  return indivIDs.pop() || 'DUMMY';
}
