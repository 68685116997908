export function extractAgeFromRange(
  ageRange: string,
  exclusiveBoundKeywords?: [string, string]
): number {
  const matches = ageRange.match(/\b(\d{1,})\b/g);
  if (!matches) throw new Error('No age range found');

  // String is actual range with two numbers
  if (matches?.length === 2) {
    const lowerRangeBound = parseInt(matches[0].toString());
    const upperRangeBound = parseInt(matches[1].toString());

    //Calculate average of range
    return Math.floor(
      lowerRangeBound + (upperRangeBound - lowerRangeBound) / 2
    );
  }

  // String is a single number
  const match = parseInt(matches[0]?.toString());

  if (exclusiveBoundKeywords) {
    const isLowerBound = ageRange.includes(exclusiveBoundKeywords[0]);
    const isUpperbound = ageRange.includes(exclusiveBoundKeywords[1]);
    if (isLowerBound) return match - 1;
    if (isUpperbound) return match + 1;
  }

  return match;
}
