import {
  LermanAnswersEnum,
  PromisAnswersEnum,
} from '@prsonal/shared/utils/types';
import { Breakpoints } from '@prsonal/website/shared/utils';
import { Col, Radio, Row, Typography, Grid } from 'antd';
import styled from 'styled-components';
const { Text } = Typography;
const { useBreakpoint } = Grid;

export interface SurveyTableRowProps {
  title: string;
  optionText: string[];
  index: number;
  $footer?: boolean;
  onValueChange?: (value: string) => void;
  surveyType: 'promis' | 'lerman';
  checked?: string;
}

export function SurveyTableRow(props: SurveyTableRowProps) {
  const screens = useBreakpoint();

  return (
    <StyledTableRow
      bgcolor={
        props.index === 0
          ? '#E8F4F0'
          : props.index % 2 === 0
          ? '#F8FBFA'
          : '#FFF'
      }
      $header={props.index === 0 ? true : false}
      $footer={props.$footer}
      gutter={[0, 10]}
    >
      <Col xs={24} flex={screens.lg ? '2' : undefined}>
        <Text strong>{props.title}</Text>
      </Col>
      {props.index === 0 &&
        screens.lg &&
        props.optionText.map((text) => (
          <StyledTableCol flex={'1'} key={text}>
            <Text strong>{text}</Text>
          </StyledTableCol>
        ))}
      {props.index === 0 ? undefined : (
        <StyledRadioGroup
          name={`radioGroup${props.index}`}
          numberOfOptions={
            Object.values(
              props.surveyType === 'promis'
                ? PromisAnswersEnum
                : LermanAnswersEnum
            )?.length
          }
          onChange={(e) =>
            props.onValueChange
              ? props.onValueChange(e?.target?.value)
              : undefined
          }
          defaultValue={props.checked}
        >
          <Row align={'top'}>
            {Object.values(
              props.surveyType === 'promis'
                ? PromisAnswersEnum
                : LermanAnswersEnum
            )?.map((value, index) => (
              <StyledTableCol flex={'1'} key={value}>
                <StyledRadio value={value} />
                {!screens.lg && (
                  <StyledRadioText>{props.optionText[index]}</StyledRadioText>
                )}
              </StyledTableCol>
            ))}
          </Row>
        </StyledRadioGroup>
      )}
    </StyledTableRow>
  );
}

const StyledTableRow = styled(Row)<{
  bgcolor?: string;
  $header?: boolean;
  $footer?: boolean;
}>`
  background: ${(props) => props.bgcolor};
  border-top: ${(props) =>
    props.$header ? '1px solid var(--neutral-5, #d9d9d9)' : 'none'};
  border-left: 1px solid var(--neutral-5);
  border-right: 1px solid var(--neutral-5);
  border-bottom: ${(props) =>
    props.$footer ? '1px solid var(--neutral-5, #d9d9d9)' : 'none'};
  @media screen and (min-width: ${Breakpoints.lg}px) {
    padding: 1rem 1.5rem;
  }
  padding: 0.625rem;
  justify-content: space-around;
`;

const StyledTableCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledRadioGroup = styled(Radio.Group)<{ numberOfOptions: number }>`
  flex: ${(props) => props.numberOfOptions};
  margin: auto;
`;

const StyledRadio = styled(Radio)`
  margin: 0;
`;

const StyledRadioText = styled(Text)`
  font-size: 0.75rem;
  font-family: 'Roboto';
  font-weight: 400;
  line-height: 1.25;
  font-style: normal;
  color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));
  text-align: center;
`;

export default SurveyTableRow;
