import { User } from '@prsonal/website/shared/data-access';
import { Sizes } from '@prsonal/website/shared/utils';
import { Button, InputNumber, Typography } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { PadminProps } from '../../padmin.interfaces';
import { isEqual } from 'lodash';

const { Text } = Typography;

export function BiradsInput(props: PadminProps) {
  const [user, setUser] = useState<Pick<User, 'birads'>>({
    birads: props.user.birads?.toString(),
  });
  const [biradsError, setBiradsError] = useState(false);

  const unChangedData = isEqual(user, {
    birads: props.user.birads,
  });

  return (
    <StyledBiradsInput>
      <StyledContainer>
        <StyledInputs>
          <StyledInputContainer>
            <Text>BiRADS densitet</Text>
            <InputNumber
              status={biradsError ? 'error' : undefined}
              onChange={(e) => {
                if (e != null && (+e > 4 || +e < 1)) {
                  setBiradsError(true);
                } else {
                  setBiradsError(false);
                }
                setUser((v) => ({
                  ...v,
                  birads: e ? e.toString() : null,
                }));
              }}
              placeholder="Indtast BiRADS"
              style={{ width: 225 }}
              disabled={!props.user?.consent?.prsonalConsent}
              value={user?.birads}
              precision={0}
            />
          </StyledInputContainer>
        </StyledInputs>
        <Button
          disabled={biradsError || unChangedData}
          onClick={() => props.onValueChange(user as User)}
        >
          Gem
        </Button>
      </StyledContainer>
    </StyledBiradsInput>
  );
}

const StyledBiradsInput = styled.div`
  border: ${Sizes.SMALL_BORDER} solid var(--neutral-6);
  box-shadow: 0px 0.25rem ${Sizes.STANDARD_GAP}rem 0px var(--shadow);
  padding: ${Sizes.LARGE_GAP}rem;
  background-color: var(--neutral-1);
  display: grid;
  gap: ${Sizes.STANDARD_GAP}rem;
`;

const StyledContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 6fr 0.5fr;
`;

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes.STANDARD_GAP}rem;
`;

const StyledInputs = styled.div`
  display: flex;
  gap: ${Sizes.LARGE_GAP}rem;
`;
