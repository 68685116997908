import styled from 'styled-components';
import { PrsonalWrapper } from '@prsonal/website/shared/components';
import { Col, Row, Typography, Collapse, List, Grid } from 'antd';
import Img from '../../icons/science-behind-img.jpg';
import ExpandIcon from '../../icons/arrow-right.svg';
import CollapseIcon from '../../icons/arrow-down.svg';
import CollapseContent from './components/collapse-content';

const { Text } = Typography;
const { Title } = Typography;
const { useBreakpoint } = Grid;

type Article = {
  title: string;
  description: string;
  link: string;
};

const scientificArticles: Article[] = [
  {
    title: 'Association analysis identifies 65 new breast cancer risk loci',
    description:
      'Et af de helt store gennembrud i udforskningen af arveanlæg og risiko for brystkræft. Forskerne undersøgte over 250.000 kvinder, som hver fik undersøgt ca. 500.000 varianter i deres arveanlæg.',
    link: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5798588/pdf/emss-74191.pdf',
  },
  {
    title:
      'Polygenic Risk Scores for Prediction of Breast Cancer and Breast Cancer Subtypes',
    description:
      'Den første samlede beskrivelse af den række arveanlæg, der bliver undersøgt i PRSONAL.',
    link: 'https://www.sciencedirect.com/science/article/pii/S0002929718304051?via%3Dihub',
  },
  {
    title:
      'BOADICEA: a comprehensive breast cancer risk prediction model incorporating genetic and nongenetic risk factors',
    description:
      'Artiklen beskriver den risikomodel for brystkræft, som bliver brugt i PRSONAL.',
    link: 'https://www.sciencedirect.com/science/article/pii/S1098360021015963?via%3Dihub',
  },
  {
    title:
      'Prospective validation of the BOADICEA multifactorial breast cancer risk prediction model in a large prospective cohort study',
    description:
      'Artiklen viser, at BOADICEA risikomodellen kunne forudse risiko for brystkræft blandt ca. 66.000 svenske kvinder, der gik til mammografiscreening.',
    link: 'https://jmg.bmj.com/content/jmedgenet/59/12/1196.full.pdf',
  },
];

export function ScienceBehind() {
  const screens = useBreakpoint();
  return (
    <div id="science-behind">
      {screens.xl ? (
        <PrsonalWrapper backgroundColor="var(--cyan-2)">
          <Row>
            <Col span={12}>
              <StyledImage src={Img} />
              <br />
              <br />
              <br />
            </Col>

            <Col span={12}>
              <Title level={3}>Videnskaben bag PRSONAL</Title>
              <StyledGap />
              <Text>
                Projektet bygger på forskning på området fra de seneste femten
                år med deltagelse af over 200.000 kvinder i mange lande. Vi ved
                derfor mere om årsagerne til brystkræft (risikofaktorer) i dag,
                end da brystkræftscreening blev indført i 2009.
                <br />
                <br />
                I dag kender vi f.eks. ca. 300 genetiske varianter, der har
                betydning for at udvikle brystkræft. Det er genetiske varianter,
                som alle kvinder bærer rundt på i større eller mindre grad i
                deres arvemateriale. Vi ved også, at livsstil, familiehistorie
                med brystkræft og brystvævets tæthed har betydning.
                <br />
                <br />
                Ud fra alle disse faktorer kan vi nu beregne risiko og
                tilrettelægge screeningen, så den passer til den enkelte kvinde.
              </Text>
            </Col>
          </Row>
          <Row>
            <StyledCollapse
              expandIcon={({ isActive }) =>
                isActive ? (
                  <img src={CollapseIcon} alt="" />
                ) : (
                  <img src={ExpandIcon} alt="" />
                )
              }
              items={[
                {
                  key: 1,
                  label: <CollapseContent text="Videnskabelige artikler" />,
                  children: (
                    <StyledList
                      dataSource={scientificArticles}
                      renderItem={(item) => {
                        const articleItem = item as Article;
                        return (
                          <List.Item>
                            <List.Item.Meta
                              title={
                                <a
                                  href={articleItem.link}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <StyledTitle level={5}>
                                    {articleItem.title}
                                  </StyledTitle>
                                </a>
                              }
                              description={articleItem.description}
                            />
                          </List.Item>
                        );
                      }}
                    />
                  ),
                },
              ]}
            />
          </Row>
        </PrsonalWrapper>
      ) : (
        <PrsonalWrapper backgroundColor="var(--cyan-2)">
          <Title level={4}>Videnskaben bag PRSONAL</Title>
          <br />
          <Text>
            Projektet bygger på forskning på området fra de seneste femten år
            med deltagelse af over 200.000 kvinder i mange lande. Vi ved derfor
            mere om årsagerne til brystkræft (risikofaktorer) i dag, end da
            brystkræftscreening blev indført i 2009.
            <br />
            <br />
            I dag kender vi f.eks. ca. 300 genetiske varianter, der har
            betydning for at udvikle brystkræft. Det er genetiske varianter, som
            alle kvinder bærer rundt på i større eller mindre grad i deres
            arvemateriale. Vi ved også, at livsstil, familiehistorie med
            brystkræft og brystvævets tæthed har betydning.
            <br />
            <br />
            Ud fra alle disse faktorer kan vi nu beregne risiko og tilrettelægge
            screeningen, så den passer til den enkelte kvinde.
          </Text>
        </PrsonalWrapper>
      )}
    </div>
  );
}

export default ScienceBehind;

const StyledImage = styled.img`
  width: 36.21688rem;
  height: 24.14756rem;
`;

const StyledGap = styled.div`
  display: flex;
  width: 40.39969rem;
  height: 1.875rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.875rem;
`;

const StyledCollapse = styled(Collapse)`
  .ant-collapse-item:last-child {
    border-radius: 2px;
  }
  display: flex;
  flex-direction: column;
  border: 1px solid var(--neutral-5);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  background-color: var(--neutral-1);
  border-radius: 2px;
  width: 100%;
`;

const StyledTitle = styled(Title)`
  color: var(--cyan-8, #006d75);
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 150% */
  text-decoration-line: underline;
`;

const StyledList = styled(List)`
  padding-top: 0;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0px;
`;
