import PlayCircle from '@ant-design/icons/PlayCircleOutlined';
import { User } from '@prsonal/website/shared/data-access';
import {
  Sizes,
  getRiskLevel,
  phoneNumber,
} from '@prsonal/website/shared/utils';
import { Col, Row, Typography } from 'antd';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { riskDetails } from './risk-details';

const { Title, Text } = Typography;

export interface ParticipantMenuCancerRiskProps {
  user: User;
}

export function ParticipantMenuCancerRisk(
  props: ParticipantMenuCancerRiskProps
) {
  if (!props.user.riskCalculation?.riskCalculationReleased || !props.user?.riskCalculation?.tenYearRisk)
    return (
      <RiskPendingScoreContainer>
        <Title level={4}>Her kommer din risikoberegning, når den er klar</Title>
        <br />
        <Text>
          Svaret på din mammografi kommer efter ca. 10 dage, som det plejer. Der
          kan gå op til 90 dage, før du kan se din risikoberegning her.
        </Text>
        <br />
        <Text>
          Hvis du har spørgsmål, kan du kontakte PRSONAL på telefon eller
          e-mail:
        </Text>
        <br />
        <StyledRiskInfoContainer>
          <Text>Tlf.:</Text>
          <Text>{phoneNumber}</Text>
        </StyledRiskInfoContainer>
        <StyledRiskInfoContainer>
          <Text>E-mail:</Text>
          <Text>kontakt@prsonal.dk</Text>
        </StyledRiskInfoContainer>
      </RiskPendingScoreContainer>
    );

  const riskLevel = getRiskLevel(props.user?.riskCalculation?.tenYearRisk);
  const details = riskDetails[riskLevel];
  return (
    <RiskScoreContainer>
      <Row>
        <Col span={12}>{details.text(props.user.name)}</Col>
        <Col span={12}>
          <ReactPlayer
            url={details.videoUrl}
            controls={true}
            playIcon={<PlayCircle rev={''} />}
          />
        </Col>
      </Row>
    </RiskScoreContainer>
  );
}

export default ParticipantMenuCancerRisk;

const StyledRiskInfoContainer = styled.div`
  display: flex;
  justify-content: space-around;
  gap: ${Sizes.LARGE_GAP}rem;
`;

const RiskPendingScoreContainer = styled.div`
  span {
    text-align: center;
    max-width: 43.75rem;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: ${Sizes.MENU_HEIGHT}rem;
`;

const RiskScoreContainer = styled.div`
  padding: 2rem;
`;
