import Column from 'antd/es/table/Column';
import TableAgeSelector from './table-components/table-age-selector/table-age-selector';
import TableCancerSelector from './table-components/table-cancer-selector/table-cancer-selector';
import TableCancerDiagnosisSelector from './table-components/table-cancer-diagnosis-selector/table-cancer-diagnosis-selector';
import { PrsonalTable } from '@prsonal/website/shared/components';
import { GlobalToken, Grid, theme } from 'antd';
import styled from 'styled-components';
import { Sizes } from '@prsonal/website/shared/utils';
import {
  CancerDiagnoses,
  FamilyMember,
  LifeState,
} from '@prsonal/website/shared/data-access';
import {
  DiagnosedWithCancerEnum,
  GenderEnum,
} from '@prsonal/shared/utils/types';

const { useBreakpoint } = Grid;
const { useToken } = theme;

export interface FamilyTreeTableProps {
  familyMembers?: FamilyMember[];
  onFamilyMemberChanged: (updatedFamilyMember: Partial<FamilyMember>) => void;
  disabled: boolean;
}

export function FamilyTreeTable(props: FamilyTreeTableProps) {
  const { xl } = useBreakpoint();
  const { token } = useToken();

  return xl ? (
    <PrsonalTable dataSource={props.familyMembers}>
      <Column title="Familiemedlem" dataIndex="familyMemberKey" width={'10%'} />
      <Column
        title="Alder"
        dataIndex="lifeState"
        width={'15%'}
        render={(lifeState: LifeState, familyMember: FamilyMember) => (
          <TableAgeSelector
            disabled={props.disabled}
            lifeState={lifeState}
            onFamilyMemberChanged={(updatedFamilyMember) =>
              props.onFamilyMemberChanged({
                ...familyMember,
                ...updatedFamilyMember,
              })
            }
          />
        )}
      />
      <Column
        title="Har personen haft bryst- eller æggestokkræft?"
        dataIndex="hadCancer"
        width={'10%'}
        render={(
          hadCancer: DiagnosedWithCancerEnum,
          familyMember: FamilyMember
        ) => (
          <TableCancerSelector
            disabled={props.disabled}
            diagnosedWithCancer={hadCancer}
            onFamilyMemberChanged={(updatedFamilyMember) =>
              props.onFamilyMemberChanged({
                ...familyMember,
                hadCancer: updatedFamilyMember.hadCancer,
                cancerDiagnoses: updatedFamilyMember.cancerDiagnoses,
              })
            }
          />
        )}
      />
      <Column
        title="Kræftform(er)"
        dataIndex="cancerDiagnoses"
        render={(
          cancerDiagnosis: CancerDiagnoses,
          familyMember: FamilyMember
        ) => (
          <TableCancerDiagnosisSelector
            key={familyMember.familyMemberKey}
            disabled={familyMember.hadCancer !== 'Ja' || props.disabled}
            gender={familyMember.gender as GenderEnum}
            cancerDiagnoses={cancerDiagnosis}
            onFamilyMemberChange={(updatedFamilyMember) =>
              props.onFamilyMemberChanged({
                ...familyMember,
                cancerDiagnoses: updatedFamilyMember.cancerDiagnoses,
              })
            }
            display="checkboxOnly"
          />
        )}
      />
      <Column
        title="Alder på diagnosetidspunktet"
        dataIndex="cancerDiagnoses"
        render={(
          cancerDiagnosis: CancerDiagnoses,
          familyMember: FamilyMember
        ) => (
          <TableCancerDiagnosisSelector
            key={familyMember.familyMemberKey}
            disabled={familyMember.hadCancer !== 'Ja' || props.disabled}
            gender={familyMember.gender as GenderEnum}
            cancerDiagnoses={cancerDiagnosis}
            onFamilyMemberChange={(updatedFamilyMember) =>
              props.onFamilyMemberChanged({
                ...familyMember,
                cancerDiagnoses: updatedFamilyMember.cancerDiagnoses,
              })
            }
            display="diagnosisPickerOnly"
          />
        )}
      />
    </PrsonalTable>
  ) : (
    <StyledMobileTable>
      {props.familyMembers?.map((familyMember) => (
        <StyledMobileFamilyMemberContainer key={familyMember.familyMemberKey}>
          <StyledTitleContainer token={token}>
            {familyMember.familyMemberKey}
          </StyledTitleContainer>
          <TableAgeSelector
            disabled={props.disabled}
            lifeState={familyMember?.lifeState}
            onFamilyMemberChanged={(updatedFamilyMember) =>
              props.onFamilyMemberChanged({
                ...familyMember,
                ...updatedFamilyMember,
              })
            }
          />
          <StyledMobileColumnSeparator>
            Har personen haft bryst- eller æggestokkræft?
          </StyledMobileColumnSeparator>
          <TableCancerSelector
            disabled={props.disabled}
            diagnosedWithCancer={
              familyMember.hadCancer
                ? (familyMember.hadCancer as DiagnosedWithCancerEnum)
                : undefined
            }
            onFamilyMemberChanged={(updatedFamilyMember) =>
              props.onFamilyMemberChanged({
                ...familyMember,
                hadCancer: updatedFamilyMember.hadCancer,
              })
            }
          />
          {familyMember.hadCancer === 'Ja' ? (
            <>
              <StyledMobileColumnSeparator>
                Kræftform(er) og alder på diagnosetidspunktet
              </StyledMobileColumnSeparator>
              <TableCancerDiagnosisSelector
                disabled={familyMember.hadCancer !== 'Ja' || props.disabled}
                gender={familyMember.gender as GenderEnum}
                cancerDiagnoses={familyMember?.cancerDiagnoses}
                onFamilyMemberChange={(updatedFamilyMember) =>
                  props.onFamilyMemberChanged({
                    ...familyMember,
                    cancerDiagnoses: updatedFamilyMember.cancerDiagnoses,
                  })
                }
                display="fullDisplay"
              />
            </>
          ) : null}
        </StyledMobileFamilyMemberContainer>
      ))}
    </StyledMobileTable>
  );
}

export default FamilyTreeTable;

const StyledMobileTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
`;

const StyledMobileFamilyMemberContainer = styled.div``;

const StyledMobileColumnSeparator = styled.div`
  border: ${Sizes.SMALL_BORDER}rem solid var(--neutral-5);
  padding: ${Sizes.SUBTITLE_SIZE}rem ${Sizes.SUBTITLE_SIZE * 2}rem;
  margin: ${Sizes.STANDARD_GAP}rem 0px ${Sizes.STANDARD_GAP}rem;
  background-color: #f7fbfa;
`;

const StyledTitleContainer = styled.div<{ token: GlobalToken }>`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.token.colorFillSecondary};
  padding: ${Sizes.SUBTITLE_SIZE}rem ${Sizes.SUBTITLE_SIZE * 2}rem;
  margin: ${Sizes.STANDARD_GAP}rem 0px ${Sizes.STANDARD_GAP}rem;
  font-weight: bold;
`;
