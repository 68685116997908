import { PrsonalWrapper } from '@prsonal/website/shared/components';
import { Sizes } from '@prsonal/website/shared/utils';
import { Button, Typography } from 'antd';
import { ReactNode } from 'react';
import styled from 'styled-components';
const { Title, Text } = Typography;

export interface ConsentTemplateProps {
  onConsentGiven: () => void;
  title?: string;
  canSign: boolean;
  projectIdentification: boolean;
  canNavigate?: 'forward' | 'backward' | 'forwardDisabled';
  hideSignButton?: boolean;
  onNavigate?: () => void;
  onCancel: () => void;
  consentDisplay: ReactNode;
}

export function ConsentTemplate(props: ConsentTemplateProps) {
  return (
    <PrsonalWrapper>
      <StyledConsentTemplate>
        <StyledConsentContainer>
          {props.projectIdentification ? (
            <StyledProjectIdentificationContainer>
              <Text>Projektidentifikation</Text>
              <Text>Projektnummer ved Videnskabsetisk Komité: H-23017474</Text>
              <Text>
                Projektet er registreret under Region Hovedstadens
                paraplyanmeldelse for sundhedsvidenskabelig
              </Text>
              <Text>forskning med lokalt journalnummer: P-2022-554</Text>
            </StyledProjectIdentificationContainer>
          ) : null}
          <Title level={4}>{props.title}</Title>
          {props.consentDisplay}
          <StyledSignButtonContainer>
            {!props.hideSignButton ? (
              <Button
                disabled={!props.canSign}
                type="primary"
                onClick={() => props.onConsentGiven()}
              >
                Underskriv
              </Button>
            ) : null}
          </StyledSignButtonContainer>
        </StyledConsentContainer>
        <StyledButtonContainer>
          {props.canNavigate === 'backward' ? (
            <Button onClick={props.onNavigate}>Forrige</Button>
          ) : null}
          <Button onClick={() => props.onCancel()}>Annullér</Button>
          {props.canNavigate !== 'backward' ? (
            <Button
              onClick={props.onNavigate}
              disabled={props.canNavigate === 'forwardDisabled'}
            >
              Næste
            </Button>
          ) : null}
        </StyledButtonContainer>
      </StyledConsentTemplate>
    </PrsonalWrapper>
  );
}

export default ConsentTemplate;

const StyledConsentTemplate = styled.div``;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${Sizes.STANDARD_GAP}rem;
  padding: ${Sizes.LARGE_GAP}rem;
`;

const StyledConsentContainer = styled.div`
  border: ${Sizes.SMALL_BORDER}rem solid var(--neutral-6);
  padding: ${Sizes.HUGE_GAP}rem;
  padding-bottom: ${Sizes.EXTRA_LARGE_GAP}rem;
`;

const StyledSignButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`;

const StyledProjectIdentificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${Sizes.EXTRA_LARGE_GAP}rem;
`;
