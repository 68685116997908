import { LifeQualityWorryQuestionnaire } from '@prsonal/website/shared/data-access';
import SurveyQuestion from '../../components/survey-question/survey-question';
import { QualityWorryQuestionnaireProps } from '../../quality-worry-questionnaire.interfaces';

const options = [
  {
    text: 'Jeg har ingen problemer med at vaske mig eller klæde mig på',
    value: 'NoProblemHygiene',
  },
  {
    text: 'Jeg har lidt problemer med at vaske mig eller klæde mig på',
    value: 'LittleProblemHygiene',
  },
  {
    text: 'Jeg har moderate problemer med at vaske mig eller klæde mig på',
    value: 'ModerateProblemHygiene',
  },
  {
    text: 'Jeg har store problemer med at vaske mig eller klæde mig på',
    value: 'BigProblemHygiene',
  },
  { text: 'Jeg kan ikke vaske mig eller klæde mig på', value: 'CantHygiene' },
];

const title = 'Personlig pleje';

export function EqPersonalCare(props: QualityWorryQuestionnaireProps) {
  return (
    <SurveyQuestion
      title={title}
      options={options}
      onChangePage={props.onChangePage}
      onValueChange={(value: string) => {
        props.onValueChange({
          hygiene: value as LifeQualityWorryQuestionnaire['hygiene'],
        });
      }}
      currentChecked={props.answers?.hygiene}
    />
  );
}

export default EqPersonalCare;
