import { Row, Col, Typography, Grid } from 'antd';
import styled from 'styled-components';
import StudyPurposeImage from '../../icons/study-purpose-img.png';
import { PrsonalWrapper } from '@prsonal/website/shared/components';

const { Title, Paragraph, Text } = Typography;
const { useBreakpoint } = Grid;

export function StudyPurpose() {
  const screens = useBreakpoint();
  return (
    <div id="study-purpose">
      <PrsonalWrapper backgroundColor="var(--neutral-2)">
        <Row justify="space-around" align="middle" gutter={14}>
          <Col xl={11} xs={0}>
            <StyledImage src={StudyPurposeImage} />
          </Col>

          <Col lg={13} xs={24}>
            <Title level={screens.lg ? 2 : 4}>
              Forskningsprojektets formål
            </Title>
            <br />
            <br />
            <Text>
              Stig Bojesen, professor:
              <br />
            </Text>

            <Title level={5}>
              “Formålet med projektet er at personliggøre og målrette
              brystkræftscreening i Danmark.”
            </Title>
            <br />
            <Paragraph>
              Ved at tage højde for den enkelte kvindes risiko for brystkræft
              kan kvinder med høj risiko få fordelene ved at blive screenet
              oftere. Samtidig bliver kvinder med lav risiko skånet for
              unødvendige røntgenundersøgelser. Forskerne bag projektet håber
              dermed at kunne øge de gavnlige effekter og formindske nogle af
              ulemperne i det nuværende program, såsom falsk alarm og falsk
              tryghed.
              <br />
              <br /> Projektet sammenligner personlig brystkræftscreening med
              det screeningsprogram, der findes i dag. Forskerne vil bl.a.
              undersøge, hvordan kvinder forholder sig til at få målt deres
              personlige risiko for brystkræft, og om kvinder kan acceptere, at
              deres risiko bestemmer, hvor ofte de bliver inviteret til
              mammografiscreening. Forskerne vil også se på, hvordan de kvinder,
              der får beregnet deres risiko for brystkræft, oplever det.
              <br />
              <br /> Hvis projektet bliver en succes, vil personlig screening
              kunne blive indført i hele landet.
            </Paragraph>
          </Col>
        </Row>
      </PrsonalWrapper>
    </div>
  );
}

export default StudyPurpose;

const StyledImage = styled.img`
  object-fit: contain;
  height: 100%;
`;
