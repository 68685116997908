import { WarningOutlined } from '@ant-design/icons';
import { PrsonalWrapper } from '@prsonal/website/shared/components';
import { Breakpoints } from '@prsonal/website/shared/utils';
import { Button, Col, Row, Typography, Grid } from 'antd';
import styled from 'styled-components';
import { QualityWorryQuestionnaireProps } from '../quality-worry-questionnaire.interfaces';
const { Text, Paragraph } = Typography;
const { useBreakpoint } = Grid;

export function PromisIntroduction(props: QualityWorryQuestionnaireProps) {
  const screens = useBreakpoint();
  return (
    <PrsonalWrapper
      backgroundColor="#F7FBFA"
      horizontalPadding={screens.lg ? 14.5 : 0.4}
      verticalPadding={screens.lg ? 6.25 : 0.4}
      fullHeightBackground
    >
      <Row justify="center">
        <Col xs={24}>
          <StyledContainer>
            <Text>
              De næste spørgsmål handler om angst. Her skal du svare på, hvordan
              du har haft det de sidste 7 dage.
            </Text>
            <StyledHiglightedText>
              <WarningOutlined span={24} />
              <Text>
                <Text strong>Vigtigt</Text>: Når du trykker ”Næste”, kan du ikke
                længere redigere dine svar. Hvis du gerne vil ændre dine svar,
                skal du trykke på ”Tilbage” for at vende tilbage til og ændre
                tidligere svar.
              </Text>
            </StyledHiglightedText>
          </StyledContainer>
        </Col>
      </Row>
      <Row gutter={10} justify="center">
        <Col>
          <Button onClick={() => props.onChangePage(-1)}>Tilbage</Button>
        </Col>
        <Col>
          <Button type="primary" onClick={() => props.onChangePage(1, false)}>
            Næste
          </Button>
        </Col>
      </Row>
    </PrsonalWrapper>
  );
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.88rem;
  border: 1px solid var(--neutral-6, #bfbfbf);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  @media screen and (min-width: ${Breakpoints.lg}px) {
    padding: 3.4375rem;
    padding-top: 1.88rem;
  }
  padding: 0.625rem;
  max-width: ${Breakpoints.lg}px;
  margin: auto;
  margin-bottom: 1.88rem;
  box-sizing: border-box;
`;

const StyledHiglightedText = styled(Paragraph)`
  background: var(--calendula-gold-2, #fff1b8);
  padding: 1rem;
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: flex-start;
`;

export default PromisIntroduction;
