import { Layout, Row, Col, Typography, Space, Grid, Button } from 'antd';
import styled from 'styled-components';
import KBLogo from '../../icons/KB-logo 1.svg';
import CambridgeLogo from '../../icons/5842f8a5a6515b1e0ad75b2a 1.svg';
import NovoLogo from '../../icons/NNF-DK_logo_tagline_blue_RGB_solid 1.svg';
import RegionHLogo from '../../icons/RegionH logo 1.svg';
import HerlevLogo from '../../icons/Logo_Herlev_og_Gentofte 2.svg';
import { PrsonalWrapper } from '@prsonal/website/shared/components';
import { phoneNumber } from '@prsonal/website/shared/utils';

const { Footer } = Layout;
const { Text, Paragraph, Title } = Typography;
const { useBreakpoint } = Grid;

/* eslint-disable-next-line */
export interface PrsonalFooterProps {}

export function PrsonalFooter(props: PrsonalFooterProps) {
  const screens = useBreakpoint();

  return (
    <>
      <PrsonalWrapper backgroundColor="var(--neutral-3)">
        <StyledFooter>
          <Row justify="space-between" align="top">
            <Col xs={24} lg={6}>
              <div style={{ padding: '0px 5px' }}>
                <Title level={5}>Projektansvarlige</Title>
                <br />
                <Paragraph>
                  <Text strong>Stig Bojesen (forskningsprojektansvarlig)</Text>
                  <br /> Klinisk biokemisk afdeling, Herlev Hospital
                  <br /> Borgmester Ib Juuls vej 1, 2730 Herlev
                  <br /> Mail: Stig.Egil.Bojesen@regionh.dk
                  <br /> Tlf.: {phoneNumber}
                  <br />
                  <br /> <Text strong>Ilse Vejborg</Text>
                  <br /> Cheflæge og screeningschef
                  <br /> Herlev Gentofte Hospital og
                  Mammografiscreeningsprogrammet i
                  <br /> Region Hovedstaden
                  <br /> Kildegårdsvej 28 C, opgang 63 st.
                  <br /> Gentofte Hospital
                  <br /> 2820 Gentofte
                  <br /> Mail: Ilse.vejborg@regionh.dk
                  <br /> Tlf.: 38 67 77 49
                  <br />
                  <br /> <Text strong>Janne Bigaard</Text>
                  <br /> Afdeling for Forebyggelse & Oplysning
                  <br /> Kræftens Bekæmpelse
                  <br /> Mail: bigaard@cancer.dk
                  <br /> Tlf.: 27 77 10 70
                </Paragraph>
              </div>
            </Col>
            <Col xs={24} lg={8}>
              <div style={{ padding: '0px 5px' }}>
                <Title level={5}>Hvem står bag projektet</Title>
                <br />
                <Paragraph>
                  Forskningsprojektet PRSONAL gennemføres i et samarbejde mellem
                  Region Hovedstaden, Klinisk Biokemisk Afdeling på Herlev og
                  Gentofte Hospital og Kræftens Bekæmpelse.
                  <br />
                  <br /> Projektet foregår på Mammografiklinikken, Gentofte
                  Hospital i Region Hovedstaden.
                </Paragraph>
                {screens.lg && (
                  <StyledSpace direction="vertical" size={40}>
                    <StyledImage src={KBLogo} />
                    <StyledImage src={HerlevLogo} />
                    <StyledImage src={RegionHLogo} />
                  </StyledSpace>
                )}
              </div>{' '}
            </Col>
            <Col xs={24} lg={6}>
              <Title level={5}>Øvrige samarbejdspartnere</Title>
              <br />
              <Paragraph>
                University of Cambridge og Afdeling for Folkeundersøgelser,
                Randers Hospital, Region Midtjylland.
                <br />
                <br /> Projektet er gjort muligt via en bevilling fra Novo
                Nordisk Fonden.
              </Paragraph>
              {screens.lg && (
                <StyledSpace direction="vertical" size={40}>
                  <StyledImage src={NovoLogo} />
                  <StyledImage src={CambridgeLogo} />
                </StyledSpace>
              )}
            </Col>
            <Col xs={24} lg={4}>
              <StyledText>
                <a href="/privat-livs-politik" target="_blank">
                  Privatlivspolitik
                </a>
              </StyledText>
            </Col>
          </Row>
        </StyledFooter>
      </PrsonalWrapper>

      <StyledBottomBar>
        <PrsonalWrapper
          verticalPadding={0}
          horizontalPadding={0}
          backgroundColor="var(--neutral-9)"
        >
          <StyledBottomBarText>
            @Copyright All rights reserved
          </StyledBottomBarText>
        </PrsonalWrapper>
      </StyledBottomBar>
    </>
  );
}

export default PrsonalFooter;

const StyledFooter = styled(Footer)`
  background-color: var(--neutral-3);
  padding: 0;
  align-items: center;
`;

const StyledText = styled(Text)`
  font-weight: 500;
`;

const StyledBottomBar = styled(Footer)`
  @media screen and (min-width: 1441px) {
    padding-left: 9rem;
  }
  background: var(--neutral-9);
`;

const StyledBottomBarText = styled(Text)`
  color: var(--neutral-1);
  font-size: 0.75rem;
  font-weight: 400;
`;

const StyledImage = styled.img`
  cursor: pointer;
`;

const StyledSpace = styled(Space)`
  padding-top: 1rem;
`;

const StyledButton = styled(Button)`
  font-weight: 500;
  font-size: 1rem;
`;
