import styled from 'styled-components';
import { Alert, Modal, Typography, Grid } from 'antd';
import ParticipantMenu from './components/participant-menu/participant-menu';
import { User, prsonalApi } from '@prsonal/website/shared/data-access';
import { useAuthentication } from '@prsonal/website/feature-auth';
import { Breakpoints, Sizes } from '@prsonal/website/shared/utils';
import { useGetParticipantPageProps } from './utils/participant-page.hooks';
import {
  Loader,
  PrsonalWrapper,
  QuestionnaireTable,
  UserInformation,
} from '@prsonal/website/shared/components';

const { Title } = Typography;
const { useBreakpoint } = Grid;

export function ParticipantPage() {
  const [updateUser] = prsonalApi.useUpdateOneUserMutation();
  const user = useAuthentication().user;
  const screens = useBreakpoint();
  function saveUserData(userData: User) {
    if (user?.id) updateUser({ id: user.id, user: { ...user, ...userData } });
  }

  const [questions, questionnaires, fetchingProps] =
    useGetParticipantPageProps(user);

  return user ? (
    <PrsonalWrapper
      verticalPadding={0}
      horizontalPadding={screens.lg ? undefined : 0.1}
    >
      <StyledParticipantPage>
        <Title level={4}>Min side</Title>
        <StyledContentContainer>
          <StyledContainer>
            {user.riskQuestionnaire?.updated ===
            user.riskQuestionnaire?.created ? (
              <Alert
                style={{ borderRadius: 2 }}
                type="info"
                message={`Kære ${user.name}`}
                description="Velkommen til forskningsprojektet PRSONAL.
            Dette er din side, hvor du besvarer de spørgeskemaer, der er en del af projektet.
            Både dem, du skal udfylde ved projektets start, og de 3 korte skemaer du skal udfylde undervejs i projektet.
            Hvis du kommer i interventionsgruppen, er det også her, du kan se, hvilken risikogruppe du er kommet i."
                closable
                showIcon
              />
            ) : null}
          </StyledContainer>
          <StyledMiddleSection>
            <QuestionnaireTable
              questionnaires={questionnaires}
              isLoading={fetchingProps}
            />
            <UserInformation
              user={user}
              onUpdateUser={saveUserData}
              title="Personlig information"
            />
          </StyledMiddleSection>
          <StyledContainer>
            <ParticipantMenu questions={questions} user={user} />
          </StyledContainer>
        </StyledContentContainer>
      </StyledParticipantPage>
    </PrsonalWrapper>
  ) : (
    <Loader />
  );
}

export default ParticipantPage;

const StyledContainer = styled.div``;

const StyledMiddleSection = styled.div`
  @media screen and (min-width: ${Breakpoints.lg}px) {
    display: grid;
    grid-template-columns: 3fr 1fr;
  }
  display: flex;
  flex-direction: column;
  gap: ${Sizes.LARGE_GAP}rem;
`;

const StyledContentContainer = styled.div`
  @media screen and (min-width: ${Breakpoints.lg}px) {
    display: grid;
  }
  display: flex;
  flex-direction: column;
  gap: ${Sizes.LARGE_GAP}rem;
`;

const StyledParticipantPage = styled.div`
  @media screen and (min-width: ${Breakpoints.lg}px) {
    padding: ${Sizes.STANDARD_GAP}rem ${Sizes.LARGE_GAP}rem;
  }
`;
