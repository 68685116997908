import { baseSplitApi as api } from './base-api';
export const addTagTypes = [
  'risk-questionnaire',
  'user',
  'life-quality-worry-questionnaire',
  'consent',
  'note',
  'risk-calculation',
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getOneRiskQuestionnaire: build.query<
        GetOneRiskQuestionnaireApiResponse,
        GetOneRiskQuestionnaireApiArg
      >({
        query: (queryArg) => ({
          url: `/api/risk-questionnaire/${queryArg.id}`,
          params: {
            fields: queryArg.fields,
            join: queryArg.join,
            cache: queryArg.cache,
          },
        }),
        providesTags: ['risk-questionnaire', 'user'],
      }),
      updateOneRiskQuestionnaire: build.mutation<
        UpdateOneRiskQuestionnaireApiResponse,
        UpdateOneRiskQuestionnaireApiArg
      >({
        query: (queryArg) => ({
          url: `/api/risk-questionnaire/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.riskQuestionnaire,
        }),
        invalidatesTags: ['risk-questionnaire', 'user'],
      }),
      createOneRiskQuestionnaire: build.mutation<
        CreateOneRiskQuestionnaireApiResponse,
        CreateOneRiskQuestionnaireApiArg
      >({
        query: (queryArg) => ({
          url: `/api/risk-questionnaire`,
          method: 'POST',
          body: queryArg.riskQuestionnaire,
        }),
        invalidatesTags: ['risk-questionnaire', 'user'],
      }),
      getManyLifeQualityWorryQuestionnaire: build.query<
        GetManyLifeQualityWorryQuestionnaireApiResponse,
        GetManyLifeQualityWorryQuestionnaireApiArg
      >({
        query: (queryArg) => ({
          url: `/api/life-quality-worry-questionnaire`,
          params: {
            fields: queryArg.fields,
            s: queryArg.s,
            filter: queryArg.filter,
            or: queryArg.or,
            sort: queryArg.sort,
            join: queryArg.join,
            limit: queryArg.limit,
            offset: queryArg.offset,
            page: queryArg.page,
            cache: queryArg.cache,
          },
        }),
        providesTags: ['life-quality-worry-questionnaire', 'user'],
      }),
      createOneLifeQualityWorryQuestionnaire: build.mutation<
        CreateOneLifeQualityWorryQuestionnaireApiResponse,
        CreateOneLifeQualityWorryQuestionnaireApiArg
      >({
        query: (queryArg) => ({
          url: `/api/life-quality-worry-questionnaire`,
          method: 'POST',
          body: queryArg.lifeQualityWorryQuestionnaire,
        }),
        invalidatesTags: ['life-quality-worry-questionnaire', 'user'],
      }),
      getOneLifeQualityWorryQuestionnaire: build.query<
        GetOneLifeQualityWorryQuestionnaireApiResponse,
        GetOneLifeQualityWorryQuestionnaireApiArg
      >({
        query: (queryArg) => ({
          url: `/api/life-quality-worry-questionnaire/${queryArg.id}`,
          params: {
            fields: queryArg.fields,
            join: queryArg.join,
            cache: queryArg.cache,
          },
        }),
        providesTags: ['life-quality-worry-questionnaire', 'user'],
      }),
      updateOneLifeQualityWorryQuestionnaire: build.mutation<
        UpdateOneLifeQualityWorryQuestionnaireApiResponse,
        UpdateOneLifeQualityWorryQuestionnaireApiArg
      >({
        query: (queryArg) => ({
          url: `/api/life-quality-worry-questionnaire/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.lifeQualityWorryQuestionnaire,
        }),
        invalidatesTags: ['life-quality-worry-questionnaire', 'user'],
      }),
      createManyLifeQualityWorryQuestionnaire: build.mutation<
        CreateManyLifeQualityWorryQuestionnaireApiResponse,
        CreateManyLifeQualityWorryQuestionnaireApiArg
      >({
        query: (queryArg) => ({
          url: `/api/life-quality-worry-questionnaire/bulk`,
          method: 'POST',
          body: queryArg.createManyLifeQualityWorryQuestionnaireDto,
        }),
        invalidatesTags: ['life-quality-worry-questionnaire', 'user'],
      }),
      getCurrentUser: build.query<
        GetCurrentUserApiResponse,
        GetCurrentUserApiArg
      >({
        query: () => ({ url: `/api/user/current` }),
        providesTags: ['user'],
      }),
      randomizeUser: build.mutation<
        RandomizeUserApiResponse,
        RandomizeUserApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/randomize-user/${queryArg.id}`,
          method: 'POST',
        }),
        invalidatesTags: ['user'],
      }),
      getUserCsv: build.query<GetUserCsvApiResponse, GetUserCsvApiArg>({
        query: (queryArg) => ({
          url: `/api/user/csv`,
          params: { type: queryArg['type'] },
        }),
        providesTags: ['user'],
      }),
      uploadUserCsv: build.mutation<
        UploadUserCsvApiResponse,
        UploadUserCsvApiArg
      >({
        query: () => ({ url: `/api/user/csv`, method: 'POST' }),
        invalidatesTags: ['user'],
      }),
      getManyUser: build.query<GetManyUserApiResponse, GetManyUserApiArg>({
        query: (queryArg) => ({
          url: `/api/user`,
          params: {
            fields: queryArg.fields,
            s: queryArg.s,
            filter: queryArg.filter,
            or: queryArg.or,
            sort: queryArg.sort,
            join: queryArg.join,
            limit: queryArg.limit,
            offset: queryArg.offset,
            page: queryArg.page,
            cache: queryArg.cache,
          },
        }),
        providesTags: ['user'],
      }),
      getOneUser: build.query<GetOneUserApiResponse, GetOneUserApiArg>({
        query: (queryArg) => ({
          url: `/api/user/${queryArg.id}`,
          params: {
            fields: queryArg.fields,
            join: queryArg.join,
            cache: queryArg.cache,
          },
        }),
        providesTags: ['user'],
      }),
      updateOneUser: build.mutation<
        UpdateOneUserApiResponse,
        UpdateOneUserApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.user,
        }),
        invalidatesTags: ['user'],
      }),
      getOneConsent: build.query<GetOneConsentApiResponse, GetOneConsentApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/consent/${queryArg.id}`,
            params: {
              fields: queryArg.fields,
              join: queryArg.join,
              cache: queryArg.cache,
            },
          }),
          providesTags: ['consent'],
        }
      ),
      updateOneConsent: build.mutation<
        UpdateOneConsentApiResponse,
        UpdateOneConsentApiArg
      >({
        query: (queryArg) => ({
          url: `/api/consent/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.consent,
        }),
        invalidatesTags: ['consent'],
      }),
      createOneConsent: build.mutation<
        CreateOneConsentApiResponse,
        CreateOneConsentApiArg
      >({
        query: (queryArg) => ({
          url: `/api/consent`,
          method: 'POST',
          body: queryArg.consent,
        }),
        invalidatesTags: ['consent'],
      }),
      getAllUserNotes: build.query<
        GetAllUserNotesApiResponse,
        GetAllUserNotesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/note/all-user-notes/${queryArg.id}`,
        }),
        providesTags: ['note'],
      }),
      getOneNote: build.query<GetOneNoteApiResponse, GetOneNoteApiArg>({
        query: (queryArg) => ({
          url: `/api/note/${queryArg.id}`,
          params: {
            fields: queryArg.fields,
            join: queryArg.join,
            cache: queryArg.cache,
          },
        }),
        providesTags: ['note'],
      }),
      updateOneNote: build.mutation<
        UpdateOneNoteApiResponse,
        UpdateOneNoteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/note/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.note,
        }),
        invalidatesTags: ['note'],
      }),
      createOneNote: build.mutation<
        CreateOneNoteApiResponse,
        CreateOneNoteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/note`,
          method: 'POST',
          body: queryArg.note,
        }),
        invalidatesTags: ['note'],
      }),
      createOneRiskCalculation: build.mutation<
        CreateOneRiskCalculationApiResponse,
        CreateOneRiskCalculationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/risk-calculation`,
          method: 'POST',
          body: queryArg.createRiskCalculationDto,
        }),
        invalidatesTags: ['risk-calculation', 'user'],
      }),
      replaceOneRiskCalculation: build.mutation<
        ReplaceOneRiskCalculationApiResponse,
        ReplaceOneRiskCalculationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/risk-calculation/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.replaceRiskCalculationDto,
        }),
        invalidatesTags: ['risk-calculation', 'user'],
      }),
      updateOneRiskCalculation: build.mutation<
        UpdateOneRiskCalculationApiResponse,
        UpdateOneRiskCalculationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/risk-calculation/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateRiskCalculationDto,
        }),
        invalidatesTags: ['risk-calculation', 'user'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as prsonalApi };
export type GetOneRiskQuestionnaireApiResponse =
  /** status 200 Get one base response */ RiskQuestionnaire;
export type GetOneRiskQuestionnaireApiArg = {
  id: string;
  /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
  fields?: string[];
  /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
  join?: string[];
  /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
  cache?: number;
};
export type UpdateOneRiskQuestionnaireApiResponse =
  /** status 200 Response */ RiskQuestionnaire;
export type UpdateOneRiskQuestionnaireApiArg = {
  id: string;
  riskQuestionnaire: RiskQuestionnaire;
};
export type CreateOneRiskQuestionnaireApiResponse =
  /** status 201 Get create one base response */ RiskQuestionnaire;
export type CreateOneRiskQuestionnaireApiArg = {
  riskQuestionnaire: RiskQuestionnaire;
};
export type GetManyLifeQualityWorryQuestionnaireApiResponse =
  /** status 200 Get many base response */
    | GetManyLifeQualityWorryQuestionnaireResponseDto
    | LifeQualityWorryQuestionnaire[];
export type GetManyLifeQualityWorryQuestionnaireApiArg = {
  /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
  fields?: string[];
  /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
  s?: string;
  /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
  filter?: string[];
  /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
  or?: string[];
  /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
  sort?: string[];
  /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
  join?: string[];
  /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
  limit?: number;
  /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
  offset?: number;
  /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
  page?: number;
  /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
  cache?: number;
};
export type CreateOneLifeQualityWorryQuestionnaireApiResponse =
  /** status 201 Get create one base response */ LifeQualityWorryQuestionnaire;
export type CreateOneLifeQualityWorryQuestionnaireApiArg = {
  lifeQualityWorryQuestionnaire: LifeQualityWorryQuestionnaire;
};
export type GetOneLifeQualityWorryQuestionnaireApiResponse =
  /** status 200 Get one base response */ LifeQualityWorryQuestionnaire;
export type GetOneLifeQualityWorryQuestionnaireApiArg = {
  id: string;
  /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
  fields?: string[];
  /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
  join?: string[];
  /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
  cache?: number;
};
export type UpdateOneLifeQualityWorryQuestionnaireApiResponse =
  /** status 200 Response */ LifeQualityWorryQuestionnaire;
export type UpdateOneLifeQualityWorryQuestionnaireApiArg = {
  id: string;
  lifeQualityWorryQuestionnaire: LifeQualityWorryQuestionnaire;
};
export type CreateManyLifeQualityWorryQuestionnaireApiResponse =
  /** status 201 Get create many base response */ LifeQualityWorryQuestionnaire[];
export type CreateManyLifeQualityWorryQuestionnaireApiArg = {
  createManyLifeQualityWorryQuestionnaireDto: CreateManyLifeQualityWorryQuestionnaireDto;
};
export type GetCurrentUserApiResponse = /** status 200 The current user */ User;
export type GetCurrentUserApiArg = void;
export type RandomizeUserApiResponse =
  /** status 200 Randomizes the user */ User;
export type RandomizeUserApiArg = {
  id: string;
};
export type GetUserCsvApiResponse =
  /** status 200 Returns csv of specified type */ Csv;
export type GetUserCsvApiArg = {
  type: 'PRS' | 'BiRADS' | 'ALL';
};
export type UploadUserCsvApiResponse = unknown;
export type UploadUserCsvApiArg = void;
export type GetManyUserApiResponse = /** status 200 All users */ User[];
export type GetManyUserApiArg = {
  /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
  fields?: string[];
  /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
  s?: string;
  /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
  filter?: string[];
  /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
  or?: string[];
  /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
  sort?: string[];
  /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
  join?: string[];
  /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
  limit?: number;
  /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
  offset?: number;
  /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
  page?: number;
  /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
  cache?: number;
};
export type GetOneUserApiResponse =
  /** status 200 Get one base response */ User;
export type GetOneUserApiArg = {
  id: string;
  /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
  fields?: string[];
  /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
  join?: string[];
  /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
  cache?: number;
};
export type UpdateOneUserApiResponse = /** status 200 Response */ User;
export type UpdateOneUserApiArg = {
  id: string;
  user: User;
};
export type GetOneConsentApiResponse =
  /** status 200 Get one base response */ Consent;
export type GetOneConsentApiArg = {
  id: string;
  /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
  fields?: string[];
  /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
  join?: string[];
  /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
  cache?: number;
};
export type UpdateOneConsentApiResponse = /** status 200 Response */ Consent;
export type UpdateOneConsentApiArg = {
  id: string;
  consent: Consent;
};
export type CreateOneConsentApiResponse =
  /** status 201 Get create one base response */ Consent;
export type CreateOneConsentApiArg = {
  consent: Consent;
};
export type GetAllUserNotesApiResponse =
  /** status 200 Get all users notes */ Note[];
export type GetAllUserNotesApiArg = {
  id: string;
};
export type GetOneNoteApiResponse =
  /** status 200 Get one base response */ Note;
export type GetOneNoteApiArg = {
  id: string;
  /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
  fields?: string[];
  /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
  join?: string[];
  /** Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a> */
  cache?: number;
};
export type UpdateOneNoteApiResponse = /** status 200 Response */ Note;
export type UpdateOneNoteApiArg = {
  id: string;
  note: Note;
};
export type CreateOneNoteApiResponse =
  /** status 201 Get create one base response */ Note;
export type CreateOneNoteApiArg = {
  note: Note;
};
export type CreateOneRiskCalculationApiResponse =
  /** status 201 Get create one base response */ RiskCalculation;
export type CreateOneRiskCalculationApiArg = {
  createRiskCalculationDto: CreateRiskCalculationDto;
};
export type ReplaceOneRiskCalculationApiResponse =
  /** status 200 Response */ RiskCalculation;
export type ReplaceOneRiskCalculationApiArg = {
  id: string;
  replaceRiskCalculationDto: ReplaceRiskCalculationDto;
};
export type UpdateOneRiskCalculationApiResponse =
  /** status 200 Response */ RiskCalculation;
export type UpdateOneRiskCalculationApiArg = {
  id: string;
  updateRiskCalculationDto: UpdateRiskCalculationDto;
};
export type QuestionnaireStatusEnum = 'FilledOut' | 'Open' | 'Closed';
export type AlcoholIntake = {
  id?: string;
  created?: string;
  updated?: string;
  howOften?: string;
  amount?: number;
};
export type Alcohol = {
  id?: string;
  created?: string;
  updated?: string;
  lightBeer?: AlcoholIntake;
  regularBeer?: AlcoholIntake;
  strongBeer?: AlcoholIntake;
  redWine?: AlcoholIntake;
  whiteWine?: AlcoholIntake;
  hardWine?: AlcoholIntake;
  liquor?: AlcoholIntake;
  alcopops?: AlcoholIntake;
};
export type Period = {
  id?: string;
  created?: string;
  updated?: string;
  ageBegun?: string;
  last12Months?: 'Ja' | 'Nej' | 'Jeg har hormonspiral';
  ageEnded?: string;
};
export type BirthControl = {
  id?: string;
  created?: string;
  updated?: string;
  everTakenBirthControl?: boolean;
  years?: string;
  last2Years?: boolean;
};
export type HormoneTreatment = {
  id?: string;
  created?: string;
  updated?: string;
  treated?: 'Ja' | 'Nej' | 'Ved ikke';
  years?: number;
  last5Years?: boolean;
};
export type Kid = {
  id?: string;
  created?: string;
  updated?: string;
  age?: number;
  gender?: string;
};
export type DiagnosisAge = {
  id?: string;
  created?: string;
  updated?: string;
  preciseAge?: number | null;
  approximateAge?: string | null;
  unknown?: boolean;
};
export type CancerDiagnoses = {
  id?: string;
  created?: string;
  updated?: string;
  breast?: DiagnosisAge | null;
  otherBreast?: DiagnosisAge | null;
  ovaries?: DiagnosisAge | null;
};
export type LifeState = {
  id?: string;
  created?: string;
  updated?: string;
  alive?: boolean | null;
  age?: number | null;
  birthYear?: string | null;
};
export type FamilyMember = {
  id?: string;
  created?: string;
  updated?: string;
  familyMemberKey: string;
  hadCancer?: ('Ja' | 'Nej' | 'Ved ikke') | null;
  gender: 'Female' | 'Male';
  isChildOfTarget?: boolean;
  cancerDiagnoses?: CancerDiagnoses | null;
  lifeState?: LifeState | null;
};
export type RiskQuestionnaire = {
  id?: string;
  created?: string;
  updated?: string;
  status: QuestionnaireStatusEnum;
  name: string;
  openDate: string;
  userId: string;
  doYouDrink?: boolean;
  alcohol?: Alcohol;
  period?: Period;
  birthControl?: BirthControl;
  hormoneTreatment?: HormoneTreatment;
  hasKids?: boolean;
  kids?: Kid[];
  maleCancerHistory?: boolean;
  familyMembers?: FamilyMember[];
};
export type Promis = {
  id?: string;
  created?: string;
  updated?: string;
  scared?: 'Never' | 'Rarely' | 'Sometimes' | 'Often' | 'Always';
  focus?: 'Never' | 'Rarely' | 'Sometimes' | 'Often' | 'Always';
  overwhelmed?: 'Never' | 'Rarely' | 'Sometimes' | 'Often' | 'Always';
  uneasy?: 'Never' | 'Rarely' | 'Sometimes' | 'Often' | 'Always';
  nervous?: 'Never' | 'Rarely' | 'Sometimes' | 'Often' | 'Always';
  neededHelp?: 'Never' | 'Rarely' | 'Sometimes' | 'Often' | 'Always';
  anxious?: 'Never' | 'Rarely' | 'Sometimes' | 'Often' | 'Always';
  tense?: 'Never' | 'Rarely' | 'Sometimes' | 'Often' | 'Always';
};
export type Lerman = {
  id?: string;
  created?: string;
  updated?: string;
  thoughtsOfCancer?: 'Never' | 'Rarely' | 'Sometimes' | 'AlmostAllTheTime';
  mood?: 'Never' | 'Rarely' | 'Sometimes' | 'AlmostAllTheTime';
  dailyActivities?: 'Never' | 'Rarely' | 'Sometimes' | 'AlmostAllTheTime';
  worryOverRisk?: 'Never' | 'Rarely' | 'Sometimes' | 'AlmostAllTheTime';
  howOften?: 'Never' | 'Rarely' | 'Sometimes' | 'AlmostAllTheTime';
  problem?: 'Never' | 'Rarely' | 'Sometimes' | 'AlmostAllTheTime';
};
export type LifeQualityWorryQuestionnaire = {
  id?: string;
  created?: string;
  updated?: string;
  status: QuestionnaireStatusEnum;
  name: string;
  openDate: string;
  userId: string;
  index: 1 | 2 | 3 | 4;
  walking?:
    | 'NoProblemWalking'
    | 'LittleProblemWalking'
    | 'ModerateProblemWalking'
    | 'BigProblemWalking'
    | 'CantWalk';
  hygiene?:
    | 'NoProblemHygiene'
    | 'LittleProblemHygiene'
    | 'ModerateProblemHygiene'
    | 'BigProblemHygiene'
    | 'CantHygiene';
  activity?:
    | 'NoProblemActivities'
    | 'LittleProblemActivities'
    | 'ModerateProblemActivities'
    | 'BigProblemActivities'
    | 'CantActivities';
  pain?:
    | 'NoPain'
    | 'LittlePain'
    | 'ModeratePain'
    | 'StrongPain'
    | 'ExtremePain';
  anxiety?:
    | 'NoAnxiety'
    | 'LittleAnxiety'
    | 'ModerateAnxiety'
    | 'MuchAnxiety'
    | 'ExtremeAnxiety';
  healthScore?: number;
  promis?: Promis;
  lerman?: Lerman;
};
export type GetManyLifeQualityWorryQuestionnaireResponseDto = {
  data: LifeQualityWorryQuestionnaire[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
};
export type CreateManyLifeQualityWorryQuestionnaireDto = {
  bulk: LifeQualityWorryQuestionnaire[];
};
export type RiskCalculation = {
  id?: string;
  created?: string;
  updated?: string;
  user: object;
  userId: string;
  tenYearRisk: number;
  calculationResult: object;
  riskQuestionnaire: RiskQuestionnaire;
  riskCalculationReleased: string;
  riskCalculationReleasedBy: object;
};
export type Consent = {
  id?: string;
  created?: string;
  updated?: string;
  userId?: string;
  informedConsent?: boolean;
  informedConsentDate?: string;
  medStudInformedSignature?: string;
  prsonalConsent?: boolean;
  resultAndConsequences?: boolean;
  prsonalConsentDate?: string;
  bloodSampleConsent?: boolean;
  bloodSampleConsentConsentDate?: string;
  medStudBloodSampleSignature?: string;
  information?: boolean;
  privateLifePolicy?: boolean;
  privateLifePolicyDate?: string;
};
export type Note = {
  id?: string;
  created?: string;
  updated?: string;
  userId?: string;
  date: string;
  reason: string;
  note: string;
  createdBy?: User;
};
export type User = {
  id?: string;
  created?: string;
  updated?: string;
  sub: string;
  cpr: string;
  name?: string;
  birthDate: string;
  inclusionDate?: string;
  signedUpFromHome?: boolean;
  email?: string;
  phone?: string;
  group?: 'InterventionGroup' | 'ControlGroup';
  riskCalculation?: RiskCalculation;
  role?: 'Participant' | 'MedicalStudent' | 'Admin';
  height?: number;
  weight?: number;
  bmi?: number;
  birads?: string | null;
  prs?: number | null;
  isMammographyProcessCompleted?: boolean;
  consent?: Consent;
  riskQuestionnaire?: RiskQuestionnaire;
  lifeQualityWorryQuestionnaires?: LifeQualityWorryQuestionnaire[];
  notes?: Note[];
  notesCreatedByUser?: Note[];
};
export type Csv = {
  csv: string;
};
export type CreateRiskCalculationDto = {
  userId: string;
};
export type ReplaceRiskCalculationDto = {};
export type UpdateRiskCalculationDto = {
  riskCalculationReleased: string;
};
export const {
  useGetOneRiskQuestionnaireQuery,
  useUpdateOneRiskQuestionnaireMutation,
  useCreateOneRiskQuestionnaireMutation,
  useGetManyLifeQualityWorryQuestionnaireQuery,
  useCreateOneLifeQualityWorryQuestionnaireMutation,
  useGetOneLifeQualityWorryQuestionnaireQuery,
  useUpdateOneLifeQualityWorryQuestionnaireMutation,
  useCreateManyLifeQualityWorryQuestionnaireMutation,
  useGetCurrentUserQuery,
  useRandomizeUserMutation,
  useGetUserCsvQuery,
  useUploadUserCsvMutation,
  useGetManyUserQuery,
  useGetOneUserQuery,
  useUpdateOneUserMutation,
  useGetOneConsentQuery,
  useUpdateOneConsentMutation,
  useCreateOneConsentMutation,
  useGetAllUserNotesQuery,
  useGetOneNoteQuery,
  useUpdateOneNoteMutation,
  useCreateOneNoteMutation,
  useCreateOneRiskCalculationMutation,
  useReplaceOneRiskCalculationMutation,
  useUpdateOneRiskCalculationMutation,
} = injectedRtkApi;
