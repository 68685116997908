import styled from 'styled-components';
import { Row, Col, Typography, Button, Grid } from 'antd';
import { PrsonalWrapper } from '@prsonal/website/shared/components';
import { Breakpoints } from '@prsonal/website/shared/utils';
import { QualityWorryQuestionnaireProps } from '../quality-worry-questionnaire.interfaces';
const { Paragraph, Text } = Typography;
const { useBreakpoint } = Grid;

export interface IntroductionProps extends QualityWorryQuestionnaireProps {
  name: string;
}

export function Introduction(props: IntroductionProps) {
  const screens = useBreakpoint();
  return (
    <PrsonalWrapper
      horizontalPadding={screens.lg ? 14.5 : 0.4}
      verticalPadding={screens.lg ? 6.25 : 0.4}
      backgroundColor="#F7FBFA"
      fullHeightBackground
    >
      <Row justify="center">
        <Col xs={24}>
          <TextBox>
            <Paragraph>
              <Text>Kære {props.name}</Text>
              <br />
              <br />
              <Text>
                I dette spørgeskema vil vi bede dig svare på spørgsmål, om
                hvordan du har haft det de sidste 7 dage og om din bekymring for
                brystkræft
              </Text>
              <br />
              <br />
              <Text>
                Der er i alt 19 spørgsmål. Nogle spørgsmål kan minde om
                hinanden, men det er vigtigt, at du besvarer dem, så godt du
                kan.
              </Text>
              <br />
              <br />
              <Text>
                Besvarelserne vil blive brugt til at måle om og hvordan det
                påvirker kvinder at få målt deres risiko for brystkræft.
              </Text>
            </Paragraph>
          </TextBox>
        </Col>
      </Row>
      <Row gutter={10} justify="center">
        <Col>
          <Button onClick={() => props.onChangePage(-1)}>Forrige</Button>
        </Col>
        <Col>
          <Button type="primary" onClick={() => props.onChangePage(1)}>
            Næste
          </Button>
        </Col>
      </Row>
    </PrsonalWrapper>
  );
}

const TextBox = styled.div`
  border: 1px solid var(--neutral-6, #bfbfbf);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  @media screen and (min-width: ${Breakpoints.lg}px) {
    padding: 3.4375rem;
  }
  padding: 0.625rem;
  max-width: ${Breakpoints.lg}px;
  margin: auto;
  margin-bottom: 1.88rem;
  box-sizing: border-box;
`;

export default Introduction;
