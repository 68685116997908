import styled from 'styled-components';
import { Button, Typography } from 'antd';
import { IntroductionImage } from '@prsonal/website/shared/components';
import { Breakpoints, Sizes } from '@prsonal/website/shared/utils';
const { Text } = Typography;
/* eslint-disable-next-line */
export interface IntroductionProps {
  onStartQuestionnaire: () => void;
  onGoBack: () => void;
  name?: string;
}

export function Introduction(props: IntroductionProps) {
  return (
    <StyledIntroductionContainer>
      <StyledIntroductionBox>
        <StyledTextBox>
          <Text>Kære {props.name}</Text>
          <Text>
            På de følgende sider vil vi bede dig svare på en række spørgsmål,
            der handler om menstruation, prævention, brug af hormoner, fødsler
            og kræft i din familie. Nogle spørgsmål kan minde om hinanden, men
            det er vigtigt, at du besvarer dem alle alligevel.
          </Text>
          <Text>
            Det tager omkring 15 minutter at besvare spørgeskemaet. Dine svar
            bliver gemt løbende, så du kan vende tilbage senere og fortsætte.
          </Text>
          <Text>
            Svarene i dette spørgeskema vil blive brugt til at udregne din
            risiko for brystkræft sammen med informationer om din højde og vægt,
            blodprøven (med de genetiske varianter) og tætheden af dit brystvæv
            vurderet ud fra mammografibilledet.
          </Text>
          <StyledImageContainer>
            <IntroductionImage />
          </StyledImageContainer>
        </StyledTextBox>
      </StyledIntroductionBox>
      <StyledButtonContainer>
        <Button onClick={props.onGoBack}>Forrige</Button>
        <Button type="primary" onClick={props.onStartQuestionnaire}>
          Næste
        </Button>
      </StyledButtonContainer>
    </StyledIntroductionContainer>
  );
}

export default Introduction;

const StyledIntroductionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${Sizes.STANDARD_GAP}rem;
  padding: ${Sizes.STANDARD_GAP}rem;
`;

const StyledImageContainer = styled.div`
  @media screen and (max-width: ${Breakpoints.xl}px) {
    display: none;
  }
  position: absolute;
  z-index: 1;
  margin-left: -18.75rem;
  margin-top: 13.5rem;
`;

const StyledIntroductionBox = styled.div`
  display: inline-flex;
  padding: 3.438rem;
  align-items: flex-start;
  border: ${Sizes.SMALL_BORDER}rem solid var(--neutral-6);
  background: #fff;
  box-shadow: 0px 0.25rem ${Sizes.STANDARD_GAP}rem 0px var(--shadow);
`;

const StyledTextBox = styled.div`
  max-width: 49rem;
  display: flex;
  flex-direction: column;
  gap: ${Sizes.STANDARD_GAP}rem;
`;
