import { LifeQualityWorryQuestionnaire } from '@prsonal/website/shared/data-access';
import SurveyQuestion from '../../components/survey-question/survey-question';
import { QualityWorryQuestionnaireProps } from '../../quality-worry-questionnaire.interfaces';

const options = [
  {
    text: 'Jeg har ingen problemer med at gå omkring',
    value: 'NoProblemWalking',
  },
  {
    text: 'Jeg har lidt problemer med at gå omkring',
    value: 'LittleProblemWalking',
  },
  {
    text: 'Jeg har moderate problemer med at gå omkring',
    value: 'ModerateProblemWalking',
  },
  {
    text: 'Jeg har store problemer med at gå omkring',
    value: 'BigProblemWalking',
  },
  { text: 'Jeg kan ikke gå omkring', value: 'CantWalk' },
];

const title = 'Bevægelighed';

export function EqMobility(props: QualityWorryQuestionnaireProps) {
  return (
    <SurveyQuestion
      title={title}
      options={options}
      onChangePage={props.onChangePage}
      onValueChange={(value: string) =>
        props.onValueChange({
          walking: value as LifeQualityWorryQuestionnaire['walking'],
        })
      }
      currentChecked={props.answers?.walking}
    />
  );
}

export default EqMobility;
