import { RiskLevel, RiskLevelDa } from './types';

export function getRiskLevel(riskDecimal: number): RiskLevel {
  // Convert to percent to ensure better readybility
  const risk = riskDecimal * 100;

  if (risk <= 2.44) return 'low-risk';
  if (risk <= 5.15) return 'medium-risk';
  if (risk <= 8.0) return 'increased-risk';
  return 'high-risk';
}

export function getRiskLevelDa(riskDecimal: number): RiskLevelDa {
  const risk = riskDecimal * 100;

  if (risk <= 2.44) return 'Lav';
  if (risk <= 5.15) return 'Mellem';
  if (risk <= 8.0) return 'Forhøjet';
  return 'Høj';
}
