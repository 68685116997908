import { GlobalToken, Table, TableProps, theme } from 'antd';
import styled from 'styled-components';
import React from 'react';

const { useToken } = theme;
/* eslint-disable-next-line */
export interface PrsonalTableProps<T extends Object = any>
  extends TableProps<T> {}

export function PrsonalTable(props: PrsonalTableProps) {
  const { token } = useToken();

  return (
    <StyledTable
      token={token}
      dataSource={props.dataSource}
      columns={props.columns}
      pagination={false}
      {...props}
    >
      {props.children}
    </StyledTable>
  );
}

export default PrsonalTable;

const StyledTable = styled(Table)<{ token: GlobalToken }>`
  thead th.ant-table-cell {
    background-color: ${(props) => props.token.colorFillSecondary};
    border-radius: 0% !important;
    font-weight: 500;
  }

  border: 1px solid var(--neutral-6);
`;
