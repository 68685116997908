import styled from 'styled-components';
import { Row, Col, Grid, Typography, Popover } from 'antd';
import { PrsonalWrapper } from '@prsonal/website/shared/components';
import { useEffect, useState } from 'react';
import { ReactComponent as TwoWomen } from '../../icons/begge.svg';

const { useBreakpoint } = Grid;
const { Title, Paragraph, Text } = Typography;

const HoverTextArray = [
  {
    id: 'mammography-intervention',
    content:
      'Du får en mammografi, som du plejer, og får svar efter ca. 10 hverdage.',
    title: 'Mammografi',
    intervention: true,
  },
  {
    id: 'questionaire-intervention',
    content:
      'Du skal besvare et spørgeskema, når projektet starter og undervejs.',
    title: 'Spørgeskema',
    intervention: true,
  },
  {
    id: 'blodsample',
    content:
      'Du får taget en blodprøve. Den bliver undersøgt for ca. 300 genetiske varianter i dit arvemateriale, der kan øge risikoen for at udvikle brystkræft.',
    title: 'Blodprøve og gentest',
    intervention: true,
  },
  {
    id: 'mammography-control',
    content:
      'Du får en mammografi, som du plejer, og får svar efter ca. 10 hverdage.',
    title: 'Mammografi',
  },
  {
    id: 'questionaire-control',
    content:
      'Du skal besvare et spørgeskema, når projektet starter og undervejs.',
    title: 'Spørgeskema',
  },
];

function content(popOverContent?: {
  title: string;
  content: string;
  intervention?: boolean;
}) {
  return (
    <StyledBox intervention={popOverContent?.intervention}>
      <Title level={5}>{popOverContent?.title}</Title>
      <Paragraph>{popOverContent?.content}</Paragraph>
    </StyledBox>
  );
}

function DescriptionParagraph() {
  const screens = useBreakpoint();
  return (
    <>
      <Title level={screens.lg ? 2 : 4}>Hvad indebærer det at deltage?</Title>
      <br />
      <Paragraph>
        Når du har forstået, hvad projektet går ud på, og talt med en
        projektmedarbejder skal du overveje, om det er noget for dig. Du har 24
        timers betænkningstid. Du kan også vælge at deltage inden da. Når du har
        besluttet dig for at deltage, skriver du derefter under på
        samtykkeerklæringen ved hjælp af MitID. Du kan også tale med en
        projektmedarbejder den dag, du møder op til mammografi. Du har derefter
        stadig 24 timer betænkningstid inden du underskriver samtykkeerklæringen
        og deltager i PRSONAL.
      </Paragraph>
      <Paragraph>
        Siger du ja til at deltage i forskningsprojektet, får du taget en
        mammografi, som du plejer. Du skal også besvare et spørgeskema om brug
        af hormoner, graviditet og fødsler, livsstil, brystkræft i familien mm.
        Desuden skal du undervejs svare på 3 spørgeskemaer med 19 spørgsmål, om
        hvordan du har det. Du bliver også målt og vejet. Derefter trækkes der
        lod. Du får med det samme at vide, om du er en af de 1000 kvinder, der
        kommer i kontrolgruppen eller en af de 1000, der kommer i
        interventionsgruppen.
      </Paragraph>
      <Paragraph>
        Kommer du i <StyledBold>kontrolgruppen</StyledBold>, sker der ikke
        yderligere denne dag. Du bliver inviteret til brystkræftscreening hvert
        andet år, som du plejer. Indenfor de næste to år skal du desuden svare
        på 3 spørgeskemaer med 19 spørgsmål, om hvordan du har det.
      </Paragraph>
      <Paragraph>
        Kommer du i <StyledBold>interventionsgruppen</StyledBold>, får du taget
        en blodprøve. Blodprøven undersøges or ca. 300 genetiske varianter i dit
        arvemateriale, der har betydning for din risiko for at udvikle
        brystkræft. Alle data samles i en model, der kan udregne, hvor høj eller
        lav din risiko for brystkræft er. Du får besked om din risiko og hvornår
        du skal screenes næste gang. Indenfor de næste to år skal du desuden
        svare på 3 spørgeskemaer med 19 spørgsmål, om hvordan du har det.
      </Paragraph>
    </>
  );
}

export function ParticipationDescription() {
  const screens = useBreakpoint();
  const [popOverContent, setPopovercontent] = useState<{
    title: string;
    content: string;
  }>();

  useEffect(() => {
    let showContentAction: string;
    let hideContentAction: string;

    for (let i = 0; i < HoverTextArray.length; i++) {
      if (screens.lg) {
        showContentAction = 'mouseover';
        hideContentAction = 'mouseleave';
      } else {
        showContentAction = 'click';
        hideContentAction = 'blur';
      }

      document
        ?.getElementById(HoverTextArray[i].id)
        ?.addEventListener(showContentAction, function (e) {
          setPopovercontent(HoverTextArray[i]);
        });
      document
        ?.getElementById(HoverTextArray[i].id)
        ?.addEventListener(hideContentAction, function (e) {
          setPopovercontent(undefined);
        });
    }
  });

  return (
    <div id="participation-description">
      <PrsonalWrapper>
        <Row
          align="middle"
          justify={screens.lg ? 'space-between' : 'center'}
          gutter={[40, 20]}
        >
          <Col sm={24} lg={14}>
            {screens.lg ? (
              <DescriptionParagraph />
            ) : (
              <>
                {/* Renders illustarion followed by description in full width in mobile view */}
                <Row>
                  <Popover
                    content={content(popOverContent)}
                    open={!!popOverContent}
                    overlayInnerStyle={{ padding: 0 }}
                    arrow={false}
                  >
                    <StyledTwoWomen width={'80vw'} height={'auto'} />
                  </Popover>
                  <DescriptionParagraph />
                </Row>
              </>
            )}
          </Col>

          {screens.lg ? (
            <Col lg={10} sm={0}>
              <Popover
                content={content(popOverContent)}
                open={!!popOverContent}
                overlayInnerStyle={{ padding: 0 }}
                arrow={false}
                placement="left"
              >
                <TwoWomen />
              </Popover>
            </Col>
          ) : null}
        </Row>
      </PrsonalWrapper>
    </div>
  );
}

const StyledBold = styled(Text)`
  color: var(--cyan-9);
  font-weight: 700;
`;

const StyledTwoWomen = styled(TwoWomen)`
  margin-bottom: 2.5rem;
`;

const StyledBox = styled.div<{ intervention?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 15.6875rem;
  padding: 1rem 1.5rem;
  align-items: flex-start;
  gap: 1rem;
  border-radius: 0.25rem;
  border-left: ${(props) =>
    props.intervention ? '5px solid #ADD9C9' : '5px solid #D6E4FF'};
  background: var(--conditional-pop-over, --neutral-1);
  /* drop-shadow / 0.12+0.8+0.5 */
  box-shadow:
    0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12);
`;

export default ParticipationDescription;
