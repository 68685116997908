import { AlcoholPickerType } from '../../risk-questionnaire-page.interface';

const alcoholList: AlcoholPickerType[] = [
  {
    key: 'lightBeer',
    type: 'Lys øl (2,5 %)',
    amountText: 'Angiv antal flasker/dåser',
  },
  {
    key: 'regularBeer',
    type: 'Almindelig øl (4,6 %)',
    amountText: 'Angiv antal flasker/dåser',
  },
  {
    key: 'strongBeer',
    type: 'Stærk øl (>5 %)',
    amountText: 'Angiv antal flasker/dåser',
  },
  {
    key: 'redWine',
    type: 'Rødvin',
    amountText: 'Angiv antal glas',
  },
  {
    key: 'whiteWine',
    type: 'Hvidvin og rosévin',
    amountText: 'Angiv antal glas',
  },
  {
    key: 'hardWine',
    type: 'Hedvin (f.eks. portvin)',
    amountText: 'Angiv antal genstande',
  },
  {
    key: 'liquor',
    type: 'Spiritus (f.eks. snaps og vodka)',
    amountText: 'Angiv antal genstande',
  },
  {
    key: 'alcopops',
    type: 'Alcopops (f.eks. Bacardi Breezer, Somersby)',
    amountText: 'Angiv antal flasker/dåser',
  },
];

const selectOption = [
  { value: 'Aldrig', label: 'Aldrig' },
  { value: 'Dagligt', label: 'Dagligt' },
  { value: 'Ugentligt', label: 'Ugentligt' },
  { value: 'Månedligt', label: 'Månedligt' },
];

export { selectOption, alcoholList };
