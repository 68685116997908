import { baseEnvironment } from '@prsonal/website/shared/util-environment';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';
export const baseSplitApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: baseEnvironment.API_URL,
    paramsSerializer: (params) => {
      for (const key in params) {
        if (!params[key]) delete params[key];
      }
      return queryString.stringify(params);
    },
    prepareHeaders: (headers) => {
      headers.set(
        'authorization',
        `Bearer ${window.sessionStorage.getItem(
          '@criipto-verify-react/session'
        )}`
      );
    },
  }),
  endpoints: () => ({}),
});
