import { Button, Typography, Table, Tag } from 'antd';
import styled from 'styled-components';
import {
  Breakpoints,
  Questionnaire,
  Sizes,
} from '@prsonal/website/shared/utils';
import PrsonalTable from '../prsonal-table/prsonal-table';
import { format } from 'date-fns';
import { QuestionnaireStatusEnum } from '@prsonal/website/shared/data-access';

const { Column } = Table;
const { Text } = Typography;
export interface QuestionnaireTableProps {
  questionnaires?: Questionnaire[];
  isLoading?: boolean;
}

export function QuestionnaireTable(props: QuestionnaireTableProps) {
  const isReadonlyAvailable = (
    status: QuestionnaireStatusEnum | undefined,
    isAdmin: boolean | undefined
  ) => status === 'FilledOut' && isAdmin;

  return (
    <StyledQuestionnaireTable>
      <PrsonalTable
        dataSource={props.questionnaires}
        rowKey={'id'}
        loading={props.isLoading}
      >
        <Column
          title="Spørgeskemaer"
          dataIndex={'name'}
          render={(name: string, questionnaire: Questionnaire) => (
            <Text
              type={questionnaire.status === 'Closed' ? 'secondary' : undefined}
            >
              {name}
            </Text>
          )}
        />
        <Column
          title="Status"
          dataIndex={'status'}
          render={(
            status: QuestionnaireStatusEnum,
            questionnaire: Questionnaire
          ) => (
            <Text type={status === 'Closed' ? 'secondary' : undefined}>
              {questionnaire.status === 'FilledOut' ? (
                <Tag color="green">Udfyldt og afsendt</Tag>
              ) : questionnaire.openDate ? (
                <Text>
                  Kan udfyldes <br />
                  {`d. ${format(new Date(questionnaire.openDate), 'dd-MM-yy')}`}
                </Text>
              ) : (
                'Ikke inkluderet'
              )}
            </Text>
          )}
        />
        <Column
          title=" "
          render={(_, questionnaire: Questionnaire) =>
            questionnaire.status !== 'FilledOut' ||
            isReadonlyAvailable(questionnaire.status, questionnaire.isAdmin) ? (
              <Button
                type="primary"
                disabled={questionnaire.status === 'Closed'}
                onClick={questionnaire.onFillOut}
              >
                {isReadonlyAvailable(
                  questionnaire.status,
                  questionnaire.isAdmin
                )
                  ? 'Se besvarelse'
                  : questionnaire.showEditText
                  ? 'Redigér'
                  : 'Udfyld'}
              </Button>
            ) : (
              <StyledText>Udfyldt</StyledText>
            )
          }
        />
      </PrsonalTable>
    </StyledQuestionnaireTable>
  );
}

export default QuestionnaireTable;

const StyledQuestionnaireTable = styled.div`
  box-shadow: 0px 0.25rem ${Sizes.STANDARD_GAP}rem 0px var(--shadow);
`;

const StyledText = styled(Text)`
  padding: 0 0 0 ${Sizes.BUTTON_PADDING_HORIZONTAL}rem;
  @media screen and (min-width: ${Breakpoints.lg}px) {
    padding: 0 ${Sizes.BUTTON_PADDING_HORIZONTAL}rem;
  }
`;
