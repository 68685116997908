import styled from 'styled-components';
import { RiskQuestionnaireProps } from '../../risk-questionnaire-page.interface';
import Question from '../../components/question/question';
import { Alert, Button, Radio, Select } from 'antd';
import { LastPeriodEnum } from '@prsonal/shared/utils/types';
import { Breakpoints, Sizes } from '@prsonal/website/shared/utils';
import { useState } from 'react';
import { AlertContainer } from '../../components/alert-container/alert-container';
import {
  isPeriodAnswersFilledOut,
  showDataMissingMessage,
} from '../../utils/valid-check.utils';
import { RiskQuestionnaire } from '@prsonal/website/shared/data-access';

const periodBeginAges = [
  { value: '8 år', label: '8 år' },
  { value: '9 år', label: '9 år' },
  { value: '10 år', label: '10 år' },
  { value: '11 år', label: '11 år' },
  { value: '12 år', label: '12 år' },
  { value: '13 år', label: '13 år' },
  { value: '14 år', label: '14 år' },
  { value: '15 år', label: '15 år' },
  { value: '16 år', label: '16 år' },
  { value: '17 år', label: '17 år' },
  { value: '18 år', label: '18 år' },
];

export function Period(props: RiskQuestionnaireProps) {
  const [periodAnswers, setPeriodAnswers] = useState<
    Pick<RiskQuestionnaire, 'period'>
  >({ period: props.answers?.period });

  return (
    <StyledPeriod>
      {!isPeriodAnswersFilledOut(periodAnswers) &&
      showDataMissingMessage(props) ? (
        <AlertContainer />
      ) : null}
      <StyledAlert
        type="info"
        message="Besvar så godt du kan, hvis du ikke kan huske præcis hvornår."
        showIcon
        closable
      />
      <Question title="Hvor gammel var du cirka, da du fik menstruation?">
        <Select
          disabled={props.answers.status !== 'Open'}
          style={{ width: '210px' }}
          options={periodBeginAges}
          placeholder="Vælg alder"
          value={periodAnswers.period?.ageBegun}
          onChange={(e) =>
            setPeriodAnswers((v) => ({
              ...v,
              period: { ...v?.period, ageBegun: e },
            }))
          }
        />
      </Question>
      <Question
        title="Har du haft menstruation de sidste 12 måneder?"
        subTitle="(Hvis du har hormonspiral, skal du kun sætte kryds i denne boks)"
        breaklineBeforeSubTitle
      >
        <StyledMobileRadioGroup
          disabled={props.answers.status !== 'Open'}
          value={periodAnswers?.period?.last12Months}
          onChange={({ target: { value } }) => {
            setPeriodAnswers((v) => ({
              ...v,
              period: {
                ...v?.period,
                last12Months: value,
                ageEnded:
                  value === LastPeriodEnum.Ja ||
                  value === LastPeriodEnum.JegHarHormonspiral
                    ? undefined
                    : v?.period?.ageEnded,
              },
            }));
          }}
        >
          <Radio value={LastPeriodEnum.Ja}>Ja</Radio>
          <Radio value={LastPeriodEnum.Nej}>Nej</Radio>
          <Radio value={LastPeriodEnum.JegHarHormonspiral}>
            Jeg har hormonspiral
          </Radio>
        </StyledMobileRadioGroup>
      </Question>
      {periodAnswers.period?.last12Months === LastPeriodEnum.Nej ? (
        <PeriodEndContainer>
          <Question title="Hvor gammel var du, da din menstruation stoppede?">
            <StyledRadioGroup
              disabled={props.answers.status !== 'Open'}
              value={periodAnswers?.period?.ageEnded}
              onChange={({ target: { value } }) =>
                setPeriodAnswers((v) => ({
                  ...v,
                  period: { ...v?.period, ageEnded: value },
                }))
              }
            >
              <Radio value={'Under 40 år'}>Under 40 år</Radio>
              <Radio value={'40-44 år'}>40-44 år</Radio>
              <Radio value={'45-49 år'}>45-49 år</Radio>
              <Radio value={'50-54 år'}>50-54 år</Radio>
              <Radio value={'Over 54 år'}>Over 54 år</Radio>
            </StyledRadioGroup>
          </Question>
        </PeriodEndContainer>
      ) : null}
      <ButtonContainer>
        <Button
          onClick={() => {
            props.onValueChange(periodAnswers);
            props.onChangePage(-1);
          }}
        >
          Forrige
        </Button>
        <Button
          type="primary"
          onClick={() => {
            props.onValueChange(periodAnswers);
            props.onChangePage(1);
          }}
        >
          Næste
        </Button>
      </ButtonContainer>
    </StyledPeriod>
  );
}

export default Period;

const StyledPeriod = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
`;

const StyledMobileRadioGroup = styled(Radio.Group)`
  @media screen and (max-width: ${Breakpoints.lg}px) {
    display: flex;
    flex-direction: column;
    gap: ${Sizes.STANDARD_GAP}rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${Sizes.STANDARD_GAP}rem;
  padding: ${Sizes.STANDARD_GAP}rem;
`;

const PeriodEndContainer = styled.div`
  border: ${Sizes.SMALL_BORDER}rem solid var(--neutral-5);
  padding: ${Sizes.LARGE_GAP}rem ${Sizes.LARGE_GAP}rem;
`;

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  gap: ${Sizes.STANDARD_GAP}rem;
`;

const StyledAlert = styled(Alert)`
  margin-top: ${Sizes.LARGE_GAP}rem;
  @media screen and (min-width: ${Breakpoints.lg}px) {
    margin-top: 0px;
    width: 30rem;
  }
`;
