import {
  Consent,
  Note,
  User,
  prsonalApi,
} from '@prsonal/website/shared/data-access';
import {
  Questionnaire,
  getQoLData,
  getQuestionnaireStatus,
} from '@prsonal/website/shared/utils';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { addDays } from 'date-fns';
import { useNavigate } from 'react-router-dom';

export function useGetPadminPageProps(
  userId?: string,
  isAdmin?: boolean
): [
  user?: User | undefined,
  questionnaires?: Questionnaire[],
  fetching?: boolean,
  consent?: Consent | undefined,
  notes?: Note[]
] {
  const navigate = useNavigate();
  if (!userId) return [];

  const { data: user, isLoading: fetchingUser } = prsonalApi.useGetOneUserQuery(
    {
      id: userId,
      join: [
        'riskQuestionnaire',
        'lifeQualityWorryQuestionnaires',
        'consent',
        'riskCalculation',
      ],
    }
  );
  const { data: consent, isLoading: fetchingConsent } =
    prsonalApi.useGetOneConsentQuery(
      user?.consent?.id ? { id: user?.consent?.id } : skipToken
    );

  const { data: notes, isLoading: fetchingNotes } =
    prsonalApi.useGetAllUserNotesQuery({ id: userId });

  const qol1Data = getQoLData(user?.lifeQualityWorryQuestionnaires, 1);
  const qol2Data = getQoLData(user?.lifeQualityWorryQuestionnaires, 2);
  const qol3Data = getQoLData(user?.lifeQualityWorryQuestionnaires, 3);
  const qol4Data = getQoLData(user?.lifeQualityWorryQuestionnaires, 4);

  const questionnaires: Questionnaire[] = [
    {
      id: '1',
      name: 'Samtykke til deltagelse i PRSONAL',
      status: consent?.informedConsent ? 'FilledOut' : 'Open',
      openDate: new Date().toISOString(),
      onFillOut: () =>
        navigate('/samtykke/stud-med-samtykke-1', { state: user }),
    },
    {
      id: '2',
      name: 'Risikoskema',
      status:
        consent?.prsonalConsent && user?.riskQuestionnaire?.status
          ? user.riskQuestionnaire.status
          : 'Closed',
      openDate: user?.inclusionDate,
      isAdmin: isAdmin,
      showEditText:
        new Date(user?.riskQuestionnaire?.updated ?? '') >
        new Date(user?.riskQuestionnaire?.created ?? ''),
      onFillOut: () => navigate(`/risikoskema/${user?.id}`),
    },
    {
      id: '3',
      name: 'Livskvalitet og bekymring (dag 1)',
      status: consent?.prsonalConsent
        ? getQuestionnaireStatus(qol1Data.openDate, qol1Data.status)
        : 'Closed',
      openDate: user?.inclusionDate,
      onFillOut: () => navigate(`/bekymringsskema/${user?.id}`, { state: 1 }),
    },
    {
      id: '4',
      name: 'Livskvalitet og bekymring (dag 180)',
      status: getQuestionnaireStatus(qol2Data.openDate, qol2Data.status),
      openDate: user?.inclusionDate
        ? addDays(new Date(user.inclusionDate), 180).toISOString()
        : user?.inclusionDate,
      onFillOut: () => navigate(`/bekymringsskema/${user?.id}`, { state: 2 }),
    },
    {
      id: '5',
      name: 'Livskvalitet og bekymring (dag 365)',
      status: getQuestionnaireStatus(qol3Data.openDate, qol3Data.status),
      openDate: user?.inclusionDate
        ? addDays(new Date(user.inclusionDate), 365).toISOString()
        : user?.inclusionDate,
      onFillOut: () => navigate(`/bekymringsskema/${user?.id}`, { state: 3 }),
    },
    {
      id: '6',
      name: 'Livskvalitet og bekymring (dag 800)',
      status: getQuestionnaireStatus(qol4Data.openDate, qol4Data.status),
      openDate: user?.inclusionDate
        ? addDays(new Date(user.inclusionDate), 800).toISOString()
        : user?.inclusionDate,
      onFillOut: () => navigate(`/bekymringsskema/${user?.id}`, { state: 4 }),
    },
  ];

  return [
    user,
    questionnaires,
    fetchingUser && fetchingConsent && fetchingNotes,
    consent,
    notes,
  ];
}
