import { PrsonalWrapper } from '@prsonal/website/shared/components';
import { Typography, Grid } from 'antd';
import styled from 'styled-components';
import TimelineDesktop from '../../icons/illustrationer_tidslinje.png';
import { TimelineMobile } from '@prsonal/website/shared/components';
import { Breakpoints } from '@prsonal/website/shared/utils';

const { Title } = Typography;
const { Text } = Typography;
const { useBreakpoint } = Grid;

/* eslint-disable-next-line */
export interface ResearchProjectTimelineProps {}

export function ResearchProjectTimeline(props: ResearchProjectTimelineProps) {
  const screens = useBreakpoint();

  return (
    <PrsonalWrapper verticalPadding={0}>
      <StyledTitle level={screens.lg ? 3 : 4}>
        Forskningsprojektets forløb - tidslinje
      </StyledTitle>
      <br />
      <br />
      <Text>
        Forskningsprojektet begynder med dag 1, hvor du kommer til mammografi og
        kan blive en del af forskningsprojektet, hvis du allerede har
        underskrevet samtykkeerklæringen. Hvis du ikke har, kan du blive
        informeret om projektet og komme tilbage senere, underskrive
        samtykkeerklæringen og begynde din deltagelse i PRSONAL.
        <br />
        <br />
        Dag 10 får alle kvinder svar på mammografien. Dag 90 får kvinderne i
        interventionsgruppen deres risiko at vide. Dag 180, 365 og 800 får alle
        deltagerne et spørgeskema med 19 spørgsmål.
        <br />
        <br />
        Dag 180, 365 og 800 får alle deltagerne et spørgeskema med 19 spørgsmål.
        <br />
        <br />
      </Text>
      <br />
      <img
        src={screens.lg ? TimelineDesktop : TimelineMobile.default}
        alt="Tidslinje"
        style={{ width: '100%', height: 'auto' }}
      />
    </PrsonalWrapper>
  );
}

export default ResearchProjectTimeline;

const StyledTitle = styled(Title)`
  @media screen and (min-width: ${Breakpoints.lg}px) {
    text-align: center;
  }
`;
