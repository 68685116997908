import styled from 'styled-components';
import { Typography, Button } from 'antd';
import { Sizes, phoneNumber } from '@prsonal/website/shared/utils';

const { Text, Title } = Typography;

export interface ReadMoreSectionProps {
  onInterested: () => void;
}
export function ReadMoreSection(props: ReadMoreSectionProps) {
  return (
    <StyledReadMoreSection>
      <StyledDocumentInfoSection>
        <StyledDocumentInfoContainer>
          <Text>
            PRSONAL Population-based Randomized Study Of a Novel breast cancer
            risk ALgorithm
          </Text>
        </StyledDocumentInfoContainer>
        <StyledDocumentInfoContainer>
          <Text>Revision nummer:</Text>
          <Text>5.0</Text>
        </StyledDocumentInfoContainer>
        <StyledDocumentInfoContainer>
          <Text>Dato:</Text>
          <Text>04. juni 2024</Text>
        </StyledDocumentInfoContainer>
        <StyledDocumentInfoContainer>
          <Text>Journalnummer:</Text>
          <Text>H-23017474</Text>
        </StyledDocumentInfoContainer>
        <StyledProjectIdentificationContainer>
          <Text>Projektidentifikation</Text>
          <Text>Projektnummer ved Videnskabsetisk Komité: H-23017474</Text>
          <Text>
            Projektet er registreret under Region Hovedstadens paraplyanmeldelse
            for sundhedsvidenskabelig
          </Text>
          <Text>forskning med lokalt journalnummer: P-2022-554</Text>
          <Text>Clinicaltrials.gov Number: NCT06060938</Text>
        </StyledProjectIdentificationContainer>
      </StyledDocumentInfoSection>
      <StyledProjectInfoSection>
        <Title level={4}>
          Information om deltagelse i forskningsprojektet PRSONAL
        </Title>
        <Text>
          Tak for din interesse i forskningsprojektet. Her kan du læse mere om,
          hvad det går ud på, og hvad det indebærer at deltage.
        </Text>
        <StyledInterestedButtonContainer>
          <Button type="primary" onClick={props.onInterested} size="large">
            Jeg er interesseret
          </Button>
        </StyledInterestedButtonContainer>
        <StyledProjectTextContainer>
          <Title level={5}>Vil du deltage i forskningsprojektet PRSONAL?</Title>
          <Text>
            Forskningsprojektet udføres i et samarbejde mellem Region
            Hovedstaden, Klinisk Biokemisk Afdeling på Herlev og Gentofte
            Hospital og Kræftens Bekæmpelse. Selve projektet foregår på
            mammografiklinikken på GentofteHospital.
          </Text>
        </StyledProjectTextContainer>
        <StyledProjectTextContainer>
          <Title level={5}>Informeret samtykke før deltagelse</Title>
          <Text>
            Før du beslutter, om du vil deltage, skal du fuldt ud forstå, hvad
            forskningsprojektet går ud på, og hvorfor vi har sat det i værk. Du
            bedes derfor venligst læse denne deltagerinformation grundigt.
            <br />
            <br /> På hjemmesiden{' '}
            <a href="http://prsonal.dk/">www.prsonal.dk</a> kan du læse mere,
            finde grafik og videomateriale og også finde telefonnummer på en
            projektmedarbejder, der kan besvare eventuelle spørgsmål. Hvis du
            ønsker at deltage, er det vigtigt at du deltager i en
            informationssamtale for at få mere at vide inden samtykke. Denne vil
            blive afholdt i forbindelse med den planlagte mammografi. Du er
            velkommen til at have et familiemedlem, en ven eller bekendt med til
            samtalen. Efter du har fået information om projektet har du ret til
            24 timers betænkningstid, inden du giver samtykke til at deltage. Du
            vælger selv om du vil gøre brug af de 24 timers betænkningstid.{' '}
            <br />
            <br /> Hvis du foretrækker at få information med folder og ved en
            informationssamtale inden samtykke, er der mulighed for det på
            mammografiklinikken på Gentofte Hospital samme dag, som du får din
            mammografi. Du er velkommen til at have et familiemedlem, en ven
            eller en bekendt med til samtalen. Efter samtalen har du ret til 24
            timers betænkningstid, inden du giver samtykke. Du vælger selv om du
            vil gøre brug af de 24 timers betænkningstid. Efter de 24 timer
            kommer du tilbage til mammografiklinikken, giver samtykke, hvorefter
            du er med i PRSONAL. <br />
            <br />
            Det er frivilligt at deltage i forskningsprojektet. Du kan til hver
            en tid og uden at angive en grund trække dit samtykke tilbage.
          </Text>
        </StyledProjectTextContainer>
        <StyledProjectTextContainer>
          <Title level={5}>Hvorfor forskningsprojektet PRSONAL?</Title>
          <Text>
            Formålet med forskningsprojektet er at tilrettelægge
            brystkræftscreening, så det passer til din personlige risiko for
            brystkræft. Det kan gøres, fordi vi kender risikofaktorerne for
            brystkræft. Ved at tage højde for den enkelte kvindes risiko for
            brystkræft kan vi sørge for, at kvinder med høj risiko kan få
            fordelene ved at blive screenet oftere. Samtidig bliver kvinder med
            lav risiko screenet sjældnere og derfor skånet for unødvendige
            røntgenundersøgelser.
            <br />
            <br /> Projektet sammenligner personlig brystkræftscreening med det
            screeningsprogram, der findes i dag. Vi vil bl.a. undersøge, hvordan
            kvinder forholder sig til at få målt deres personlige risiko for
            brystkræft, og om kvinder kan acceptere, at deres risiko bestemmer,
            hvor ofte de bliver inviteret til mammografiscreening.
          </Text>
        </StyledProjectTextContainer>
        <StyledProjectTextContainer>
          <Title level={5}>Sådan bliver det gjort</Title>
          <Text>
            Forskningsprojektet PRSONAL gennemføres i Region Hovedstaden og
            inkluderer ca. 2.000 kvinder i alderen 50-67 år. Halvdelen af
            kvinderne kommer i en kontrolgruppe og fortsætter i det nuværende
            program med mammografi hvert andet år. Den anden halvdel kommer i
            interventionsgruppen og får udregnet deres personlige risiko for at
            udvikle brystkræft. I projektperioden bliver de tilbudt screening
            oftere eller sjældnere afhængig af deres risiko. Lodtrækning afgør,
            hvilken gruppe man kommer i. Den samlede projektperiode varer til
            31. dec. 2034.
            <br />
            <br /> Den allerede planlagte mammografi bliver udført. Hvis du
            giver samtykke til at deltage i PRSONAL, vil vi derefter bede dig om
            at udfylde et spørgeskema om menstruationsforhold, brug af hormoner,
            børnefødsler, familiehistorie, livsstil og livskvalitet.
            Spørgeskemaet kan udfyldes hjemmefra, efter at du har givet samtykke
            til at deltage i projektet. På mammografiklinikken bliver du målt og
            vejet og til sidst trækkes der lod, om du fortsætter screening som
            tidligere (kontrolgruppen) eller får tilpasset tiden mellem
            mammografier i forhold til din risiko for brystkræft
            (interventionsgruppen).
            <br />
            <br /> Alle deltagere i projektet skal i løbet af projektperioden
            besvare yderligere 3 spørgeskemaer med 19 spørgsmål om livskvalitet,
            angst og bekymring forbrystkræft. Hvis du giver os lov, vil vi også
            følge dig i sundheds- og administrative registre indtil 31. dec.
            2034.
            <br />
            <br /> Hvis du kommer i interventionsgruppen, får du taget en
            blodprøve til analyse af 313 almindelige genetiske varianter i dit
            arvemateriale, der har betydning for din risiko for at udvikle
            brystkræft. Vi undersøger ikke for sjældne genmutationer som f.eks.
            mutationerne BRCA1 eller BRCA2.
            <br />
            <br /> Efter ca. tre måneder får du din beregnede risiko for at
            udvikle brystkræft, hvis du kommer i interventionsgruppen. Risikoen
            beskrives som lav, mellem, forhøjet eller høj og bestemmer, hvor
            ofte du tilbydes screening. Du vil få besked via sikker elektronisk
            post, som du f.eks. kender fra din bank.
          </Text>
        </StyledProjectTextContainer>
        <StyledRiskTextContainer>
          <div>
            <StyledColoredText strong>
              Kvinderne i den lave risikogruppe
            </StyledColoredText>
            <Text>
              {' '}
              bliver inviteret til screening hvert fjerde år. Det drejer sig om
              ca. 46 pct.
            </Text>
          </div>
          <div>
            <StyledColoredText strong>
              Kvinderne i mellem risikogruppen{' '}
            </StyledColoredText>
            <Text>
              {' '}
              bliver inviteret til screening hvert andet år, ligesom nu. Det
              drejer sig om ca. 44 pct.
            </Text>
          </div>
          <div>
            <StyledColoredText strong>
              {' '}
              Kvinderne i den forhøjede risikogruppe
            </StyledColoredText>
            <Text>
              {' '}
              bliver inviteret til screening hvert år. Det drejer sig om ca. 8
              pct.
            </Text>
          </div>
          <div>
            <StyledColoredText strong>
              Kvinderne i den høje risikogruppe
            </StyledColoredText>
            <Text>
              {' '}
              bliver inviteret til mammografi hvert år og vil få supplerende
              MR-scanning af brystet (MR-mammografi). Det drejer sig om ca. 2
              pct.
            </Text>
          </div>
        </StyledRiskTextContainer>
        <StyledProjectTextContainer>
          <Title level={5}>Hvem kan deltage?</Title>
          <Text>
            Kvinder i alderen 50-67, der får foretaget en screeningsundersøgelse
            for brystkræft og har underskrevet samtykkeerklæringen.
            <br />
            <br /> Du kan <Text strong>ikke</Text> deltage i forsøget, hvis du
            opfylder ét eller flere af nedenstående kriterier:
          </Text>
          <StyledListContainer>
            <li>
              <Text>
                Hvis du tidligere har haft brystkræft eller er i behandling for
                brystkræft nu
              </Text>
            </li>
            <li>
              <Text>
                Hvis du allerede ved, at du har høj risiko for brystkræft
              </Text>
            </li>
            <li>
              <Text>Hvis du har fået fjernet dit brystvæv</Text>
            </li>
            <li>
              <Text>
                Hvis vi på forhånd ved, at risikomodellen ikke kan bruges hos
                dig
              </Text>
            </li>
          </StyledListContainer>
        </StyledProjectTextContainer>
        <StyledProjectTextContainer>
          <Title level={5}>Hvad betyder mit samtykke?</Title>
          <Text>Dit samtykke indebærer, at du siger ja til, at du:</Text>
          <StyledListContainer>
            <li>
              <Text>
                udfylder spørgeskemaer, som indgår i risikoberegningen
              </Text>
            </li>
            <li>
              <Text>giver os lov til at bruge dit mammografibillede </Text>
            </li>
            <li>
              <Text>bliver målt og vejet </Text>
            </li>
            <li>
              <Text>deltager i lodtrækningen </Text>
            </li>
            <li>
              <Text>
                får taget blodprøve, der bliver brugt til genetisk måling, hvis
                du kommer i interventionsgruppen{' '}
              </Text>
            </li>
            <li>
              <Text>
                får din blodprøve opbevaret i en forskningsbiobank, hvis du
                kommer i interventionsgruppen{' '}
              </Text>
            </li>
            <li>
              <Text>
                modtager risikomålingen og et screeningsprogram, der passer
                dertil, hvis du kommer i interventionsgruppen{' '}
              </Text>
            </li>
            <li>
              <Text>
                udfylder spørgeskemaer om livskvalitet, angst og bekymring for
                brystkræft
              </Text>
            </li>
            <li>
              <Text>
                giver os lov til at følge dig i sundheds- og administrative
                registre indtil 31. dec. 2034
              </Text>
            </li>
            <li>
              <Text>
                giver os lov til at kontakte dig, hvis der skulle opstå
                yderligere spørgsmål
              </Text>
            </li>
          </StyledListContainer>
          <StyledProjectTextContainer style={{ paddingTop: '1.5rem' }}>
            <Text>
              Hvis du ved lodtrækning kommer i interventionsgruppen, vil vi
              yderligere bede dig om lov til at opbevare blodprøven i en biobank
              til fremtidig forskning efter projektets ophør, d. 31. dec. 2034.
              Du kan godt afslå dette og alligevel være med i PRSONAL.
            </Text>
          </StyledProjectTextContainer>
        </StyledProjectTextContainer>
        <StyledProjectTextContainer>
          <Title level={5}>Sådan beskytter vi dine data</Title>
          <Text>
            Resultater fra mammografien, analysen af 313 genetiske varianter i
            dit arvemateriale, vægt, højde og svar om menstruationsforhold, brug
            af hormoner, børnefødsler, familiehistorie og livsstil samt
            spørgeskemaer om livskvalitet, angst og bekymring for brystkræft
            samt registerinformationer opbevares med overholdelse af
            databeskyttelsesloven og databeskyttelsesforordningen (GDPR) indtil
            31. december 2034. Vi indsamler ikke informationer fra
            sygejournaler. Data og blodprøver forbliver i Danmark. Blodprøven
            bliver gemt i en biobank. Se mere om dette nedenfor.
          </Text>
        </StyledProjectTextContainer>
        <StyledProjectTextContainer>
          <Title level={5}>
            Risici, bivirkninger, komplikationer, ulemper og belastninger ved at
            deltage i forskningsprojektet
          </Title>
          <Text>
            Kvinder i interventionsgruppen får taget en blodprøve på 4 mL blod
            for at undersøge arvematerialet. Det kan medføre lidt forbigående
            ubehag i form af ømhed og smerte ved indstiksstedet og muligvis et
            blåt mærke i huden dagen efter.
            <br />
            <br /> De øvrige forventede bivirkninger, risici og ulemper er meget
            beskedne, da PRSONAL først og fremmest består af at indhente
            information fra spørgeskemaer, risikoberegning og
            risikokommunikation.
            <br />
            <br /> Alle kvinder modtager information om deres risiko
            elektronisk, hvilket kan medføre øget angst og bekymring hos nogle.
            Måske særlig kvinder med forhøjet eller høj risiko. Fra tidligere
            studier, ved vi, at bekymringen oftest fortager sig med tiden. Vi
            holder øje med sundhedsændringer vha. spørgeskemaerne og
            opfølgningen i nationale sundheds- og administrative registre. Har
            du brug for hjælp, kan du kontakte en medarbejder på PRSONAL.
            <br />
            <br />
            Kvinder med lav risiko kan tro, at de aldrig får brystkræft og
            udsætter et lægebesøg, hvis de opdager symptomer fra brystet. Ved at
            inddrage brugerne har vi forsøgt at udarbejde denne information om
            projektet, så den er så forståelig og præcis som muligt. Du kan
            altid ringe til en projektmedarbejder, der kan hjælpe med at svare
            på dine spørgsmål. Der kan altid være uforudsete risici og
            belastninger forbundet med videnskabelige forsøg, men forskerne har
            så vidt muligt forsøgt at teste forløbet på forhånd for at minimere
            risici.
          </Text>
        </StyledProjectTextContainer>
        <StyledProjectTextContainer>
          <Title level={5}>Nytte ved forsøget</Title>
          <Text>
            I PRSONAL forsøger vi at finde ud af, hvordan vi i fremtiden bedst
            kan tilrettelægge personlig brystkræftscreening så information om
            kvinders risiko for brystkræft bliver kommunikeret på den bedst
            mulige måde. Du vil formentlig ikke selv have direkte nytte af at
            deltage i forsøget.
          </Text>
        </StyledProjectTextContainer>
        <StyledProjectTextContainer>
          <Title level={5}>Udelukkelse fra og afbrydelse af forsøget</Title>
          <Text>
            Fortæl os, hvis du efter din deltagelse i PRSONAL på anden vis
            finder ud af, at du har forhøjet risiko for brystkræft. Så kan du
            ikke længere deltage i PRSONAL. Derudover afbrydes forsøget hvis der
            opleves uforudsigelige alvorlige bivirkninger.
          </Text>
        </StyledProjectTextContainer>
        <StyledProjectTextContainer>
          <Title level={5}>Biobank</Title>
          <Text>
            Kvinder i interventionsgruppen får taget en blodprøve på 4 mL efter
            lodtrækningen. Blodprøven fragtes til Klinisk biokemisk afdeling på
            Herlev Hospital, hvor der bliver isoleret arvemateriale fra alle 4
            mL. Arvematerialet anvendes indenfor 3 måneder til at kortlægge de
            313 almindeligt forekommende genetiske varianter, der har betydning
            for din risiko for at udvikle brystkræft. Resten af arvematerialet
            vil blive opbevaret i en forskningsbiobank indtil 31 dec. 2034. Hvis
            du giver lov, vil det overskydende arvemateriale derefter blive
            overflyttet til en biobank til fremtidig forskning, som Datatilsynet
            har godkendt og hvor de databeskyttelsesretlige regler gælder. Et
            eventuelt nyt forskningsprojekt vil forinden blive anmeldt til den
            videnskabsetiske komité. Det kræver som udgangspunkt et nyt samtykke
            fra dig at bruge din blodprøve. Komiteen kan dog tillade ny
            forskning uden indhentning af samtykke, hvis der ikke er risiko for
            eller belastning af dig ved den nye forskning.
          </Text>
        </StyledProjectTextContainer>
        <StyledProjectTextContainer>
          <Title level={5}>Du kan altid framelde dig igen</Title>
          <Text>
            Selvom du har sagt ja til at deltage i forskningsprojektet PRSONAL,
            kan du på et hvilket som helst tidspunkt framelde dig igen. Uanset
            hvilken risikogruppe du er kommet i. Framelder du dig
            forskningsprojektet, vil du blive inviteret til brystkræftscreening
            hvert andet år, ligesom før du kom med i projektet.
          </Text>
        </StyledProjectTextContainer>
        <StyledProjectTextContainer>
          <Title level={5}>Økonomiske forhold</Title>
          <Text>
            PRSONAL er igangsat af Stig E Bojesen, Klinisk Biokemisk Afdeling,
            Herlev Gentofte Hospital sammen Janne Bigaard, Afdeling for
            Forebyggelse & Oplysning, Kræftens Bekæmpelse og Ilse Vejborg,
            Cheflæge og screeningschef, Herlev Gentofte Hospital og
            Mammografiscreeningsprogrammet i Region Hovedstaden. De
            projektansvarlige har ingen økonomiske interesser i projektet.
            Projektet er gjort muligt via en bevilling på 6.3 mio kr. fra Novo
            Nordisk Fonden. Midlerne administreres af Herlev Hospital. De
            anvendes udelukkende til udgifter, der er direkte relateret til
            PRSONAL: Indkøb af viden til udvikling af et kommunikationsværktøj
            for personlig brystkræftscreening, glas mm. til blodprøver,
            reagenser og laboratorievarer til undersøgelsen af arvematerialet,
            data management, løn til PRSONALs projektansatte,
            spørgeskemalicenser, oversættelser, videnskabelige rejser og møder,
            udgifter til publicering og overhead samt andre løbende udgifter. De
            deltagende afdelinger støtter i form af ekspertise, infrastruktur,
            kontorfaciliteter og arbejdsløn for de ugentlige timer, som de
            deltagende forskere anvender til PRSONAL. Hverken Novo Nordisk
            Fonden eller de deltagende afdelinger har ret til at påvirke
            forsøgets udformning eller resultatbearbejdning og har heller ikke
            adgang til dine persondata. Ingen kvinder modtager vederlag for at
            deltage i projektet.
          </Text>
        </StyledProjectTextContainer>
        <StyledProjectTextContainer>
          <Title level={5}>Hvordan formidles forskningsresultaterne?</Title>
          <Text>
            Data og resultater vil blive præsenteret ved internationale møder og
            offentliggjort i internationale tidsskrifter. Den første del af
            forskningsprojektet forventes afsluttet i 2026 og offentliggjort
            senest i 2027, men vi vil følge de kvinder, der deltager, i
            sundheds- og administrative registre indtil 31. december 2034, hvis
            kvinderne giver samtykke til det. <br /> <br />
            Vi håber, at denne information har givet dig tilstrækkeligt indblik
            i, hvad det vil sige at deltage i forskningsprojektet. Vi håber
            også, at du føler dig godt rustet til at beslutte, om du vil deltage
            eller ej. Vi beder dig også om at læse pjecen ”Før du beslutter dig.
            Om at være forsøgsperson i sundhedsvidenskabelige forsøg”:
            <br />
            <a
              href="https://nationaltcenterforetik.dk/Media/637997088999665897/Fr_du_beslutter_digpdf.pdf"
              target="_blank"
              rel="noreferrer"
            >
              https://nationaltcenterforetik.dk/Media/637997088999665897/Fr_du_beslutter_digpdf.pdf
            </a>
          </Text>
        </StyledProjectTextContainer>
        <StyledInterestedButtonContainer>
          <Button type="primary" onClick={props.onInterested} size="large">
            Jeg er interesseret
          </Button>
        </StyledInterestedButtonContainer>
        <StyledProjectTextContainer>
          <Text>
            Hvis du vil vide mere om projektet, er du meget velkommen til at
            kontakte os.
          </Text>
          <br />
          <br />
          <Text>
            Stig Bojesen
            <br />
            (Forskningsprojektansvarlig)
            <br />
            Klinisk biokemisk afdeling
            <br />
            Herlev Hospital
            <br />
            Borgmester Ib Juuls Vej 1
            <br />
            2730 Herlev
            <br />
            Mail: Stig.Egil.Bojesen@regionh.dk
            <br />
            Tlf.: {phoneNumber}
          </Text>
          <br />
          <br />
          <Text>
            Ilse Vejborg
            <br />
            Cheflæge og screeningschef
            <br />
            Herlev Gentofte Hospital og
            <br />
            Mammografiscreeningsprogrammet
            <br />
            i Region Hovedstaden
            <br />
            Kildegårdsvej 28C, opgang 63 st.
            <br />
            Gentofte Hospital, 2820 Gentofte
            <br />
            Mail: Ilse.Vejborg@regionh.dk
            <br />
            Tlf.: 38 67 77 49
          </Text>
          <br />
          <br />
          <Text>
            Janne Bigaard
            <br />
            Afdeling for Forebyggelse & Oplysning
            <br />
            Kræftens Bekæmpelse
            <br />
            Strandboulevarden 49
            <br />
            2100 København Ø
            <br />
            Mail: bigaard@cancer.dk
            <br />
            Tlf.: 27 77 10 70
          </Text>
        </StyledProjectTextContainer>
      </StyledProjectInfoSection>
    </StyledReadMoreSection>
  );
}

const StyledInterestedButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;
`;

const StyledReadMoreSection = styled.div`
  display: grid;
  max-width: 40rem;
`;

const StyledDocumentInfoContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.4fr;
  max-width: 25rem;
`;

const StyledProjectIdentificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${Sizes.EXTRA_LARGE_GAP}rem;
`;

const StyledDocumentInfoSection = styled.div``;

const StyledProjectInfoSection = styled.div`
  display: grid;
  gap: 1rem;
`;

const StyledProjectTextContainer = styled.div``;

const StyledRiskTextContainer = styled.div`
  padding: 0px ${Sizes.LARGE_GAP}rem;
  display: grid;
`;

const StyledListContainer = styled.div`
  padding: ${Sizes.LARGE_GAP}rem ${Sizes.LARGE_GAP}rem 0px;
  display: grid;
  gap: ${Sizes.STANDARD_GAP}rem;
`;
const StyledColoredText = styled(Text)`
  color: var(--cyan-8);
`;
