import { User, prsonalApi } from '@prsonal/website/shared/data-access';
import { FillOutInfo } from './components/fill-out-info';
import { NoRole } from './components/no-role';
import { useAuthentication } from './user-context';
import { Loader, PrsonalWrapper } from '@prsonal/website/shared/components';

export const AuthGuard = ({ children }: { children: JSX.Element }) => {
  const authentication = useAuthentication();
  const [updateUserData] = prsonalApi.useUpdateOneUserMutation();
  const [createOneConsent] = prsonalApi.useCreateOneConsentMutation();

  function updateUser(updatedUser: User) {
    if (authentication.user?.id) {
      updateUserData({ id: authentication.user.id, user: updatedUser });
      createOneConsent({
        consent: {
          privateLifePolicy: true,
          privateLifePolicyDate: new Date().toISOString(),
          userId: authentication.user.id,
        },
      });
    }
  }

  if (authentication.isFetchingUser || authentication.isLoadingClaims)
    return <Loader />;

  if (!authentication.user) return <Loader />;

  if (!authentication.user.email || !authentication.user.phone)
    return (
      <PrsonalWrapper>
        <FillOutInfo user={authentication.user} onUserUpdate={updateUser} />
      </PrsonalWrapper>
    );

  if (!authentication.user.role)
    return (
      <PrsonalWrapper>
        <NoRole />
      </PrsonalWrapper>
    );

  return children;
};
