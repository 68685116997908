export type AlcoholIntake = {
  howOften?: string;
  amount?: number;
};
type Alcohol = {
  lightBeer?: AlcoholIntake;
  regularBeer?: AlcoholIntake;
  strongBeer?: AlcoholIntake;
  redWine?: AlcoholIntake;
  whiteWine?: AlcoholIntake;
  hardWine?: AlcoholIntake;
  liquor?: AlcoholIntake;
  alcopops?: AlcoholIntake;
};

const ethanolAmount: Record<keyof Alcohol, number> = {
  // Volume in ml * alcohol percentage * 0.8 (density of ethanol g/ml)
  lightBeer: 330 * 0.025 * 0.8,
  regularBeer: 330 * 0.046 * 0.8,
  // Assume 7% alcohol in the average strong beer
  strongBeer: 330 * 0.07 * 0.8,
  // Assume 12% alcohol in the average wine and 125ml per glass
  redWine: 125 * 0.12 * 0.8,
  // Assume 12% alcohol in the average wine and 125ml per glass
  whiteWine: 125 * 0.12 * 0.8,
  // Assume 17% alcohol in the average hard wine and 50ml per glass
  hardWine: 50 * 0.17 * 0.8,
  // Assume 40% alcohol in the average liquor and 30ml per glass
  liquor: 30 * 0.4 * 0.8,
  // Assume 4% alcohol in the average alcopops and 275ml per bottle
  alcopops: 275 * 0.04 * 0.8,
};

export function calculateAlcoholIntake(
  alcoholType: keyof Alcohol,
  alcoholIntake?: AlcoholIntake
) {
  const mapping = {
    Ugentligt: 7,
    Dagligt: 1,
    Månedligt: 365.25 / 12,
  };
  const frequency = alcoholIntake?.howOften
    ? alcoholIntake.howOften === 'Ugentligt'
      ? mapping.Ugentligt
      : alcoholIntake.howOften === 'Dagligt'
      ? mapping.Dagligt
      : mapping.Månedligt
    : undefined;

  const gramsOfEthanolPerUnit = ethanolAmount[alcoholType];
  return alcoholIntake && alcoholIntake.amount && frequency
    ? (alcoholIntake.amount / frequency) * gramsOfEthanolPerUnit
    : 0;
}

export function calculateTotalAlcoholIntake(alcohol?: Alcohol) {
  let alcoholIntake = 0;
  if (!alcohol) {
    return alcoholIntake;
  }
  alcoholIntake += calculateAlcoholIntake('alcopops', alcohol.alcopops);
  alcoholIntake += calculateAlcoholIntake('hardWine', alcohol.hardWine);
  alcoholIntake += calculateAlcoholIntake('lightBeer', alcohol.lightBeer);
  alcoholIntake += calculateAlcoholIntake('liquor', alcohol.liquor);
  alcoholIntake += calculateAlcoholIntake('redWine', alcohol.redWine);
  alcoholIntake += calculateAlcoholIntake('regularBeer', alcohol.regularBeer);
  alcoholIntake += calculateAlcoholIntake('strongBeer', alcohol.strongBeer);
  alcoholIntake += calculateAlcoholIntake('whiteWine', alcohol.whiteWine);

  return alcoholIntake;
}
