import { Button, InputNumber, Radio, Select } from 'antd';
import styled from 'styled-components';
import { RiskQuestionnaireProps } from '../../risk-questionnaire-page.interface';
import Question from '../../components/question/question';
import { PrsonalTable } from '@prsonal/website/shared/components';
import Column from 'antd/es/table/Column';
import { Breakpoints, Sizes } from '@prsonal/website/shared/utils';
import { useState } from 'react';
import { AlertContainer } from '../../components/alert-container/alert-container';
import {
  isChildrenAnswersFilledOut,
  showDataMissingMessage,
} from '../../utils/valid-check.utils';
import {
  FamilyMember,
  RiskQuestionnaire,
} from '@prsonal/website/shared/data-access';
import { createKidFamilyMembers } from './utils/create-kid-familymembers.utils';

const numberOfKids = Array.from({ length: 15 }, (v, i) => ({
  value: i + 1,
  label: i + 1,
}));

export function Children(props: RiskQuestionnaireProps) {
  const [kidsAnswers, setKidsAnswers] = useState<
    Pick<RiskQuestionnaire, 'kids' | 'hasKids' | 'familyMembers'>
  >({
    hasKids: props.answers?.hasKids,
    familyMembers: props.answers?.familyMembers,
  });

  return (
    <StyledChildrenContainer>
      {!isChildrenAnswersFilledOut(kidsAnswers) &&
      showDataMissingMessage(props) ? (
        <AlertContainer />
      ) : null}
      <Question title="Har du fået børn?">
        <Radio.Group
          disabled={props.answers.status !== 'Open'}
          value={kidsAnswers?.hasKids}
          onChange={(e) => {
            setKidsAnswers((v) => ({
              hasKids: e.target?.value,
              familyMembers: !e.target?.value
                ? v.familyMembers?.filter((f) => !f.isChildOfTarget)
                : v?.familyMembers,
            }));
          }}
        >
          <Radio value={true}>Ja</Radio>
          <Radio value={false}>Nej</Radio>
        </Radio.Group>
      </Question>
      {kidsAnswers?.hasKids ? (
        <ChildrenCountContainer>
          <Question title="Hvor mange børn?">
            <InputContainer>
              <Select
                disabled={props.answers.status !== 'Open'}
                style={{ minWidth: '8rem' }}
                placeholder="Vælg antal børn"
                options={numberOfKids}
                value={
                  numberOfKids.find(
                    (v) =>
                      v.value ===
                      kidsAnswers?.familyMembers?.filter(
                        (f) => f.isChildOfTarget
                      )?.length
                  )?.value
                }
                onChange={(e) => {
                  setKidsAnswers((v) => ({
                    ...v,
                    familyMembers: [
                      ...(v.familyMembers?.filter((f) => !f.isChildOfTarget) ??
                        []),
                      ...(createKidFamilyMembers(e) as FamilyMember[]),
                    ],
                  }));
                }}
              />
            </InputContainer>
          </Question>
          <PrsonalTable
            dataSource={kidsAnswers?.familyMembers?.filter(
              (f) => f.isChildOfTarget
            )}
          >
            <Column
              width={'50%'}
              title="Alder"
              render={(_, familyMember: FamilyMember) => (
                <InputNumber
                  disabled={props.answers.status !== 'Open'}
                  inputMode="numeric"
                  style={{ width: '100%' }}
                  placeholder="Alder"
                  min={0}
                  value={familyMember.lifeState?.age}
                  onChange={(e) => {
                    setKidsAnswers((v) => ({
                      ...v,
                      familyMembers: v?.familyMembers?.map((member) =>
                        member.familyMemberKey === familyMember.familyMemberKey
                          ? {
                              ...member,
                              lifeState: {
                                ...member.lifeState,
                                age: e ?? undefined,
                                alive: true,
                              },
                            }
                          : member
                      ),
                    }));
                  }}
                />
              )}
            />
            <Column
              title="Køn"
              dataIndex={'gender'}
              render={(gender, familyMember: FamilyMember, index) => (
                <Select
                  disabled={props.answers.status !== 'Open'}
                  style={{ width: '100%' }}
                  options={[
                    { value: 'Male', label: 'Dreng' },
                    { value: 'Female', label: 'Pige' },
                  ]}
                  value={gender}
                  placeholder="Køn"
                  onChange={(e) => {
                    setKidsAnswers((v) => ({
                      ...v,
                      familyMembers: v?.familyMembers?.map((member) =>
                        member.familyMemberKey === familyMember.familyMemberKey
                          ? {
                              ...member,
                              gender: e,
                              familyMemberKey:
                                member.familyMemberKey.replaceAll(
                                  // Looks for parentheses and everything in between
                                  /\([^)]*\)/g,
                                  e === 'Male' ? '(Søn)' : '(Datter)'
                                ),
                            }
                          : member
                      ),
                    }));
                  }}
                />
              )}
            />
          </PrsonalTable>
        </ChildrenCountContainer>
      ) : null}
      <ButtonContainer>
        <Button
          onClick={() => {
            props.onValueChange(kidsAnswers);
            props.onChangePage(-1);
          }}
        >
          Forrige
        </Button>
        <Button
          type="primary"
          onClick={() => {
            props.onValueChange(kidsAnswers);
            props.onChangePage(1);
          }}
        >
          Næste
        </Button>
      </ButtonContainer>{' '}
    </StyledChildrenContainer>
  );
}

export default Children;

const StyledChildrenContainer = styled.div`
  padding-top: ${Sizes.LARGE_GAP}rem;
  display: flex;
  flex-direction: column;
  gap: ${Sizes.LARGE_GAP}rem;
`;

const InputContainer = styled.div`
  width: 60%;
  min-width: 7.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${Sizes.STANDARD_GAP}rem;
  padding: ${Sizes.STANDARD_GAP}rem;
`;

const ChildrenCountContainer = styled.div`
  border: ${Sizes.SMALL_BORDER}rem solid var(--neutral-5);
  padding: ${Sizes.LARGE_GAP}rem 1rem;
  display: grid;
  gap: 1.875rem;
  @media screen and (min-width: ${Breakpoints.lg}px) {
    grid-template-columns: 0.5fr 2fr;
  }
`;
