import { Button, Radio, Select } from 'antd';
import styled from 'styled-components';
import { RiskQuestionnaireProps } from '../../risk-questionnaire-page.interface';
import Question from '../../components/question/question';
import { HormoneTreatedEnum } from '@prsonal/shared/utils/types';
import { Breakpoints, Sizes } from '@prsonal/website/shared/utils';
import { useState } from 'react';
import { AlertContainer } from '../../components/alert-container/alert-container';
import {
  isHormoneTreatmentAnswersFilledOut,
  showDataMissingMessage,
} from '../../utils/valid-check.utils';
import { RiskQuestionnaire } from '@prsonal/website/shared/data-access';

const numberOfYears = Array.from({ length: 21 }, (v, i) => ({
  value: i,
  label: i,
}));

export function HormoneTreatment(props: RiskQuestionnaireProps) {
  const [hormoneTreatmentAnswers, setHormoneTreatmentAnswers] = useState<
    Pick<RiskQuestionnaire, 'hormoneTreatment'>
  >({ hormoneTreatment: props.answers?.hormoneTreatment });

  return (
    <StyledHormoneTreatment>
      {!isHormoneTreatmentAnswersFilledOut(hormoneTreatmentAnswers) &&
      showDataMissingMessage(props) ? (
        <AlertContainer />
      ) : null}
      <Question title="Har du fået hormonbehandling i forbindelse med overgangsalderen?">
        <Radio.Group
          disabled={props.answers.status !== 'Open'}
          value={hormoneTreatmentAnswers?.hormoneTreatment?.treated}
          onChange={({ target: { value } }) => {
            setHormoneTreatmentAnswers((v) => ({
              ...v,
              hormoneTreatment: {
                ...v?.hormoneTreatment,
                years:
                  value === HormoneTreatedEnum.Nej ||
                  value === HormoneTreatedEnum.VedIkke
                    ? undefined
                    : v?.hormoneTreatment?.years,
                last5Years:
                  value === HormoneTreatedEnum.Ja ||
                  value === HormoneTreatedEnum.VedIkke
                    ? undefined
                    : v?.hormoneTreatment?.last5Years,
                treated: value,
              },
            }));
          }}
        >
          <Radio value={HormoneTreatedEnum.Ja}>Ja</Radio>
          <Radio value={HormoneTreatedEnum.Nej}>Nej</Radio>
          <Radio value={HormoneTreatedEnum.VedIkke}>Ved ikke</Radio>
        </Radio.Group>
      </Question>
      {hormoneTreatmentAnswers?.hormoneTreatment?.treated ===
      HormoneTreatedEnum.Ja ? (
        <HormoneTreatmentDetailContainer>
          <Question
            title="I hvor mange år har du fået hormonbehandling?"
            subTitle="Medregn ikke eventuelle pauser. (Skriv 0 hvis du har fået hormonbehandling ifm. overgangsalderen i under et år)"
            breaklineBeforeSubTitle
          >
            <StyledPrsonalInputNumberContainer>
              <Select
                disabled={props.answers.status !== 'Open'}
                style={{ minWidth: '8rem' }}
                placeholder="Vælg antal år"
                options={numberOfYears}
                value={
                  numberOfYears.find(
                    (v) =>
                      v.value ===
                      hormoneTreatmentAnswers?.hormoneTreatment?.years
                  )?.value
                }
                onChange={(e) => {
                  setHormoneTreatmentAnswers((v) => ({
                    ...v,
                    hormoneTreatment: {
                      ...v.hormoneTreatment,
                      years: e,
                    },
                  }));
                }}
              />
            </StyledPrsonalInputNumberContainer>
          </Question>
          <Question title="Har du fået hormonbehandling de seneste fem år?">
            <Radio.Group
              disabled={props.answers.status !== 'Open'}
              value={hormoneTreatmentAnswers?.hormoneTreatment?.last5Years}
              onChange={(e) =>
                setHormoneTreatmentAnswers((v) => ({
                  ...v,
                  hormoneTreatment: {
                    ...v?.hormoneTreatment,
                    last5Years: e.target?.value,
                  },
                }))
              }
            >
              <Radio value={true}>Ja</Radio>
              <Radio value={false}>Nej</Radio>
            </Radio.Group>
          </Question>
        </HormoneTreatmentDetailContainer>
      ) : null}
      <ButtonContainer>
        <Button
          onClick={() => {
            props.onValueChange(hormoneTreatmentAnswers);
            props.onChangePage(-1);
          }}
        >
          Forrige
        </Button>
        <Button
          type="primary"
          onClick={() => {
            props.onValueChange(hormoneTreatmentAnswers);
            props.onChangePage(1);
          }}
        >
          Næste
        </Button>
      </ButtonContainer>{' '}
    </StyledHormoneTreatment>
  );
}

export default HormoneTreatment;

const StyledHormoneTreatment = styled.div``;

const HormoneTreatmentDetailContainer = styled.div`
  border: ${Sizes.SMALL_BORDER}rem solid var(--neutral-5);
  display: flex;
  flex-direction: column;
  gap: ${Sizes.LARGE_GAP}rem;
  padding: ${Sizes.LARGE_GAP}rem 1rem;
  margin-top: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${Sizes.STANDARD_GAP}rem;
  padding: ${Sizes.STANDARD_GAP}rem;
`;

const StyledPrsonalInputNumberContainer = styled.div`
  @media screen and (min-width: ${Breakpoints.lg}px) {
    width: 10%;
  }
`;
