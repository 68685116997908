import styled from 'styled-components';
import logo from '../images/Vectorlogo.svg';
import {
  Layout,
  Space,
  Row,
  Typography,
  Col,
  Button,
  Grid,
  theme,
  GlobalToken,
} from 'antd';
import PrsonalWrapper from '../prsonal-wrapper/prsonal-wrapper';
import { Breakpoints, phoneNumber } from '@prsonal/website/shared/utils';

const { Header } = Layout;
const { Text } = Typography;
const { useBreakpoint } = Grid;
const { useToken } = theme;

export interface HeaderProps {
  onAuth: () => void;
  isAuth: boolean;
  onReadMore: () => void;
  onLogoClick: () => void;
  fixedHeader?: boolean;
}
export function PrsonalHeader(props: HeaderProps) {
  const screens = useBreakpoint();
  const { token } = useToken();

  return screens.lg ? (
    <StyledShadow token={token} $fixedHeader={props.fixedHeader}>
      <PrsonalWrapper verticalPadding={0} horizontalPadding={0}>
        <Row align={'middle'} justify={'space-evenly'}>
          <Col lg={props.isAuth ? 15 : 5} xl={props.isAuth ? 21 : 11}>
            <Space>
              <StyledImage src={logo} alt="logo" onClick={props.onLogoClick} />
              <StyledPrsonalText>PRSONAL</StyledPrsonalText>
            </Space>
          </Col>
          {!props.isAuth ? (
            <Col xl={6}>
              <Text>Har du spørgsmål, kontakt: </Text>
              <Text>{phoneNumber}</Text>
            </Col>
          ) : null}

          <Col xl={3}>
            <StyledLoginButton onClick={props.onAuth} size="large">
              {props.isAuth ? 'Log ud' : 'Deltager login'}
            </StyledLoginButton>
          </Col>
          {!props.isAuth ? (
            <Col xl={4}>
              <StyledParticipationButton
                type="primary"
                onClick={() => props.onReadMore()}
                size="large"
              >
                Læs mere om at deltage
              </StyledParticipationButton>
            </Col>
          ) : null}
        </Row>
      </PrsonalWrapper>
    </StyledShadow>
  ) : (
    <StyledShadow token={token}>
      <PrsonalWrapper verticalPadding={0} horizontalPadding={0}>
        {!props.isAuth ? (
          <StyledRow justify={'space-evenly'}>
            <StyledCol xs={4}>
              <StyledImage src={logo} alt="logo" onClick={props.onLogoClick} />
            </StyledCol>
            <StyledCol xs={17}>
              <Space size={'small'}>
                <StyledLoginButton onClick={props.onAuth} size="large">
                  Log ind
                </StyledLoginButton>

                <StyledParticipationButton
                  onClick={() => props.onReadMore()}
                  size="large"
                  type="primary"
                >
                  Læs mere om at deltage
                </StyledParticipationButton>
              </Space>
            </StyledCol>
          </StyledRow>
        ) : (
          <Row justify={'space-evenly'}>
            <Col xs={17}>
              <StyledImage src={logo} alt="logo" onClick={props.onLogoClick} />
              <StyledPrsonalText>PRSONAL</StyledPrsonalText>
            </Col>
            <StyledCol xs={7}>
              <StyledLoginButton onClick={props.onAuth} size="large">
                Log ud
              </StyledLoginButton>
            </StyledCol>
          </Row>
        )}
      </PrsonalWrapper>
    </StyledShadow>
  );
}

export default PrsonalHeader;

const StyledImage = styled.img`
  cursor: pointer;
`;

const StyledCol = styled(Col)`
  text-align: right;
`;

const StyledRow = styled(Row)`
  align-items: right;
`;

const StyledPrsonalText = styled(Text)`
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-weight: 700;
  line-height: 0.90419rem; /* 90.421% */
  padding-left: 0.68rem;
`;

const StyledParticipationButton = styled(Button)`
  border-radius: 0.125rem;
  border: 1px solid var(--primary-6);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  background: var(--primary-6);
  color: var(--character-primary-inverse, #fff);

  @media screen and (max-width: ${Breakpoints.lg}px) {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
`;

const StyledLoginButton = styled(Button)`
  border-radius: 0.125rem;
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-1);

  @media screen and (max-width: ${Breakpoints.lg}px) {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
`;

const StyledShadow = styled(Header)<{
  token: GlobalToken;
  $fixedHeader?: boolean;
}>`
  background-color: ${(props) => props.token.colorBgContainer};
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  ${(props) => (props.$fixedHeader ? 'position:sticky; top:0; z-index:1' : '')};
  @media screen and (max-width: ${Breakpoints.lg}px) {
    padding-left: 0.31rem;
    padding-right: 0.31rem;
  }
`;
