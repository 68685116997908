import styled from 'styled-components';
import { Typography } from 'antd';
import { PrsonalWrapper } from '@prsonal/website/shared/components';

const { Title, Text } = Typography;

export function NoRole() {
  return (
    <PrsonalWrapper>
      <StyledNoRoleWrapper>
        <Title level={2}>Tak for din interesse.</Title>
        <Text>
          Når du møder op til din mammografi, og siger ja til at deltage i
          PRSONAL, vil du blive bedt om at udfylde et spørgeskema. Du skal bl.a.
          svare på, om du har familiemedlemmer, der har eller har haft
          bryst-eller æggestokkræft. Her kan du på forhånd forberede dig ved at
          medbringe oplysninger om familiemedlemmernes årstal for diagnose,
          fødselsår og evt. dødsår. Vær opmærksom på, at dette kun gælder
          diagnoserne bryst- og æggestokkræft.
          <br />
          <br />
          På mammografiklinikken bliver du også målt og vejet, og til sidst
          trækkes der lod, om du fortsætter screening som tidligere
          (kontrolgruppen) eller får tilpasset tiden mellem mammografier i
          forhold til din risiko for brystkræft (interventionsgruppen). Hvis du
          ender i gruppen, der skal have målt sin risiko, får du også taget en
          blodprøve.
          <br />
          <br />
          Du skal medbringe en mobiltelefon, der kan koble på MitID.
          <br />
          <br />
          Vi glæder os til at se dig.
        </Text>
      </StyledNoRoleWrapper>
    </PrsonalWrapper>
  );
}

const StyledNoRoleWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
