import styled from 'styled-components';
import { PrsonalWrapper } from '@prsonal/website/shared/components';
import EQlogo from '../assets/EQ-5D-5L-logo.svg';
import EQFooter from '../components/eq-footer/eq-footer';
import { QualityWorryQuestionnaireProps } from '../quality-worry-questionnaire.interfaces';

export function SurveyFrontPage(props: QualityWorryQuestionnaireProps) {
  return (
    <PrsonalWrapper verticalPadding={0} horizontalPadding={0}>
      <StyledColumn>
        <StyledContent>
          <img src={EQlogo} alt="EQ-5D-5L logo" />
          <StyledParagraph>Helbredsspørgeskema</StyledParagraph>
          <StyledParagraph>Dansk version for Danmark</StyledParagraph>
          <StyledParagraph>
            <i>Danish version for Denmark</i>
          </StyledParagraph>
        </StyledContent>
        <EQFooter onChangePage={props.onChangePage} />
      </StyledColumn>
    </PrsonalWrapper>
  );
}

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: calc(100vh - 80px);
  width: 100%;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
  font-family: Arial;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;
  font-style: normal;
  gap: 1.25rem;
`;

const StyledParagraph = styled.p`
  margin: 0;
`;

export default SurveyFrontPage;
