import { useAuthentication } from '@prsonal/website/feature-auth';
import { PrsonalHeader } from '@prsonal/website/shared/components';
import {
  Outlet,
  useNavigate,
  useLocation,
  ScrollRestoration,
} from 'react-router-dom';
import styled from 'styled-components';

const stickyHeadersOnPaths = ['/', '/laes-mere'];

export function App() {
  const authentication = useAuthentication();
  const navigate = useNavigate();
  const location = useLocation();

  if (authentication.isAuthenticated && location.pathname === '/')
    navigate('/bruger');

  return (
    <>
      <StyledApp>
        <PrsonalHeader
          onAuth={
            authentication.isAuthenticated
              ? authentication.logout
              : authentication.login
          }
          isAuth={authentication.isAuthenticated}
          onReadMore={() => navigate('/laes-mere')}
          onLogoClick={() => navigate('/')}
          fixedHeader={stickyHeadersOnPaths.includes(location.pathname)}
        />
        <Outlet />
      </StyledApp>
      <ScrollRestoration />
    </>
  );
}

export default App;

const StyledApp = styled.div``;
