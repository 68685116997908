import styled from 'styled-components';
import { Select, Typography } from 'antd';
import { valueType } from 'antd/es/statistic/utils';
import { GenderEnum } from '@prsonal/shared/utils/types';
import { Sizes } from '@prsonal/website/shared/utils';
import { FamilyMember } from '@prsonal/website/shared/data-access';
import { difference } from 'lodash';
import { generateIndivID } from '../../../../utils/generate-indiv-ID.utils';

const { Text } = Typography;

export interface FamilyMemberCounterProps {
  familyMembers: FamilyMember[];
  onFamilyMembersChanged: (familyMembers: FamilyMember[]) => void;
  familyMemberKey: string;
  motherID?: string;
  fatherID?: string;
  gender: GenderEnum;
  title: string;
  disabled?: boolean;
}

const numberOfFamilyMembers = Array.from({ length: 16 }, (v, i) => ({
  value: i,
  label: i,
}));

/**
 * Returns a counter that creates family members with the given familyMemberId so it can be added to the list of family members
 *
 * @param {FamilyMemberCounterProps} props
 * @param {FamilyMember[]} props.familyMembers
 * @param {Function} props.setFamilyMember - Used for setting the list of familyMembers that is provided as props.
 * @param {string} props.familyMemberKey - Used for setting the type of familyMember eg. "Søster" and key of the list entry.
 * @param {GenderEnum} props.gender
 * @param {string} props.title - Title used for setting the addonBefore prop of the input component. Should be plural form of familyMemberId
 * @returns {JSX.Element}
 */
export function FamilyMemberCounter(props: FamilyMemberCounterProps) {
  const familyMemberCount = props.familyMembers.filter((familyMember) =>
    familyMember.familyMemberKey.includes(props.familyMemberKey)
  ).length;

  // Adds as many familyMembers as the difference between input value and saved state;
  const addMember = (count: number) => {
    const newFamilyMembers = [];
    for (let i = familyMemberCount + 1; i <= count; i++) {
      const newFamilyMemberId = `${props.familyMemberKey} ${i}`;
      newFamilyMembers.push({
        cancerDiagnoses: undefined,
        familyMemberKey: newFamilyMemberId,
        hadCancer: undefined,
        lifeState: undefined,
        indivID: generateIndivID(),
        motherID: props.motherID,
        fatherID: props.fatherID,
        gender: props.gender,
      });
    }
    props.onFamilyMembersChanged([...props.familyMembers, ...newFamilyMembers]);
  };

  // Removes as many familyMembers as the difference between saved state and input value;
  const removeMember = (count: number) => {
    const familyMemberToBeRemoved = props.familyMembers.filter((familyMember) =>
      familyMember.familyMemberKey.includes(props.familyMemberKey)
    );
    props.onFamilyMembersChanged([
      ...difference(props.familyMembers, familyMemberToBeRemoved.slice(count)),
    ]);
  };

  const handleChange = (value: valueType) => {
    const count = +value;
    if (count > familyMemberCount) {
      addMember(count);
    } else if (count < familyMemberCount) {
      removeMember(count);
    }
  };

  return (
    <StyledFamilyMemberCounter>
      <StyledFamilyMemberIdContainer>
        <Text>{props.title}</Text>
      </StyledFamilyMemberIdContainer>
      <Select
        disabled={props.disabled}
        style={{ minWidth: '8rem' }}
        placeholder={`Vælg antal ${props.title}`}
        options={numberOfFamilyMembers}
        value={
          numberOfFamilyMembers.find((v) => v.value === familyMemberCount)
            ?.value
        }
        onChange={(e) => {
          handleChange(e);
        }}
      />
    </StyledFamilyMemberCounter>
  );
}

export default FamilyMemberCounter;

const StyledFamilyMemberIdContainer = styled.div``;

const StyledFamilyMemberCounter = styled.div`
  display: grid;
  grid-template-columns: 1fr 8rem;
  width: 16rem;
  padding: 0.5rem;
  align-items: center;
  margin: 0px 0.313rem ${Sizes.STANDARD_GAP}rem;
  border: ${Sizes.SMALL_BORDER}rem solid var(--neutral-6);
`;
