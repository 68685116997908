import { User, prsonalApi } from '@prsonal/website/shared/data-access';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export function useConsent(
  user: User
): [
  (signature?: string) => void,
  (signature?: string) => void,
  (wantInfo: boolean) => void,
  (wantInfo: boolean) => void
] {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [updateOneConsent] = prsonalApi.useUpdateOneConsentMutation();
  const [updateOneUser] = prsonalApi.useUpdateOneUserMutation();

  function giveInformedPRSONALConsent(signature?: string) {
    if (user?.id && user?.consent?.id) {
      updateOneUser({
        id: user.id,
        user: {
          role: 'Participant',
        } as User,
      });
      updateOneConsent({
        id: user?.consent?.id,
        consent: {
          informedConsentDate: new Date().toISOString(),
          informedConsent: true,
          medStudInformedSignature: signature,
        },
      });
    }
  }

  function giveInformedBloodSampleConsent(signature?: string) {
    if (user?.consent?.id) {
      updateOneConsent({
        id: user.consent.id,
        consent: {
          medStudBloodSampleSignature: signature,
        },
      });
      navigate(`/padmin/${user.id}`);
    }
  }

  function givePRSONALConsent(wantInfo: boolean) {
    if (user?.consent?.id) {
      updateOneConsent({
        id: user.consent.id,
        consent: {
          resultAndConsequences: wantInfo,
          prsonalConsent: true,
          prsonalConsentDate: new Date().toISOString(),
        },
      })
        .then(() => dispatch(prsonalApi.util.invalidateTags(['user'])))
        .catch(() => dispatch(prsonalApi.util.invalidateTags(['user'])));
    }
  }

  function giveBloodSampleConsent(wantInfo: boolean) {
    if (user?.consent?.id) {
      updateOneConsent({
        id: user.consent.id,
        consent: {
          information: wantInfo,
          bloodSampleConsent: true,
          bloodSampleConsentConsentDate: new Date().toISOString(),
        },
      });
    }
    navigate(`/bruger`);
  }

  return [
    giveInformedPRSONALConsent,
    giveInformedBloodSampleConsent,
    givePRSONALConsent,
    giveBloodSampleConsent,
  ];
}
