import { useNavigate } from 'react-router-dom';
import ContentLinkSection from './sections/content-link-section/content-link-section';
import ParticipationDescription from './sections/participant-description/participation-description';
import ProjectBanner from './sections/project-banner/project-banner';
import FeatureProsAndCons from './sections/pros-and-cons/feature-pros-and-cons';
import PrsonalFooter from './sections/prsonal-footer/prsonal-footer';
import ResearchProjectTimeline from './sections/research-project-timeline/research-project-timeline';
import RiskGroupSection from './sections/risk-group-section/risk-group-section';
import ScienceBehind from './sections/science-behind/science-behind';
import StudyPurpose from './sections/study-purpose/study-purpose';
import Unsubscribe from './sections/unsubscribe/unsubscribe';

export function LandingPage() {
  const navigate = useNavigate();
  return (
    <>
      <ProjectBanner onReadMore={() => navigate('/laes-mere')} />
      <ContentLinkSection />
      <StudyPurpose />
      <ParticipationDescription />
      <ResearchProjectTimeline />
      <RiskGroupSection />
      <FeatureProsAndCons />
      <ScienceBehind />
      <Unsubscribe />
      <PrsonalFooter />
    </>
  );
}

export default LandingPage;
