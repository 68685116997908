import { QuestionnaireStatusEnum } from '@prsonal/website/shared/data-access';

export interface Questionnaire {
  id: string;
  name: string;
  status: QuestionnaireStatusEnum;
  /**
   * An admin has special access to the questionnaires after they are filled out. They have read only access.
   */
  isAdmin?: boolean;
  openDate?: string;
  closedDate?: string;
  showEditText?: boolean;
  onFillOut: () => void;
}

export type RiskLevel =
  | 'low-risk'
  | 'medium-risk'
  | 'increased-risk'
  | 'high-risk';

export type RiskLevelDa = 'Lav' | 'Mellem' | 'Forhøjet' | 'Høj';
/**
 * Navigation state for padmin page.
 */
export class PadminPageState {
  userIds?: string[];

  search?: string;
}
