import { LifeQualityWorryQuestionnaire } from '@prsonal/website/shared/data-access';
import SurveyQuestion from '../../components/survey-question/survey-question';
import { QualityWorryQuestionnaireProps } from '../../quality-worry-questionnaire.interfaces';

const options = [
  {
    text: 'Jeg har ingen problemer med at udføre mine sædvanlige aktiviteter',
    value: 'NoProblemActivities',
  },
  {
    text: 'Jeg har lidt problemer med at udføre mine sædvanlige aktiviteter',
    value: 'LittleProblemActivities',
  },
  {
    text: 'Jeg har moderate problemer med at udføre mine sædvanlige aktiviteter',
    value: 'ModerateProblemActivities',
  },
  {
    text: 'Jeg har store problemer med at udføre mine sædvanlige aktiviteter',
    value: 'BigProblemActivities',
  },
  {
    text: 'Jeg kan ikke udføre mine sædvanlige aktiviteter',
    value: 'CantActivities',
  },
];

const title = 'Sædvanlige aktiviteter';
const subtitle =
  '(fx. arbejde, studie, husarbejde, familie- eller fritidsaktiviteter)';

export function EqUsualActivities(props: QualityWorryQuestionnaireProps) {
  return (
    <SurveyQuestion
      title={title}
      subTitle={subtitle}
      options={options}
      onChangePage={props.onChangePage}
      onValueChange={(value: string) =>
        props.onValueChange({
          activity: value as LifeQualityWorryQuestionnaire['activity'],
        })
      }
      currentChecked={props.answers?.activity}
    />
  );
}

export default EqUsualActivities;
