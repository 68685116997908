import styled from 'styled-components';
import ConsentTemplate, { ConsentTemplateProps } from './consent-template';
import { Checkbox, CheckboxOptionType } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { Sizes } from '@prsonal/website/shared/utils';

export type MedStudConsentCheckboxes = {
  onCheckboxClicked: (canNavigate: boolean) => void;
} & Omit<ConsentTemplateProps, 'canSign' | 'consentDisplay'>;

export function MedStudConsentCheckboxes(props: MedStudConsentCheckboxes) {
  return (
    <StyledFirstConsent>
      <ConsentTemplate
        canSign={true}
        title={props.title}
        onConsentGiven={props.onConsentGiven}
        projectIdentification={props.projectIdentification}
        canNavigate={props.canNavigate}
        onCancel={props.onCancel}
        onNavigate={props.onNavigate}
        hideSignButton={props.hideSignButton}
        consentDisplay={
          props.canNavigate ? (
            <CheckBoxes canNavigate={props.onCheckboxClicked} />
          ) : null
        }
      />
    </StyledFirstConsent>
  );
}

interface CheckBoxesProps {
  canNavigate: (canNavigate: boolean) => void;
}

function CheckBoxes({ canNavigate }: CheckBoxesProps) {
  function onChange(checkValues: CheckboxValueType[]) {
    if (checkValues.length === 5) {
      canNavigate(true);
      return;
    }
    canNavigate(false);
    return;
  }

  const options: CheckboxOptionType[] = [
    { label: 'Kvinden er mellem 50 og 67 år', value: 1 },
    {
      label:
        'Kvinden har lige fået foretaget en helt almindelig screeningsmammografi, dvs. ikke pga. mistanke om en knude i brystet eller andet',
      value: 2,
    },
    {
      label:
        'Kvinden har ikke tidligere fået at vide, at hun har forhøjet risiko for brystkræft',
      value: 3,
    },
    { label: 'Kvinden har ikke haft brystkræft tidligere', value: 4 },
    {
      label:
        'Kvindens udseende svarer ikke til en kvinde med oprindelse syd for Sahara',
      value: 5,
    },
  ];
  return <StyledCheckboxContainer options={options} onChange={onChange} />;
}

export default MedStudConsentCheckboxes;

const StyledCheckboxContainer = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: ${Sizes.EXTRA_LARGE_GAP}rem;
`;

const StyledFirstConsent = styled.div``;
