import { Button, Radio } from 'antd';
import styled from 'styled-components';
import { RiskQuestionnaireProps } from '../../risk-questionnaire-page.interface';
import Question from '../../components/question/question';
import { Sizes } from '@prsonal/website/shared/utils';
import { useState } from 'react';
import { AlertContainer } from '../../components/alert-container/alert-container';
import {
  isBirthControlAnswersFilledOut,
  showDataMissingMessage,
} from '../../utils/valid-check.utils';
import { RiskQuestionnaire } from '@prsonal/website/shared/data-access';

export function BirthControl(props: RiskQuestionnaireProps) {
  const [birthControlAnswers, setBirthControlAnswers] = useState<
    Pick<RiskQuestionnaire, 'birthControl'>
  >({ birthControl: props.answers?.birthControl });

  return (
    <StyledBirthControl>
      {!isBirthControlAnswersFilledOut(birthControlAnswers) &&
      showDataMissingMessage(props) ? (
        <AlertContainer />
      ) : null}
      <Question title="Har du nogensinde taget p-piller?">
        <Radio.Group
          disabled={props.answers.status !== 'Open'}
          value={birthControlAnswers?.birthControl?.everTakenBirthControl}
          onChange={(e) => {
            setBirthControlAnswers((v) => ({
              ...v,
              birthControl: {
                ...v?.birthControl,
                years: !e.target.value ? undefined : v?.birthControl?.years,
                last2Years: !e.target.value
                  ? undefined
                  : v?.birthControl?.last2Years,
                everTakenBirthControl: e.target?.value,
              },
            }));
          }}
        >
          <Radio value={true}>Ja</Radio>
          <Radio value={false}>Nej</Radio>
        </Radio.Group>
      </Question>
      {birthControlAnswers?.birthControl?.everTakenBirthControl ? (
        <StyledBirthControlDetailContainer>
          <Question title="I hvor mange år samlet set?">
            <StyledRadioGroup
              disabled={props.answers.status !== 'Open'}
              value={birthControlAnswers?.birthControl?.years}
              onChange={(e) =>
                setBirthControlAnswers((v) => ({
                  ...v,
                  birthControl: {
                    ...v?.birthControl,
                    years: e.target?.value,
                  },
                }))
              }
            >
              {/* TODO: Should be made to a type */}
              <Radio value={'Under 1 år'}>Under 1 år</Radio>
              <Radio value={'1-4 år'}>1-4 år</Radio>
              <Radio value={'5-9 år'}>5-9 år</Radio>
              <Radio value={'10-14 år'}>10-14 år</Radio>
              <Radio value={'Over 14 år'}>Over 14 år</Radio>
            </StyledRadioGroup>
          </Question>
          <Question title="Har du taget p-piller de sidste 2 år?">
            <Radio.Group
              disabled={props.answers.status !== 'Open'}
              value={birthControlAnswers?.birthControl?.last2Years}
              onChange={(e) =>
                setBirthControlAnswers((v) => ({
                  ...v,
                  birthControl: {
                    ...v?.birthControl,
                    last2Years: e.target?.value,
                  },
                }))
              }
            >
              <Radio value={true}>Ja</Radio>
              <Radio value={false}>Nej</Radio>
            </Radio.Group>
          </Question>
        </StyledBirthControlDetailContainer>
      ) : null}
      <StyledButtonContainer>
        <Button
          onClick={() => {
            props.onValueChange(birthControlAnswers);
            props.onChangePage(-1);
          }}
        >
          Forrige
        </Button>
        <Button
          type="primary"
          onClick={() => {
            props.onValueChange(birthControlAnswers);
            props.onChangePage(1);
          }}
        >
          Næste
        </Button>
      </StyledButtonContainer>{' '}
    </StyledBirthControl>
  );
}

export default BirthControl;

const StyledBirthControl = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes.LARGE_GAP}rem;
`;

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  gap: ${Sizes.STANDARD_GAP}rem;
`;

const StyledBirthControlDetailContainer = styled.div`
  border: ${Sizes.SMALL_BORDER}rem solid var(--neutral-5, #d9d9d9);
  padding: ${Sizes.LARGE_GAP}rem 1rem;
  display: flex;
  flex-direction: column;
  gap: ${Sizes.LARGE_GAP}rem;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${Sizes.STANDARD_GAP}rem;
  padding: ${Sizes.STANDARD_GAP}rem;
`;
