import { Buffer } from 'buffer';

type DownloadType = 'text/csv';

function createDownloadFileUrl(data: string, type: DownloadType) {
  // Make sure data is base64 encoded
  const b64Encoded = Buffer.from(data).toString('base64');
  return URL.createObjectURL(
    new Blob([Buffer.from(b64Encoded, 'base64')], { type })
  );
}

export function downloadFile(
  fileName: string,
  data: string,
  type: DownloadType
) {
  const url = createDownloadFileUrl(data, type);
  const anchorTag = document.createElement('a');
  anchorTag.href = url;
  anchorTag.setAttribute('download', fileName);
  document.body.appendChild(anchorTag);
  anchorTag.click();
  document.body.removeChild(anchorTag);
  URL.revokeObjectURL(url);
}
