import {
  GroupEnum,
  QuestionnaireStatusEnum,
} from '@prsonal/shared/utils/types';
import { User, prsonalApi } from '@prsonal/website/shared/data-access';
import {
  Questionnaire,
  getQoLData,
  getQuestionnaireStatus,
} from '@prsonal/website/shared/utils';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { CollapseProps, Typography } from 'antd';
import { addDays } from 'date-fns';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;

const interventionQuestionKeys = [3, 4, 6, 7, 8];

export function useGetParticipantPageProps(
  user?: User
): [CollapseProps['items'], Questionnaire[], boolean] {
  const navigate = useNavigate();
  const { data: consent, isLoading: fetchingConsent } =
    prsonalApi.useGetOneConsentQuery(
      user?.consent?.id ? { id: user.consent.id } : skipToken
    );

  const qol1Data = getQoLData(user?.lifeQualityWorryQuestionnaires, 1);
  const qol2Data = getQoLData(user?.lifeQualityWorryQuestionnaires, 2);
  const qol3Data = getQoLData(user?.lifeQualityWorryQuestionnaires, 3);
  const qol4Data = getQoLData(user?.lifeQualityWorryQuestionnaires, 4);

  const questions = [
    {
      key: 1,
      label: 'Vil resultatet af min ”test” komme med i journalen hos min læge?',
      children: (
        <Text>
          Nej, når man er del af et forskningsprojekt, bliver ens oplysninger
          ikke automatisk delt med ens praktiserende læge.
        </Text>
      ),
    },
    {
      key: 2,
      label: 'Ved min læge, at jeg deltager i projektet?',
      children: (
        <Text>
          Nej, for det er screeningssekretariatet, der inviterer dig med i
          projektet. Din læge må gerne kontakte os eller kigge på vores
          hjemmeside, hvis vedkommende har spørgsmål om projektet.
        </Text>
      ),
    },
    {
      key: 3,
      label: 'Hvad med min forsikring?',
      children: (
        <Text>
          Du er ikke forpligtet til at meddele resultatet af din risikoberegning
          til dit forsikringsselskab, heller ikke til din sundhedsforsikring.
        </Text>
      ),
    },
    {
      key: 4,
      label: 'Hvad menes der med "brystvævest tæthed"?',
      children: (
        <Text>
          Tæthed er et mål for hvor meget kirtelvæv, der er i dit bryst,
          sammenlignet med fedtvæv. Det vurderes ud fra mammografibilledet.
          Nogle har mere tæt brystvæv end andre, og for langt de fleste
          erstattes kirtelvævet af fedtvæv efter overgangsalderen. Tætheden kan
          skygge for en knude i brystet og har derfor betydning for ens risiko
          for at udvikle brystkræft.
        </Text>
      ),
    },
    {
      key: 5,
      label:
        'Hvad hvis jeg selv finder en knude eller anden forandring i brystet?',
      children: (
        <Text>
          Kontakt din læge, hvis du opdager en knude eller en anden forandring i
          eller omkring brystet. Din læge vil undersøge dig og afgøre, om du
          skal videre til yderligere undersøgelser. Du skal altså ikke vente med
          at gå til lægen, fordi du er med i forskningsprojektet. Heller ikke
          selvom vi har målt en lav risiko hos dig.
        </Text>
      ),
    },
    {
      key: 6,
      label: 'Hvordan og hvornår får jeg nyt fra PRSONAL',
      children: (
        <Text>
          Du får besked, når du skal besvare spørgeskemaer. Hvis du har ønsket
          det, vil du undervejs få nyt fra projektet.
        </Text>
      ),
    },
    {
      key: 7,
      label: 'Opbevares mine data sikkert nok?',
      children: (
        <Text>
          Vi ved, at sikker opbevaring af data er vigtig for deltagerne i
          PRSONAL. Resultater fra mammografien, analysen af de genetiske
          varianter i dit arvemateriale, vægt, højde og svar om
          menstruationsforhold, brug af hormoner, børnefødsler, familiehistorik
          og livsstil samt spørgeskemaer om livskvalitet, angst og bekymring for
          brystkræft opbevares med overholdelse af databeskyttelsesloven og
          databeskyttelsesforordningen (GDPR). Blodprøven bliver gemt i en
          forskningsbiobank og kan kun bruges til andre undersøgelser efter
          fornyet godkendelse fra en Videnskabsetisk komité og eventuelt et nyt
          samtykke fra dig.
        </Text>
      ),
    },
    {
      key: 8,
      label:
        'Hvad sker der, hvis min familiehistorik/personlige historik ændrer sig?',
      children: (
        <Text>
          Hvis din risiko skulle ændre sig de næste ti år som følge af ændringer
          i din livsstil/dit helbred eller ændringer idin familiehistorik, så
          skal du tale med din egen læge. Vi vil bede dig fortælle os, hvis du
          får målt din risiko for brystkræft hos andre end os.
        </Text>
      ),
    },
    {
      key: 9,
      label: 'Hvad sker der, når projektet slutter?',
      children: (
        <Text>
          Så vil vi gøre resultaterne op, bl.a. i artikler og ved at fortælle om
          dem til andre forskere og sundhedsvæsenet. Hvis du ønsker det, vil vi
          sende dig en kort sammenfatning af de vigtigste resultater.
        </Text>
      ),
    },
  ];

  const questionnaires: Questionnaire[] = [
    {
      id: '1',
      name: 'Samtykke til deltagelse i PRSONAL',
      status: consent?.prsonalConsent
        ? QuestionnaireStatusEnum.FilledOut
        : QuestionnaireStatusEnum.Open,
      openDate: new Date().toISOString(),
      onFillOut: () => navigate('/samtykke/prsonal-samtykke', { state: user }),
    },
    {
      id: '2',
      name: 'Risikoskema',
      status: !consent?.prsonalConsent
        ? QuestionnaireStatusEnum.Closed
        : (user?.riskQuestionnaire?.status as QuestionnaireStatusEnum),
      openDate: user?.inclusionDate,
      showEditText:
        new Date(user?.riskQuestionnaire?.updated ?? '') >
        new Date(user?.riskQuestionnaire?.created ?? ''),
      onFillOut: () => navigate(`/risikoskema/${user?.id}`),
    },
    {
      id: '3',
      name: 'Livskvalitet og bekymring (dag 1)',
      status: consent?.prsonalConsent
        ? getQuestionnaireStatus(qol1Data.openDate, qol1Data.status)
        : QuestionnaireStatusEnum.Closed,
      openDate: user?.inclusionDate,
      onFillOut: () => navigate(`/bekymringsskema/${user?.id}`, { state: 1 }),
    },
    {
      id: '5',
      name: 'Livskvalitet og bekymring (dag 180)',
      status: getQuestionnaireStatus(qol2Data.openDate, qol2Data.status),
      openDate: user?.inclusionDate
        ? addDays(new Date(user.inclusionDate), 180).toISOString()
        : user?.inclusionDate,
      onFillOut: () => navigate(`/bekymringsskema/${user?.id}`, { state: 2 }),
    },
    {
      id: '6',
      name: 'Livskvalitet og bekymring (dag 365)',
      status: getQuestionnaireStatus(qol3Data.openDate, qol3Data.status),
      openDate: user?.inclusionDate
        ? addDays(new Date(user.inclusionDate), 365).toISOString()
        : user?.inclusionDate,
      onFillOut: () => navigate(`/bekymringsskema/${user?.id}`, { state: 3 }),
    },
    {
      id: '7',
      name: 'Livskvalitet og bekymring (dag 800)',
      status: getQuestionnaireStatus(qol4Data.openDate, qol4Data.status),
      openDate: user?.inclusionDate
        ? addDays(new Date(user.inclusionDate), 800).toISOString()
        : user?.inclusionDate,
      onFillOut: () => navigate(`/bekymringsskema/${user?.id}`, { state: 4 }),
    },
  ];

  if (user?.group !== GroupEnum.InterventionGroup) {
    return [
      questions.filter(
        (question) => !interventionQuestionKeys.includes(question.key)
      ),
      questionnaires,
      fetchingConsent,
    ];
  }

  return [questions, questionnaires, fetchingConsent];
}
