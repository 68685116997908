import {
  LifeQualityWorryQuestionnaire,
  QuestionnaireStatusEnum,
} from '@prsonal/website/shared/data-access';
import { isPast } from 'date-fns';

export function getQuestionnaireStatus(
  openDate: string | undefined,
  status: QuestionnaireStatusEnum | undefined
): QuestionnaireStatusEnum {
  if (!openDate || !status) return 'Closed';

  // Make sure that we open a questionnaire if we are past the open data and the status is still not open.
  if (isPast(new Date(openDate)) && status === 'Closed') return 'Open';
  return status;
}

export function getQoLData(
  QoLs: LifeQualityWorryQuestionnaire[] | undefined,
  index: number
) {
  const QoL = QoLs?.find((q) => q.index === index);
  return { openDate: QoL?.openDate, status: QoL?.status };
}
