import { useAuthentication } from '@prsonal/website/feature-auth';
import { PadminList } from '@prsonal/website/feature-padmin-list';
import { ParticipantPage } from '@prsonal/website/feature-participant-page';
import styled from 'styled-components';

export function UserPage() {
  const user = useAuthentication().user;
  return (
    <StyledUserPage>
      {user?.role === 'Participant' ? (
        <ParticipantPage />
      ) : user?.role === 'MedicalStudent' || user?.role === 'Admin' ? (
        <PadminList />
      ) : null}
    </StyledUserPage>
  );
}

export default UserPage;

const StyledUserPage = styled.div``;
