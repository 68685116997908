import { Breakpoints, Sizes } from '@prsonal/website/shared/utils';
import { ReactNode } from 'react';
import { Grid } from 'antd';
import styled from 'styled-components';
const { useBreakpoint } = Grid;
export interface PrsonalWrapperProps {
  children: ReactNode;
  verticalPadding?: number;
  horizontalPadding?: number;
  backgroundColor?: string;
  fullHeightBackground?: boolean;
  padminView?: boolean;
}
const defaultsMobile: Pick<
  PrsonalWrapperProps,
  'verticalPadding' | 'horizontalPadding'
> = {
  verticalPadding: Sizes.PRSONAL_VERTICAL_PADDING_MOBILE,
  horizontalPadding: Sizes.PRSONAL_HORIZONTAL_PADDING_MOBILE,
};
const defaultsDesktop: Pick<
  PrsonalWrapperProps,
  'verticalPadding' | 'horizontalPadding'
> = {
  verticalPadding: Sizes.PRSONAL_VERTICAL_PADDING_DESKTOP,
  horizontalPadding: Sizes.PRSONAL_HORIZONTAL_PADDING_DESKTOP,
};

export function PrsonalWrapper(props: PrsonalWrapperProps) {
  const screens = useBreakpoint();

  const values: PrsonalWrapperProps = {
    ...(screens.lg ? defaultsDesktop : defaultsMobile),
    ...props,
  };
  return (
    <StyledBackgroundWrapper
      backgroundColor={values.backgroundColor}
      fullHeightBackground={props.fullHeightBackground}
    >
      <StyledPrsonalWrapper
        verticalPadding={values.verticalPadding}
        horizontalPadding={values.horizontalPadding}
        desktopView={screens.lg}
        padminView={props.padminView}
      >
        {values.children}
      </StyledPrsonalWrapper>
    </StyledBackgroundWrapper>
  );
}
export default PrsonalWrapper;
const StyledPrsonalWrapper = styled.div<{
  verticalPadding?: number;
  horizontalPadding?: number;
  desktopView?: boolean;
  padminView?: boolean;
}>`
  @media screen and (min-width: ${Breakpoints.lg}px) {
    max-width: ${(props) =>
      props.padminView ? Sizes.PADMIN_WIDTH : Sizes.PRSONAL_WIDTH_DESKTOP}rem;
  }
  max-width: ${Sizes.PRSONAL_WIDTH_MOBILE}rem;
  margin: auto;

  ${(props) =>
    `padding: ${props.verticalPadding}rem ${props.horizontalPadding}rem;`}
`;

const StyledBackgroundWrapper = styled.div<{
  backgroundColor?: string;
  fullHeightBackground?: boolean;
}>`
  ${(props) =>
    props.backgroundColor ? `background-color: ${props.backgroundColor}` : ''};
  ${(props) =>
    props.fullHeightBackground ? 'min-height: calc(100vh - 80px)' : ''};
`;
