import styled from 'styled-components';
import arrowLeft from '../../assets/arrow-left.png';
import arrowRight from '../../assets/arrow-right.png';

export interface EQButtonProps {
  previous?: boolean;
  next?: boolean;
  onClick?: () => void;
}

export function EQButton(props: EQButtonProps) {
  return (
    <StyledEQButton onClick={props.onClick}>
      {(props.previous && <img src={arrowLeft} alt="arrow-left" />) ||
        (props.next && 'Næste') ||
        'Ok'}
      {(props.next && <img src={arrowRight} alt="arrow-right" />) ||
        (props.previous && 'Forrige')}
    </StyledEQButton>
  );
}

const StyledEQButton = styled.button`
  display: flex;
  justify-content: center;
  padding: 0.75rem 0.75rem;
  align-items: center;
  gap: 0.5625rem;
  border-radius: 0.25rem;
  border: 2px solid #8c95a2;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(216, 216, 255, 0.25) inset;
  width: 100px;
  font-family: Arial;
  font-size: 1rem;
  line-height: 1.5rem;
`;

export default EQButton;
