import { ClassConstructor, plainToClass } from 'class-transformer';
import { validateSync } from 'class-validator';
import { useLocation } from 'react-router-dom';
import { PadminPageState } from './types';

type StateType = PadminPageState;

export function useTypedNavigationState<T extends StateType>(
  type: ClassConstructor<T>
): T | null {
  const { state } = useLocation();
  if (state == null) return null;

  const transformedType = plainToClass(type, state);
  const validatedResult = validateSync({ transformedType });

  if (validatedResult.length) return null;

  return transformedType;
}
