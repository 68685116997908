import styled from 'styled-components';
import { Typography } from 'antd';
import { PrsonalWrapper } from '@prsonal/website/shared/components';

const { Text } = Typography;

export function PrivateLifePolicy() {
  return (
    <PrsonalWrapper>
      <StyledContainer>
        <Text>
          <b>Privatlivspolitik</b>
        </Text>
        <Text>Ikrafttrædelsesdato: 18/9-2023</Text>
        <Text>Senest opdateret 26/3-2024</Text>
        <StyledBoldText>1. Introduktion</StyledBoldText>
        <Text>
          Melatech (“vi”, “os”, “vores”) indsamler, behandler og beskytter dine
          data i overensstemmelse med Databeskyttelsesforordningen (GDPR).
        </Text>
        <StyledBoldText>2. Formål og indsamling</StyledBoldText>
        <Text>
          Vi indsamler dine oplysninger med henblik på deltagelse i
          forskningsprojektet PRSONAL.
        </Text>
        <StyledBoldText>3. Før brugeroprettelse</StyledBoldText>
        <Text>
          I forbindelse med besøg på prsonal.dk indsamles log-oplysninger,
          herunder IP-adresse og styresystem, med henblik på fejlfinding i
          forbindelse med tekniske fejl eller nedbrud.
          <br />
          <br />I forbindelse med login- og tilmeldingsprocessen indsamles
          cookies, der er nødvendige for login via MitID.
        </Text>
        <StyledBoldText>4. Efter brugeroprettelse</StyledBoldText>
        <Text>
          Hvis du deltager i forskningsprojektet PRSONAL, indsamles de
          oplysninger, som du afgiver i forbindelse med forskningsprojektet.
          Derudover føres en hændelseslog over brugerinteraktioner, herunder
          indtastede oplysninger, e-mailadresse, IP-adresse og styresystem, med
          henblik på fejlfinding i forbindelse med tekniske fejl eller nedbrud.
        </Text>
        <StyledBoldText>5. Videregivelse</StyledBoldText>
        <Text>
          Vi videregiver dine oplysninger til Region Hovedstaden, som er
          dataansvarlig for forskningsprojektet PRSONAL.
        </Text>
        <StyledBoldText>6. Opbevaring</StyledBoldText>
        <Text>
          Hvis du indtaster dine kontaktoplysninger, men ikke senere deltager i
          forskningsprojektet PRSONAL, bliver dine kontaktoplysninger slettet
          efter senest tre måneder fra indtastningsdatoen.
        </Text>
        <StyledBoldText>7. Dine rettigheder</StyledBoldText>
        <Text>
          I henhold til GDPR har du til enhver tid ret til at få adgang til,
          rette eller slette dine oplysninger. Ønsker du at udøve disse
          rettigheder, eller har du spørgsmål om dine data, kan du kontakte os
          på kontakt@melatech.io
        </Text>
        <StyledBoldText>8. Kontakt os</StyledBoldText>
        <Text>
          For spørgsmål om dine databeskyttelsesrettigheder og begæring om
          indsigt m.m., kontakt da venligst vores Databeskyttelsesrådgiver
          (DPO):
          <br />
          <br />
          Unitas ApS
          <br />
          gdpr@unitas.consulting
          <br />
          Lyshøjen 12
          <br />
          8520 Lystrup
          <br />
          CVR.: 40978089
        </Text>
      </StyledContainer>
    </PrsonalWrapper>
  );
}

const StyledContainer = styled.div`
  display: grid;
  gap: 1rem;
`;

const StyledBoldText = styled(Text)`
  font-weight: 500;
`;
