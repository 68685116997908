import { Sizes } from '@prsonal/website/shared/utils';
import styled from 'styled-components';
import { Typography } from 'antd';
import { useState } from 'react';
import ConsentTemplate from './consent-template';
import { Consent } from '@prsonal/website/shared/data-access';
import { format } from 'date-fns';

const { Text } = Typography;
export interface FirstConsentProps {
  onCancel: () => void;
  onConsentGiven: (signature?: string, wantInfo?: boolean) => void;
  consent?: Consent;
  readonlyMedStudSignature: boolean;
  participantName?: string;
}

export function FirstConsent(props: FirstConsentProps) {
  const [extraInfoNotWanted, setExtraInfoNotWanted] = useState<
    boolean | undefined
  >(undefined);
  const [medStudSignature, setMedStudSignature] = useState<string>();

  return (
    <ConsentTemplate
      canNavigate={'forwardDisabled'}
      canSign={
        !props.readonlyMedStudSignature
          ? !!medStudSignature
          : extraInfoNotWanted != null
      }
      onCancel={props.onCancel}
      onConsentGiven={() =>
        props.onConsentGiven(
          !props.readonlyMedStudSignature ? medStudSignature : undefined,
          extraInfoNotWanted
        )
      }
      projectIdentification={false}
      consentDisplay={
        <StyledContainer>
          <StyledConsentTemplate>
            <Text strong>(S4)</Text>
            <StyledCenteredText strong>
              Informeret samtykke til deltagelse i et sundhedsvidenskabeligt
              forskningsprojekt.
            </StyledCenteredText>
            <br />
            <br />
            <StyledStandardGappedContainer>
              <Text>Forskningsprojektets titel:</Text>
              <Text>
                PRSONAL: Population-based Randomized Study Of a Novel breast
                cancer risk Algorithm and stratified screening
              </Text>
            </StyledStandardGappedContainer>
            <br />
            <br />
            <StyledStandardGappedContainer>
              <Text strong>Erklæring fra forsøgspersonen:</Text>
              <Text>
                Jeg har fået skriftlig og mundtlig information og jeg ved nok om
                formål, metode, fordele og ulemper til at sige ja til at
                deltage.
              </Text>
              <Text>
                Jeg ved, at det er <Text underline>frivilligt at deltage</Text>,
                og at jeg altid kan trække mit samtykke tilbage uden at miste
                mine nuværende eller fremtidige rettigheder til behandling.
              </Text>
              <Text>
                Jeg giver samtykke til, at deltage i forskningsprojektet og til,
                at mit biologiske materiale udtages med henblik på opbevaring i
                en forskningsbiobank. Jeg har fået en kopi af dette samtykkeark
                samt en kopi af den skriftlige information om projektet til eget
                brug.
              </Text>
            </StyledStandardGappedContainer>
            <br />
            <br />
            <StyledInputContainer>
              <StyledLabel htmlFor="participantName">
                Forsøgspersonens navn:
              </StyledLabel>
              <StyledInput
                id="participantName"
                readOnly
                value={props.participantName}
              />
            </StyledInputContainer>
            <br />
            <br />
            <StyledInputContainer>
              <div style={{ maxWidth: 250 }}>
                <StyledLabel htmlFor="participantDate">Dato:</StyledLabel>
                <StyledInput
                  id="participantDate"
                  readOnly
                  value={
                    props.consent?.prsonalConsentDate
                      ? format(
                          new Date(props.consent.prsonalConsentDate),
                          'dd/MM/yyyy'
                        )
                      : format(new Date(), 'dd/MM/yyyy')
                  }
                />
              </div>
              <StyledLabel htmlFor="participantSignature">
                Underskrift:
              </StyledLabel>
              <StyledInput id="participantSignature" italic />
            </StyledInputContainer>
            <br />
            <Text>
              Hvis der kommer nye væsentlige helbredsoplysninger frem om dig i
              forskningsprojektet vil du blive informeret. Vil du frabede dig
              information om nye væsentlige helbredsoplysninger, som kommer frem
              i forskningsprojektet, bedes du markere her:{' '}
              <StyledCheckboxLabel>
                <StyledCheckbox />
                <StyledCheckboxCustom />
              </StyledCheckboxLabel>
              (sæt x)
            </Text>
            <br />
            <br />
            <Text>
              Ønsker du at blive informeret om forskningsprojektets resultat
              samt eventuelle konsekvenser for dig?:
            </Text>
            <br />
            <br />
            <StyledYesNoContainer>
              <div>
                <Text>Ja</Text>
                <StyledCheckboxLabel>
                  <StyledCheckbox
                    checked={extraInfoNotWanted}
                    onChange={() => setExtraInfoNotWanted(true)}
                  />
                  <StyledCheckboxCustom />
                </StyledCheckboxLabel>
                <Text>(sæt x)</Text>
              </div>
              <div>
                <Text>Nej</Text>
                <StyledCheckboxLabel>
                  <StyledCheckbox
                    checked={extraInfoNotWanted === false}
                    onChange={() => setExtraInfoNotWanted(false)}
                  />
                  <StyledCheckboxCustom />
                </StyledCheckboxLabel>
                <Text>(sæt x)</Text>
              </div>
            </StyledYesNoContainer>

            <br />
            <br />
            <Text strong>Erklæring fra den, der afgiver information:</Text>
            <br />
            <StyledStandardGappedContainer>
              <Text>
                Jeg erklærer, at forsøgspersonen har modtaget mundtlig og
                skriftlig information om forsøget.
              </Text>
              <Text>
                Efter min overbevisning er der givet tilstrækkelig information
                til, at der kan træffes beslutning om deltagelse i forsøget.
              </Text>
              <Text>Navnet på den, der har afgivet information:</Text>
            </StyledStandardGappedContainer>
            <br />
            <br />
            <StyledInputContainer>
              <div style={{ maxWidth: 250 }}>
                <StyledLabel htmlFor="medStudDate">Dato:</StyledLabel>
                <StyledInput
                  id="medStudDate"
                  readOnly
                  value={
                    props.consent?.informedConsentDate
                      ? format(
                          new Date(props.consent.informedConsentDate),
                          'dd/MM/yyyy'
                        )
                      : format(new Date(), 'dd/MM/yyyy')
                  }
                />
              </div>
              <StyledLabel htmlFor="medStudSignature">Underskrift:</StyledLabel>
              <StyledInput
                id="medStudSignature"
                italic
                value={props.consent?.medStudInformedSignature}
                onChange={(e) => setMedStudSignature(e.target.value)}
                readOnly={props.readonlyMedStudSignature}
              />
            </StyledInputContainer>
            <br />
            <br />
            <br />
            <Text>ProjektIdentifikation: H-23017474.</Text>
          </StyledConsentTemplate>
        </StyledContainer>
      }
    />
  );
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledYesNoContainer = styled.div`
  display: flex;
  gap: 10rem;
`;

const StyledConsentTemplate = styled.div`
  width: ${Sizes.PDF_WIDTH}px;
`;

const StyledStandardGappedContainer = styled.div`
  display: grid;
  gap: ${Sizes.STANDARD_GAP}rem;
`;

const StyledCenteredText = styled(Text)`
  display: flex;
  justify-content: center;
`;

const StyledInputContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

const StyledLabel = styled.label``;

const StyledInput = styled.input<{ italic?: boolean }>`
  flex: 1;
  border: none;
  outline: none;
  border-bottom: 1px solid #000;
  padding: 5px;
  box-sizing: border-box;
  ${(props) => (props.italic ? 'font-style: italic' : '')};
`;

const StyledCheckboxLabel = styled.label`
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  user-select: none;
`;

const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border: none;
`;

const StyledCheckboxCustom = styled.span`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #000;

  ${StyledCheckbox}:checked + &::after {
    content: '✕'; /* 'x' character */
    position: absolute;
    left: 0;
    bottom: -1px; /* Adjust the vertical position to overlap with the underline */
    color: #000; /* Color of the 'x' */
  }
`;
