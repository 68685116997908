import { InfoCircleOutlined } from '@ant-design/icons';
import { GroupEnum, IndexEnum, RoleEnum } from '@prsonal/shared/utils/types';
import { User, prsonalApi } from '@prsonal/website/shared/data-access';
import { RiskLevel, Sizes, getRiskLevel } from '@prsonal/website/shared/utils';
import {
  Alert,
  Button,
  GlobalToken,
  Popover,
  Spin,
  Tag,
  Tooltip,
  Typography,
  theme,
} from 'antd';
import { format } from 'date-fns';
import styled from 'styled-components';

const { useToken } = theme;
const { Text, Title } = Typography;

export interface StudyDataProps {
  user: User;
  userRole: RoleEnum;
  onRandomize: () => void;
  isLoading: boolean;
}

const riskGroup: Record<RiskLevel, string> = {
  'high-risk': 'Høj',
  'increased-risk': 'Forhøjet',
  'low-risk': 'Lav',
  'medium-risk': 'Mellem',
};

export function StudyData(props: StudyDataProps) {
  const { token } = useToken();
  const [
    recalculateRisk,
    { isLoading: isReCalculatingRisk, error: errorReCalcuatingRisk },
  ] = prsonalApi.useReplaceOneRiskCalculationMutation();

  const [
    calculateRisk,
    { isLoading: isCalculatingRisk, error: errorCalcuatingRisk },
  ] = prsonalApi.useCreateOneRiskCalculationMutation();

  const [
    updateRiskCalculation,
    { isLoading: updatingRiskCalculation, error: errorUpdatingRiskCalculation },
  ] = prsonalApi.useUpdateOneRiskCalculationMutation();

  const canRandomize =
    props.user?.inclusionDate != null &&
    props.user?.bmi &&
    props.user.riskQuestionnaire?.status === 'FilledOut' &&
    props.user?.lifeQualityWorryQuestionnaires?.filter(
      (loq) => loq.index === IndexEnum.One
    )?.[0].status === 'FilledOut';

  const canCalculateRisk =
    props.user.group &&
    props.user.birads &&
    props.user.prs &&
    props.userRole === 'Admin';

  return (
    <StyledStudyData>
      <StyledTitleContainer token={token}>Forskningsdata</StyledTitleContainer>
      <StyledContentContainer>
        <StyledTextContainer>
          <Text>Inklusionsdato:</Text>
          <Text type="secondary">
            {props.user?.inclusionDate && props.user?.consent?.prsonalConsent
              ? format(new Date(props.user.inclusionDate), 'dd-MM-yy')
              : 'Ikke inkluderet'}
          </Text>
        </StyledTextContainer>
        <StyledTextContainer>
          <Text>Gruppe:</Text>
          {props.user?.group != null ? (
            <Text type="secondary">
              {props.user.group === GroupEnum.ControlGroup ? (
                <Tag>Kontrolgruppe</Tag>
              ) : (
                <Tag>Interventionsgruppe</Tag>
              )}
            </Text>
          ) : props.isLoading ? (
            <Spin />
          ) : (
            <Tooltip
              title={
                !canRandomize
                  ? 'Kvinden skal have udfyldt risikoskema, livskvalitet dag 1, højde og vægt for at kunne randomiseres.'
                  : ''
              }
            >
              <Button
                size="small"
                disabled={!canRandomize}
                type="primary"
                onClick={() => props.onRandomize()}
              >
                Randomisér
              </Button>
            </Tooltip>
          )}
        </StyledTextContainer>
        <StyledTextContainer>
          <Text>Risikoberegning:</Text>
          <Text type="secondary">
            {props.user?.riskCalculation ? (
              <Popover
                overlayStyle={{
                  width: '20vw',
                }}
                content={
                  <>
                    <Title level={4}>Detaljer</Title>
                    <StyledDescriptionContainer>
                      <Text strong>Præcis risiko:</Text>
                      {props.user.riskCalculation.tenYearRisk * 100 + '%'}
                    </StyledDescriptionContainer>
                    <StyledDescriptionContainer>
                      <Text strong>Risikogruppe:</Text>
                      {getRiskLevel(props.user.riskCalculation.tenYearRisk)}
                    </StyledDescriptionContainer>
                    <Button
                      size="small"
                      disabled={props.userRole !== 'Admin'}
                      onClick={() => {
                        if (props.user.id && props.user.riskCalculation?.id) {
                          recalculateRisk({
                            id: props.user.riskCalculation?.id,
                            replaceRiskCalculationDto: {},
                          });
                        }
                      }}
                      loading={isReCalculatingRisk}
                    >
                      Genberegn risiko
                    </Button>
                  </>
                }
              >
                <Tag icon={<InfoCircleOutlined />}>
                  {(props.user.riskCalculation.tenYearRisk * 100).toFixed(2) +
                    '%'}
                </Tag>
              </Popover>
            ) : (
              <Tooltip
                title={
                  !canCalculateRisk
                    ? 'Kvinden skal være randomiseret samt have BIRADS og PRS udfyldt før risiko kan udregnes.'
                    : ''
                }
              >
                <Button
                  title="Tooltip Text"
                  size="small"
                  disabled={!canCalculateRisk}
                  onClick={() => {
                    if (props.user.id) {
                      calculateRisk({
                        createRiskCalculationDto: { userId: props.user.id },
                      });
                    }
                  }}
                  loading={isCalculatingRisk}
                >
                  Udregn risiko
                </Button>
              </Tooltip>
            )}
          </Text>

          {props.user.riskCalculation?.id ? (
            <Text type="secondary">
              {!props.user.riskCalculation?.riskCalculationReleased ? (
                <Button
                  disabled={
                    props.userRole !== 'Admin' ||
                    !props.user.isMammographyProcessCompleted
                  }
                  size="small"
                  loading={updatingRiskCalculation}
                  onClick={() => {
                    if (props.user.riskCalculation?.id)
                      updateRiskCalculation({
                        id: props.user.riskCalculation?.id,
                        updateRiskCalculationDto: {
                          riskCalculationReleased: new Date().toISOString(),
                        },
                      });
                  }}
                >
                  Frigiv
                </Button>
              ) : (
                <Tag color="success">Frigivet</Tag>
              )}
            </Text>
          ) : null}
        </StyledTextContainer>
        {props.user?.riskCalculation ? (
          <StyledTextContainer>
            <Text>Risikogruppe:</Text>
            <Text type="secondary">
              {riskGroup[getRiskLevel(props.user.riskCalculation.tenYearRisk)]}
            </Text>
          </StyledTextContainer>
        ) : null}
        {errorCalcuatingRisk ?? errorReCalcuatingRisk ? (
          <Alert
            description="Der skete en fejl ved udregning af risiko"
            type="error"
          />
        ) : null}
        {errorUpdatingRiskCalculation ? (
          <Alert
            description="Der skete en fejl ved frigivelse af risiko"
            type="error"
          />
        ) : null}
      </StyledContentContainer>
    </StyledStudyData>
  );
}

const StyledStudyData = styled.div`
  border: ${Sizes.SMALL_BORDER}rem solid var(--neutral-6, #bfbfbf);
`;

const StyledTitleContainer = styled.div<{ token: GlobalToken }>`
  background-color: ${(props) => props.token.colorFillSecondary};
  padding: ${Sizes.LARGE_GAP}rem;
  border-bottom: ${Sizes.SMALL_BORDER}rem solid var(--neutral-6, #bfbfbf);
`;

const StyledContentContainer = styled.div`
  padding: ${Sizes.LARGE_GAP}rem;
  display: flex;
  flex-direction: column;
  gap: ${Sizes.STANDARD_GAP}rem;
`;

const StyledTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledDescriptionContainer = styled.div`
  display: flex;
  gap: 5px;
`;
