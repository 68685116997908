import { Input, InputProps } from 'antd';
import { isNumber } from 'lodash';
import React from 'react';

export interface InputNumberProps {
  onValueChange: (number?: number) => void;
  inputProps?: InputProps;
}

/**
 * If anything but a number is given, 0 will be placed as value.
 * @param {InputNumberProps} props
 * @param {Function} props.onValueChange - define function to handle onChange in input field.
 * @returns {JSX.Element}
 */
export function PrsonalInputNumber(props: InputNumberProps) {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    props.onValueChange(
      inputValue.length === 0
        ? undefined
        : isNaN(+inputValue)
        ? props.inputProps?.value && isNumber(props.inputProps.value)
          ? props.inputProps.value
          : 0
        : +inputValue
    );
  };
  return (
    <Input
      {...props.inputProps}
      onChange={handleInputChange}
      inputMode="numeric"
    />
  );
}

export default PrsonalInputNumber;
