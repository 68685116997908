import { useAuthentication } from '@prsonal/website/feature-auth';
import {
  LifeQualityWorryQuestionnaire,
  prsonalApi,
} from '@prsonal/website/shared/data-access';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Introduction from './introduction/introduction';
import SurveyFrontPage from './survey-front-page/survey-front-page';
import EqMobility from './questions/eq-mobility/eq-mobility';
import EqPersonalCare from './questions/eq-personal-care/eq-personal-care';
import EqUsualActivities from './questions/eq-usual-activities/eq-usual-activities';
import EqPain from './questions/eq-pain/eq-pain';
import EqAnxiety from './questions/eq-anxiety/eq-anxiety';
import HealthSlider from './components/health-slider/health-slider';
import LermanIntroduction from './lerman-introduction/lerman-introduction';
import { LermanSurvey } from './questions/lerman/lerman';
import PromisIntroduction from './promis-introduction/promis-introduction';
import PromisSurvey from './questions/promis/promis';
import { inRange } from 'lodash';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { RoleEnum } from '@prsonal/shared/utils/types';

type Index = 1 | 2 | 3 | 4;

export function QualityWorryQuestionnaire() {
  const currentUser = useAuthentication().user;
  const navigate = useNavigate();
  const index = useLocation().state as Index;
  const { userId } = useParams();

  const { data: user } = prsonalApi.useGetOneUserQuery(
    userId
      ? { id: userId, join: ['lifeQualityWorryQuestionnaires'] }
      : skipToken
  );

  const lifeQualityWorryQuestionnaireId =
    user?.lifeQualityWorryQuestionnaires?.filter((q) => q.index === index)[0]
      .id ?? '';

  const { data: lifeQualityWorryQuestionnaire, isLoading } =
    prsonalApi.useGetOneLifeQualityWorryQuestionnaireQuery(
      lifeQualityWorryQuestionnaireId
        ? { id: lifeQualityWorryQuestionnaireId }
        : skipToken
    );

  const [activeComponentIndex, setActiveComponentIndex] = useState(0);
  const [questionnaireAnswers, setQuestionnaireAnswers] = useState<
    Partial<LifeQualityWorryQuestionnaire> | undefined
  >(lifeQualityWorryQuestionnaire);
  useEffect(() => {
    if (!isLoading) {
      setQuestionnaireAnswers(lifeQualityWorryQuestionnaire);
    }
  }, [isLoading, lifeQualityWorryQuestionnaire]);

  const [updateLifeQualityWorryQuestionnaire] =
    prsonalApi.useUpdateOneLifeQualityWorryQuestionnaireMutation();

  const components = [
    <Introduction
      name={`${currentUser?.name ?? 'ukendt deltager'}`}
      id={lifeQualityWorryQuestionnaireId}
      answers={questionnaireAnswers}
      onChangePage={handleChangePageClick}
      onValueChange={onValueChange}
    />,
    <SurveyFrontPage
      id={lifeQualityWorryQuestionnaireId}
      answers={questionnaireAnswers}
      onChangePage={handleChangePageClick}
      onValueChange={onValueChange}
    />,
    <EqMobility
      id={lifeQualityWorryQuestionnaireId}
      answers={questionnaireAnswers}
      onChangePage={handleChangePageClick}
      onValueChange={onValueChange}
    />,
    <EqPersonalCare
      id={lifeQualityWorryQuestionnaireId}
      answers={questionnaireAnswers}
      onChangePage={handleChangePageClick}
      onValueChange={onValueChange}
    />,
    <EqUsualActivities
      id={lifeQualityWorryQuestionnaireId}
      answers={questionnaireAnswers}
      onChangePage={handleChangePageClick}
      onValueChange={onValueChange}
    />,
    <EqPain
      id={lifeQualityWorryQuestionnaireId}
      answers={questionnaireAnswers}
      onChangePage={handleChangePageClick}
      onValueChange={onValueChange}
    />,
    <EqAnxiety
      id={lifeQualityWorryQuestionnaireId}
      answers={questionnaireAnswers}
      onChangePage={handleChangePageClick}
      onValueChange={onValueChange}
    />,
    <HealthSlider
      id={lifeQualityWorryQuestionnaireId}
      answers={questionnaireAnswers}
      onChangePage={handleChangePageClick}
      onValueChange={onValueChange}
    />,
    <PromisIntroduction
      id={lifeQualityWorryQuestionnaireId}
      answers={questionnaireAnswers}
      onChangePage={handleChangePageClick}
      onValueChange={onValueChange}
    />,
    <PromisSurvey
      id={lifeQualityWorryQuestionnaireId}
      answers={questionnaireAnswers}
      onChangePage={handleChangePageClick}
      onValueChange={onValueChange}
    />,
    <LermanIntroduction
      id={lifeQualityWorryQuestionnaireId}
      answers={questionnaireAnswers}
      onChangePage={handleChangePageClick}
      onValueChange={onValueChange}
    />,
    <LermanSurvey
      id={lifeQualityWorryQuestionnaireId}
      answers={questionnaireAnswers}
      onChangePage={handleChangePageClick}
      onValueChange={onValueChange}
    />,
  ];

  function onValueChange(value: Partial<LifeQualityWorryQuestionnaire>) {
    setQuestionnaireAnswers((v) => ({ ...v, ...value }));
  }

  function handleChangePageClick(indexChange: number, save = false) {
    if (save && lifeQualityWorryQuestionnaireId) {
      updateLifeQualityWorryQuestionnaire({
        id: lifeQualityWorryQuestionnaireId,
        lifeQualityWorryQuestionnaire: {
          ...questionnaireAnswers,
          status: 'FilledOut',
        } as LifeQualityWorryQuestionnaire,
      });
    }

    if (inRange(activeComponentIndex + indexChange, 0, components.length)) {
      setActiveComponentIndex(activeComponentIndex + indexChange);
    } else {
      navigate(
        currentUser?.role === RoleEnum.MedicalStudent
          ? `/padmin/${user?.id}`
          : '/'
      );
    }
  }

  return components[activeComponentIndex];
}

export default QualityWorryQuestionnaire;
