import { createGlobalStyle } from 'styled-components';

export const Colors = createGlobalStyle`
    :root {
        --neutral-1: #ffffff;
        --neutral-2: #F7FBFA;
        --neutral-3: #F5F5F5;
        --neutral-5: #d9d9d9;
        --neutral-6: #bfbfbf;
        --neutral-7: #8C8C8C;
        --neutral-8: #595959;
        --neutral-9: #434343;
        --neutral-13: #000;
        --cyan-2: #F7FBFA;
        --cyan-4: #E8F4F0;
        --cyan-7: #08979C;
        --cyan-8: #006d75;
        --cyan-9: #00474F;
        --primary: #08979C;
        --primary-6: #1890FF;
        --shadow: rgba(0, 0, 0, 0.1);
    }
`;
