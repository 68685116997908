import styled from 'styled-components';
import { Copy, Phone } from '../../icons';
import { Button, DatePicker, Input, Radio, Typography } from 'antd';
import { Sizes } from '@prsonal/website/shared/utils';
import { useState } from 'react';
import dayjs from 'dayjs';
import { Note } from '@prsonal/website/shared/data-access';
import { format } from 'date-fns';

const { Text } = Typography;
const { TextArea } = Input;

export interface ContactNotesProps {
  notes?: Note[];
  onNoteCreate: (note: Note) => void;
}

const today = new Date();
const noteTemplate = {
  date: dayjs(today).toISOString(),
  reason: '',
  note: '',
};

export function ContactNotes(props: ContactNotesProps) {
  const [isNewNote, setIsNewNote] = useState(true);
  const [isNoteDisplayed, setIsNoteDisplayed] = useState<string>();

  const [newNote, setNewNote] = useState<Note>(noteTemplate);

  return (
    <StyledContactNotes>
      <StyledTitleContainer>
        <Text>Note</Text>
        <Phone />
      </StyledTitleContainer>
      <Radio.Group value={isNewNote}>
        <Radio.Button value={true} onChange={() => setIsNewNote(!isNewNote)}>
          Ny note
        </Radio.Button>
        <Radio.Button value={false} onChange={() => setIsNewNote(!isNewNote)}>
          Tidligere noter
        </Radio.Button>
      </Radio.Group>
      {isNewNote ? (
        <StyledNewNoteContainer>
          <DatePicker
            defaultValue={dayjs(today)}
            value={dayjs(newNote.date)}
            onChange={(e) =>
              setNewNote({
                ...newNote,
                date: e?.toISOString() ?? dayjs(today).toISOString(),
              })
            }
          />
          <Input
            placeholder="Henvendelses årsag"
            value={newNote.reason}
            onChange={(e) => setNewNote({ ...newNote, reason: e.target.value })}
          />
          <TextArea
            placeholder="Skriv din note her"
            value={newNote.note}
            rows={5}
            maxLength={1000}
            onChange={(e) => setNewNote({ ...newNote, note: e.target.value })}
            showCount
          />
          <StyledButtonContainer>
            <Button
              type="primary"
              onClick={() => {
                props.onNoteCreate(newNote);
                setNewNote(noteTemplate);
              }}
              disabled={!newNote.note.length || !newNote.reason.length}
            >
              Gem note
            </Button>
          </StyledButtonContainer>
        </StyledNewNoteContainer>
      ) : (
        <StyledPreviewsNotes>
          <StyledPreviewsNotesTitleContainer>
            <Text>Dato</Text>
            <Text>Henvendelses årsag</Text>
          </StyledPreviewsNotesTitleContainer>
          {props.notes?.map((note) => (
            <>
              <StyledNoteTitleContainer
                onClick={() =>
                  setIsNoteDisplayed((v) =>
                    note.id !== v ? note.id : undefined
                  )
                }
              >
                <StyledTitleContainer>
                  <Copy />
                  <Text underline>
                    {format(new Date(note.date), 'dd.MM.yyyy')}
                  </Text>
                </StyledTitleContainer>
                <Text type="secondary">{note.reason}</Text>
              </StyledNoteTitleContainer>
              <StyledNoteArea hidden={isNoteDisplayed !== note.id}>
                <TextArea
                  showCount
                  maxLength={1000}
                  value={note.note}
                  readOnly
                />
              </StyledNoteArea>
            </>
          ))}
        </StyledPreviewsNotes>
      )}
    </StyledContactNotes>
  );
}

const StyledContactNotes = styled.div`
  display: grid;
  gap: ${Sizes.LARGE_GAP}rem;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${Sizes.STANDARD_GAP}rem;
`;

const StyledNewNoteContainer = styled.div`
  display: grid;
  gap: ${Sizes.STANDARD_GAP}rem;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: ${Sizes.LARGE_GAP}rem;
`;

const StyledPreviewsNotes = styled.div`
  border: ${Sizes.SMALL_BORDER}rem solid var(--neutral-6);
`;

const StyledPreviewsNotesTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${Sizes.LARGE_GAP}rem;
  background-color: var(--cyan-4);
`;

const StyledNoteTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${Sizes.LARGE_GAP}rem;
  border-top: ${Sizes.SMALL_BORDER}rem solid var(--neutral-6);
  cursor: pointer;
`;

const StyledNoteArea = styled.div<{ hidden: boolean }>`
  margin-bottom: ${Sizes.EXTRA_LARGE_GAP}rem;
  ${(props) => (props.hidden ? 'display:none' : '')};
`;
