import styled from 'styled-components';
import { Spin, Typography } from 'antd';

const { Title } = Typography;

export function Loader() {
  return (
    <StyledLoader>
      <StyledSpinContainer>
        <Spin size="large" />
      </StyledSpinContainer>
      <Title level={3}>Indlæser</Title>
    </StyledLoader>
  );
}

const StyledSpinContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledLoader = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
