import { User } from '@prsonal/website/shared/data-access';
import { Sizes } from '@prsonal/website/shared/utils';
import { Button, InputNumber, Typography } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { PadminProps } from '../../padmin.interfaces';
import { isEqual } from 'lodash';

const { Text } = Typography;

export function PRSInput(props: PadminProps) {
  const [user, setUser] = useState<Pick<User, 'prs'>>({
    prs: props.user.prs,
  });
  const [prsError, setPrsError] = useState(false);

  const unChangedData = isEqual(user, {
    prs: props.user.prs,
  });

  return (
    <StyledPRSInput>
      <StyledContainer>
        <StyledInputs>
          <StyledInputContainer>
            <Text>{'PRS (z-score)'}</Text>
            <InputNumber
              disabled={props.user.group !== 'InterventionGroup'}
              status={prsError ? 'error' : undefined}
              onChange={(e) => {
                if (e != null && (e > 10 || e < -10)) {
                  setPrsError(true);
                } else {
                  setPrsError(false);
                }
                setUser((v) => ({
                  ...v,
                  prs: e ? e : null,
                }));
              }}
              // For some reason antd doesnt allow to return undefined or null even though the input can be and the component will display placeholder if undefined is returned...
              //eslint-disable-next-line
              //@ts-expect-error
              parser={(value) => (value ? value?.replace(',', '.') : null)}
              placeholder="Indtast PRS"
              style={{ width: 225 }}
              step={'0.0000000001'}
              value={user?.prs}
            />
          </StyledInputContainer>
        </StyledInputs>
        <Button
          disabled={prsError || unChangedData}
          onClick={() => props.onValueChange(user as User)}
        >
          Gem
        </Button>
      </StyledContainer>
    </StyledPRSInput>
  );
}

const StyledPRSInput = styled.div`
  border: ${Sizes.SMALL_BORDER} solid var(--neutral-6);
  box-shadow: 0px 0.25rem ${Sizes.STANDARD_GAP}rem 0px var(--shadow);
  padding: ${Sizes.LARGE_GAP}rem;
  background-color: var(--neutral-1);
  display: grid;
  gap: ${Sizes.STANDARD_GAP}rem;
`;

const StyledContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 6fr 0.5fr;
`;

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes.STANDARD_GAP}rem;
`;

const StyledInputs = styled.div`
  display: flex;
  gap: ${Sizes.LARGE_GAP}rem;
`;
