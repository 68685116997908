import { useState } from 'react';
import arrow from '../../assets/arrow-scale.svg';
import styled from 'styled-components';
import { Grid } from 'antd';
import { PrsonalWrapper } from '@prsonal/website/shared/components';
import Modal from '../modal/modal';
import EQFooter from '../eq-footer/eq-footer';
import { QualityWorryQuestionnaireProps } from '../../quality-worry-questionnaire.interfaces';
import { Breakpoints } from '@prsonal/website/shared/utils';
const { useBreakpoint } = Grid;

/* eslint-disable-next-line */
export interface HealthSliderProps extends QualityWorryQuestionnaireProps {}

export function HealthSlider(props: HealthSliderProps) {
  const [health, setHealth] = useState(props.answers?.healthScore ?? -1);
  const [hoverHealth, setHoverHealth] = useState(-1);
  const [showCircle, setShowCircle] = useState(health !== -1);
  const [showArrow, setShowArrow] = useState(health !== -1);
  const [showModal, setShowModal] = useState(false);
  const [showInfo, setShowInfo] = useState(true);

  const screens = useBreakpoint();

  const onClick = (index: number) => {
    setHealth(index);
    setShowCircle(true);
    setShowArrow(true);
    props.onValueChange({ healthScore: index });
  };

  const onHover = (index: number) => {
    setHoverHealth(index);
    setShowArrow(true);
  };

  const onLeave = () => {
    setHoverHealth(-1);
    health === -1 ? setShowCircle(false) : setShowCircle(true);
    health === -1 ? setShowArrow(false) : setShowArrow(true);
  };
  const onClickNext = (increment: number) => {
    if (health === -1 && increment === 1) {
      setShowModal(true);
    } else {
      props.onChangePage(increment);
    }
  };

  const lines = [];
  for (let i = 100; i >= 0; i--) {
    if (i % 10 === 0) {
      lines.push(
        <StyledLineContainer
          onClick={() => onClick(i)}
          onMouseEnter={() => onHover(i)}
          onMouseLeave={() => onLeave()}
          key={i}
        >
          <StyledLine fat={screens.lg || i % 50 === 0} key={i}></StyledLine>
          {(screens.lg || i % 50 === 0) && <div>{i}</div>}
        </StyledLineContainer>
      );
    } else {
      lines.push(
        <StyledLineContainer
          onClick={() => onClick(i)}
          onMouseEnter={() => onHover(i)}
          onMouseLeave={() => onLeave()}
          key={i}
        >
          <StyledLine key={i}></StyledLine>
        </StyledLineContainer>
      );
    }
  }

  return (
    <PrsonalWrapper
      verticalPadding={0}
      horizontalPadding={0}
      fullHeightBackground
    >
      {showInfo && !screens.lg ? (
        <StyledColumn>
          <StyledBulletContainer>
            <StyledBulletList key={'info-list'}>
              <StyledBullet key={0}>
                Vi vil gerne vide, hvor godt eller dårligt dit helbred er I DAG.
              </StyledBullet>
              <StyledBullet key={1}>
                Denne skala er nummereret fra 0 til 100.
              </StyledBullet>
              <StyledBullet key={2}>
                100 svarer til det <u>bedste</u> helbred, du kan forestille dig.
                <br />0 svarer til det <u>dårligste</u> helbred, du kan
                forestille dig.{' '}
              </StyledBullet>
            </StyledBulletList>
          </StyledBulletContainer>
          <EQFooter
            onChangePage={(increment: number) => {
              increment === -1
                ? props.onChangePage(increment)
                : setShowInfo(false);
            }}
          />
        </StyledColumn>
      ) : (
        <StyledColumn>
          <Modal
            show={showModal}
            handleClose={() => setShowModal(false)}
            children={undefined}
          />
          {!screens.lg && (
            <StyledRow>
              <StyledBulletContainer>
                <StyledBullet key={0}>
                  Tryk på det sted på skalaen, der viser, hvordan dit helbred er
                  I DAG.
                </StyledBullet>
              </StyledBulletContainer>
            </StyledRow>
          )}
          <StyledRow>
            <StyledParagraph>
              Det bedste helbred, du kan forestille dig
            </StyledParagraph>
          </StyledRow>
          <StyledContainer>
            {screens.lg && (
              <StyledBulletContainer>
                <StyledBulletList key={'bullets'}>
                  <StyledBullet key={0}>
                    Vi vil gerne vide, hvor godt eller dårligt dit helbred er I
                    DAG.
                  </StyledBullet>
                  <StyledBullet key={1}>
                    Denne skala er nummereret fra 0 til 100.
                  </StyledBullet>
                  <StyledBullet key={2}>
                    100 svarer til det <u>bedste</u> helbred, du kan forestille
                    dig.
                    <br />0 svarer til det <u>dårligste</u> helbred, du kan
                    forestille dig.{' '}
                  </StyledBullet>
                  <StyledBullet key={0}>
                    Tryk på det sted på skalaen, der viser, hvordan dit helbred
                    er I DAG.
                  </StyledBullet>
                </StyledBulletList>
              </StyledBulletContainer>
            )}
            <StyledTextBox show={health >= 0}>
              DIT HELBRED I DAG = <br />
              <b>{health}</b>
            </StyledTextBox>
            <StyledArrow
              show={showArrow}
              health={health}
              hoverHealth={hoverHealth}
            >
              <StyledHealthText>
                {hoverHealth === -1
                  ? health === -1
                    ? 0
                    : health
                  : hoverHealth}
              </StyledHealthText>
              <img src={arrow} alt="arrow" />
            </StyledArrow>
            <StyledSlider>
              {lines}
              <StyledCircle show={showCircle} health={health} />
            </StyledSlider>
          </StyledContainer>
          <StyledRow>
            <StyledParagraph>
              Det dårligste helbred, du kan forestille dig
            </StyledParagraph>
          </StyledRow>
          <EQFooter onChangePage={onClickNext} />
        </StyledColumn>
      )}
    </PrsonalWrapper>
  );
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  height: 50vh;
  width: 80%;
  margin: 1rem auto;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: calc(100vh - 80px);
  width: 100%;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: end;
  width: 80%;
  margin: auto;
`;

const StyledParagraph = styled.p`
  width: 7rem;
  font-family: Arial;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  margin: 0;
  text-align: center;
`;

const StyledBulletContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: auto;
`;

const StyledBulletList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const StyledBullet = styled.li`
  font-family: Arial;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  padding-bottom: 1rem;
  margin: 0;
`;

const StyledSlider = styled.div`
  width: 100%;
  height: 100%;
  flex: 0;
`;

const StyledLine = styled.div<{ fat?: boolean }>`
  ${(props) => (props.fat ? 'height: 2px;' : 'height: 1px;')}
  ${(props) =>
    props.fat ? 'border-top: 2px solid black;' : 'border-top: 1px solid black;'}
  ${(props) => (props.fat ? 'margin-left: 0px;' : 'margin-left: 5px;')}
  ${(props) => (props.fat ? 'width: 20px;' : 'width: 10px;')}
  margin-right: auto;
`;

const StyledLineContainer = styled.div`
  width: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: calc(50vh / 100);
  text-align: center;
  font-family: Arial;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  cursor: pointer;
`;

const StyledCircle = styled.div<{ show: boolean; health: number }>`
  ${(props) => (props.show ? 'visibility: visible;' : 'visibility: hidden;')}
  margin-left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: relative;
  background-color: red;
  top: ${(props) =>
    props.health === -1
      ? '0px'
      : `calc(50vh / 100 * ${-props.health - 1} - 3px)`};
  pointer-events: none;
`;
// ${-4 * props.health - 8}

const StyledArrow = styled.div<{
  show: boolean;
  hoverHealth: number;
  health: number;
}>`
  ${(props) => (props.show ? 'display: flex;' : 'display: none;')}
  flex-direction: row;
  justify-content: flex-end;
  align-items: start;
  position: relative;
  top: ${(props) =>
    props.hoverHealth === -1
      ? props.health === -1
        ? '0'
        : `calc(50vh - 50vh / 100 * ${props.health + 3.5})`
      : `calc(50vh - 50vh / 100 * ${props.hoverHealth + 3.5})`};
  font-family: Arial;
  font-size: 1rem;
  text-align: center;
  font-weight: 700;
  line-height: 1.375rem;
`;

const StyledHealthText = styled.p`
  margin: 0;
  margin-top: 0.2rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
`;

const StyledTextBox = styled.div<{ show: boolean }>`
  display: 'flex';
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  flex-direction: column;
  text-align: center;
  font-family: Arial;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  background-color: #000;
  color: var(--character-primary-inverse, #fff);
  @media screen and (min-width: ${Breakpoints.lg}px) {
    width: 10rem;
    padding: 0.625rem 1.875rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  font-size: 0.875rem;
  width: 6.5rem;
  padding: 0.625rem 0.325rem;
  margin: auto;
`;

export default HealthSlider;
