import { FamilyMember } from '@prsonal/website/shared/data-access';

export function createKidFamilyMembers(
  amount: number
): Partial<FamilyMember>[] {
  return Array.from({ length: amount }).map((_, index) => ({
    isChildOfTarget: true,
    familyMemberKey: `Barn ${index + 1} (uvist)`,
  }));
}
