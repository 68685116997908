import { ReadMorePage } from '@prsonal/feature-read-more';
import {
  AuthGuard,
  PrivateLifePolicy,
  PrsonalProvider,
} from '@prsonal/website/feature-auth';
import { ConsentPage } from '@prsonal/website/feature-consent';
import { PadminPage } from '@prsonal/website/feature-padmin';
import { RiskQuestionnairePage } from '@prsonal/website/feature-risk-questionnaire';
import { ThemedApp } from '@prsonal/website/shared/components';
import { store } from '@prsonal/website/shared/data-access';
import { baseEnvironment } from '@prsonal/website/shared/util-environment';
import { HttpClient, httpClientIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import 'antd/dist/reset.css';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import App from './app/app';
import UserPage from './app/pages/user-page/user-page';
import { Button, Result } from 'antd';
import { QualityWorryQuestionnaire } from '@prsonal/website/feature-quality-worry-questionnaire';
import { LandingPage } from '@prsonal/website/feature-landing-page';

if (baseEnvironment.ENV === 'production' || baseEnvironment.ENV === 'test') {
  Sentry.init({
    dsn:
      baseEnvironment.ENV === 'production'
        ? 'https://4f5135a1b084fe97c05d7c4950792064@sentry.dermloop.io/10'
        : 'https://cb4c2df01613b7d93d3e6b8c5ddee1bb@sentry.test.dermloop.io/8',
    environment: baseEnvironment.ENV,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', baseEnvironment.API_URL],
      }),
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.Replay(),
      httpClientIntegration({
        failedRequestStatusCodes: [[400, 599]],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    profilesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <LandingPage />,
      },
      { path: 'laes-mere', element: <ReadMorePage /> },
      {
        path: 'bruger',
        element: (
          <AuthGuard>
            <UserPage />
          </AuthGuard>
        ),
      },
      {
        path: 'risikoskema/:userId',
        element: (
          <AuthGuard>
            <RiskQuestionnairePage />
          </AuthGuard>
        ),
      },
      {
        path: 'bekymringsskema/:userId',
        element: (
          <AuthGuard>
            <QualityWorryQuestionnaire />
          </AuthGuard>
        ),
      },
      {
        path: 'padmin/:userId',
        element: (
          <AuthGuard>
            <PadminPage />
          </AuthGuard>
        ),
      },
      {
        path: 'samtykke/:consent',
        element: (
          <AuthGuard>
            <ConsentPage />
          </AuthGuard>
        ),
      },
    ],
  },
  { path: '/privat-livs-politik', element: <PrivateLifePolicy /> },
  {
    path: '*',
    element: (
      <Result
        status={'404'}
        title="404"
        subTitle="Denne side findes ikke"
        extra={
          <a href="/">
            <Button type="primary">Tilbage</Button>
          </a>
        }
      />
    ),
  },
]);

root.render(
  <ThemedApp>
    <Provider store={store}>
      <PrsonalProvider>
        <RouterProvider router={router} />
      </PrsonalProvider>
    </Provider>
  </ThemedApp>
);
