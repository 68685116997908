import { Row, Col, Layout, Typography, Grid } from 'antd';
import styled from 'styled-components';
import InfoBox from './components/info-box/info-box';
import { Breakpoints } from '@prsonal/website/shared/utils';
import { PrsonalWrapper } from '@prsonal/website/shared/components';

const { Content } = Layout;
const { Paragraph, Title, Text } = Typography;
const { useBreakpoint } = Grid;

export function FeatureProsAndCons() {
  const screens = useBreakpoint();
  return (
    <div id="pros-and-cons">
      <PrsonalWrapper>
        <Row align="top" justify="space-between" gutter={{ xs: 0, lg: 120 }}>
          <Col xs={24} lg={15}>
            <StyledContent>
              <Title level={screens.lg ? 2 : 4}>
                Fordele og ulemper ved at deltage i PRSONAL
              </Title>
              <br />
              <br />
              <Paragraph>
                Brystkræft er den hyppigste kræftsygdom blandt kvinder i Danmark
                med ca. 4.800 tilfælde om året.
                <br />
                Det nuværende program for brystkræftscreening blev landsdækkende
                i 2009 og er stort set ikke ændret siden. Over 80 pct. af de
                inviterede kvinder deltager. Det har været medvirkende til, at
                behandlingen i dag er mindre indgribende, og at dødeligheden af
                brystkræft er faldet. Screeningsprogrammet har en høj kvalitet,
                men der er også både fordele og ulemper for den enkelte kvinde.
              </Paragraph>
              <Paragraph>
                <Text strong>Personlig brystkræftscreening</Text>, som
                forskningsprojektet PRSONAL undersøger,{' '}
                <Text strong>kan øge fordelene</Text> og{' '}
                <Text strong>være med til at formindske ulemperne</Text>
              </Paragraph>
              <Paragraph>
                Det er vigtigt at slå fast, at vi endnu ikke kender alle fordele
                og eventuelle ulemper ved personlig brystkræftscreening. Det er
                derfor, vi forsker.
              </Paragraph>
              <Paragraph>
                Vi passer på dine data:
                <br />
                Alle dine data bliver opbevaret fortroligt ifølge
                databeskyttelsesforordningen og databeskyttelsesloven.
                Informationer og blodprøver vil forblive i Danmark.
              </Paragraph>
            </StyledContent>
          </Col>
          <Col xs={24} lg={9}>
            <InfoBox />
          </Col>
        </Row>
      </PrsonalWrapper>
    </div>
  );
}

export default FeatureProsAndCons;

const StyledContent = styled(Content)`
  @media screen and (min-width: ${Breakpoints.lg}px) {
    gap: 1.875rem;
  }
`;
