import {
  DownloadOutlined,
  SearchOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { CsvTypeEnum, GroupEnum, RoleEnum } from '@prsonal/shared/utils/types';
import { PrsonalTable } from '@prsonal/website/shared/components';
import {
  QuestionnaireStatusEnum,
  RiskCalculation,
  User,
  prsonalApi,
} from '@prsonal/website/shared/data-access';
import { baseEnvironment } from '@prsonal/website/shared/util-environment';
import { Sizes, getRiskLevelDa } from '@prsonal/website/shared/utils';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import {
  Button,
  Dropdown,
  Input,
  MenuProps,
  Table,
  Tag,
  Typography,
  Upload,
  UploadProps,
  message,
} from 'antd';
import { format } from 'date-fns';
import { isArray, toNumber } from 'lodash';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const { Column } = Table;
const { Text } = Typography;

export interface PadminTableProps {
  users: User[];
  userRole: RoleEnum;
  onRowClick: (user: User, users: User[] | undefined, search: string) => void;
  onCSVDownloadClick: (CSVType: CsvTypeEnum) => void;
}

export function PadminTable(props: PadminTableProps) {
  const [filteredUsers, setFilteredUsers] = useState<User[] | undefined>();
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sortQueryParams = useMemo(
    () => new URLSearchParams(search),
    [search]
  ).get('sort');

  const sortOrder = sortQueryParams?.includes('ascend') ? 'ascend' : 'descend';

  const paginationQueryParams = useMemo(
    () => new URLSearchParams(search),
    [search]
  ).get('pagination');

  const [pagination, setPagination] = useState({
    current: paginationQueryParams?.split(',')[0] ?? 1,
    pageSize: paginationQueryParams?.split(',')[1] ?? 1000,
  });

  return (
    <StyledPadminTable>
      <StyledInputContainer>
        <StyledInput
          placeholder="Søg med CPR, navn eller telefonnummer"
          addonBefore={<SearchOutlined />}
          onChange={(e) => {
            const userSearch = e.target.value.match(/\d-/)
              ? e.target.value.replace(/-/, '')
              : e.target.value;
            setFilteredUsers(
              props.users.filter(
                (user) =>
                  user.cpr.includes(userSearch) ||
                  user.name?.toLowerCase().includes(userSearch.toLowerCase()) ||
                  user.phone?.includes(userSearch)
              )
            );
          }}
        />
        {props.userRole === RoleEnum.Admin ? (
          <StyledButtonContainer>
            <Dropdown
              menu={{ items: getDropDownProps(props.onCSVDownloadClick) }}
              arrow
              placement="bottom"
            >
              <Button icon={<DownloadOutlined />}>Download CSV</Button>
            </Dropdown>
            <Upload {...getUploadProps(dispatch)}>
              <Button icon={<UploadOutlined />}>Upload CSV</Button>
            </Upload>
          </StyledButtonContainer>
        ) : null}
      </StyledInputContainer>
      <PrsonalTable
        pagination={{
          showSizeChanger: true,
          current: toNumber(pagination.current),
          pageSize: toNumber(pagination.pageSize),
          defaultPageSize: toNumber(pagination.pageSize),
        }}
        onChange={(pagination, _filters, sorter) => {
          if (isArray(sorter)) return;
          setPagination({
            current: pagination.current ?? 1,
            pageSize: pagination.pageSize ?? 1000,
          });
          navigate(
            `/bruger${
              sorter.field && sorter.order
                ? `?sort=${sorter.field},${sorter.order}`
                : ''
            }${pagination && sorter.field && sorter.order ? '&' : '?'}${
              pagination
                ? `pagination=${pagination.current},${pagination.pageSize}`
                : ''
            }`,
            {
              replace: true,
            }
          );
        }}
        locale={{
          triggerDesc: '',
          triggerAsc: '',
          cancelSort: '',
        }}
        scroll={{ x: 2600 }}
        dataSource={filteredUsers ?? props.users}
        rowKey={'id'}
        onRow={(user) => ({
          onClick: () =>
            props.onRowClick(
              user,
              filteredUsers,
              search.includes('?code') ? '' : search
            ),
        })}
      >
        <Column
          fixed={'left'}
          title={'Deltager'}
          width={350}
          render={(_, user: User) => (
            <StyledParticipantInfoContainer>
              <Text>{user.name}</Text>
              <Text type="secondary">{getBirthDayFromSSN(user.cpr)}</Text>
            </StyledParticipantInfoContainer>
          )}
        />
        <Column
          defaultSortOrder={
            sortQueryParams?.includes('inclusionDate') ? sortOrder : undefined
          }
          sorter={(a: User, b: User) => {
            if (a.inclusionDate && b.inclusionDate)
              return (
                new Date(a.inclusionDate).getTime() -
                new Date(b.inclusionDate).getTime()
              );
            return 1;
          }}
          title={'Inklusionsdato'}
          dataIndex={'inclusionDate'}
          render={(inclusionDate: string) => (
            <Text type={inclusionDate ? 'success' : 'danger'}>
              {inclusionDate
                ? format(new Date(inclusionDate), 'dd.MM.yyyy')
                : 'Ikke inkluderet'}
            </Text>
          )}
          filters={[
            { text: 'Inkluderet', value: true },
            { text: 'Ikke inkluderet', value: false },
          ]}
          onFilter={(value, record) => value === !!record.inclusionDate}
        />
        {/* Is user randomized (put in a group)? */}
        <Column
          defaultSortOrder={
            sortQueryParams?.includes('group') ? sortOrder : undefined
          }
          sorter={(a: User, b: User) =>
            !!a.group === !!b.group ? 0 : b.group ? 1 : -1
          }
          title={'Randomiseret'}
          dataIndex={'group'}
          render={(group: string | undefined) => (
            <Text type={group ? 'success' : 'danger'}>
              {group
                ? group === GroupEnum.InterventionGroup
                  ? 'Intervention'
                  : 'Kontrol'
                : 'Nej'}
            </Text>
          )}
          filters={[
            { text: 'Intervention', value: GroupEnum.InterventionGroup },
            { text: 'Kontrol', value: GroupEnum.ControlGroup },
            { text: 'Ikke randomiseret', value: 'Nej' },
          ]}
          onFilter={(value, record) =>
            value === 'Nej' ? !record.group : value === record.group
          }
        />
        <Column
          defaultSortOrder={
            sortQueryParams?.includes('prs') ? sortOrder : undefined
          }
          sorter={(a: User, b: User) => (a.prs === b.prs ? 0 : a.prs ? 1 : -1)}
          title={'PRS'}
          dataIndex={'prs'}
          render={(prs: string | undefined) => (
            <Text type={prs ? 'success' : 'danger'}>{prs ? 'Ja' : 'Nej'}</Text>
          )}
          filters={[
            { text: 'Ja', value: true },
            { text: 'Nej', value: false },
          ]}
          onFilter={(value, record) => value === !!record.prs}
        />
        <Column
          defaultSortOrder={
            sortQueryParams?.includes('birads') ? sortOrder : undefined
          }
          sorter={(a: User, b: User) =>
            a.birads === b.birads ? 0 : a.birads ? 1 : -1
          }
          title={'BiRADS'}
          dataIndex={'birads'}
          render={(birads: string) => (
            <Text type={birads ? 'success' : 'danger'}>
              {birads ? 'Ja' : 'Nej'}
            </Text>
          )}
          filters={[
            { text: 'Ja', value: true },
            { text: 'Nej', value: false },
          ]}
          onFilter={(value, record) => value === !!record.birads}
        />
        <Column
          defaultSortOrder={
            sortQueryParams?.includes('isMammographyProcessCompleted')
              ? sortOrder
              : undefined
          }
          sorter={(a: User, b: User) =>
            a.isMammographyProcessCompleted === b.isMammographyProcessCompleted
              ? 0
              : a.isMammographyProcessCompleted
              ? 1
              : -1
          }
          title={'Afsluttet'}
          dataIndex={'isMammographyProcessCompleted'}
          render={(isMammographyProcessCompleted: boolean) => (
            <Text type={isMammographyProcessCompleted ? 'success' : 'danger'}>
              {isMammographyProcessCompleted ? 'Ja' : 'Nej'}
            </Text>
          )}
          filters={[
            { text: 'Ja', value: true },
            { text: 'Nej', value: false },
          ]}
          onFilter={(value, record) =>
            value === !!record.isMammographyProcessCompleted
          }
        />
        <Column
          defaultSortOrder={
            sortQueryParams?.includes('riskCalculation') ? sortOrder : undefined
          }
          sorter={(a: User, b: User) =>
            !!a.riskCalculation === !!b.riskCalculation
              ? 0
              : a.riskCalculation
              ? 1
              : -1
          }
          title={'Beregnet'}
          dataIndex={'riskCalculation'}
          render={(riskCalculation: RiskCalculation) => (
            <Text type={riskCalculation ? 'success' : 'danger'}>
              {riskCalculation
                ? `${riskCalculation.tenYearRisk * 100 + '%'}`
                : 'Nej'}
            </Text>
          )}
          filters={[
            { text: 'Ja', value: true },
            { text: 'Nej', value: false },
          ]}
          onFilter={(value, record) => value === !!record.riskCalculation}
        />
        <Column
          title={'Risikogruppe'}
          dataIndex={'riskLevel'}
          render={(_, user: User) => (
            <Text>
              {user.riskCalculation
                ? getRiskLevelDa(user.riskCalculation.tenYearRisk)
                : 'Ingen'}
            </Text>
          )}
          filters={[
            { text: 'Lav', value: 'Lav' },
            { text: 'Mellem', value: 'Mellem' },
            { text: 'Forhøjet', value: 'Forhøjet' },
            { text: 'Høj', value: 'Høj' },
            { text: 'Ingen', value: 'Ingen' },
          ]}
          onFilter={(value, record: User) =>
            record.riskCalculation?.tenYearRisk
              ? value === getRiskLevelDa(record.riskCalculation?.tenYearRisk)
              : value === 'Ingen'
          }
        />
        <Column
          defaultSortOrder={
            sortQueryParams?.includes('released') ? sortOrder : undefined
          }
          sorter={(a: User, b: User) =>
            a.riskCalculation?.riskCalculationReleased ===
            b.riskCalculation?.riskCalculationReleased
              ? 0
              : a.riskCalculation?.riskCalculationReleased
              ? 1
              : -1
          }
          dataIndex={'released'}
          title={'Frigivet'}
          render={(_, user) => (
            <Text
              type={
                user.riskCalculation?.riskCalculationReleased
                  ? 'success'
                  : 'danger'
              }
            >
              {user.riskCalculation?.riskCalculationReleased ? 'Ja' : 'Nej'}
            </Text>
          )}
          filters={[
            { text: 'Ja', value: true },
            { text: 'Nej', value: false },
          ]}
          onFilter={(value, record) =>
            value === !!record.riskCalculation?.riskCalculationReleased
          }
        />
        <Column
          defaultSortOrder={
            sortQueryParams?.includes('note') ? sortOrder : undefined
          }
          sorter={(a: User, b: User) =>
            !!a.notes?.length === !!b.notes?.length
              ? 0
              : a.notes?.length
              ? 1
              : -1
          }
          title={'Note findes'}
          dataIndex={'note'}
          render={(_, user: User) => (
            <Text type={user.notes?.length ? 'success' : 'danger'}>
              {user.notes?.length ? 'Ja' : 'Nej'}
            </Text>
          )}
          filters={[
            { text: 'Ja', value: true },
            { text: 'Nej', value: false },
          ]}
          onFilter={(value, record) => value === !!record.notes?.length}
        />
        <Column
          title={'Sidste note skrevet af'}
          render={(_, user: User) => (
            <Text>
              {user.notes?.length
                ? [...user.notes]
                    .sort(function (a, b) {
                      if (a.created && b.created) {
                        return (
                          new Date(a.created).getTime() -
                          new Date(b.created).getTime()
                        );
                      }
                      return 0;
                    })
                    .at(0)?.createdBy?.name
                : 'Ingen noter'}
            </Text>
          )}
        />
        <Column
          title={'Risikoskema'}
          width={125}
          render={(_, user: User) => (
            <StyledTagContainer>
              {getTag(user.riskQuestionnaire?.status)}
            </StyledTagContainer>
          )}
          filters={[
            { text: 'Færdig', value: 'FilledOut' },
            { text: 'Klar', value: 'Open' },
            { text: 'Ikke klar', value: 'Closed' },
          ]}
          onFilter={(value, record) =>
            value === record.riskQuestionnaire?.status
          }
        />
        <Column
          title={'QoL 1'}
          width={100}
          render={(_, user: User) => (
            <StyledQOLContainer>
              {getTag(
                user.lifeQualityWorryQuestionnaires?.find(
                  (qol) => qol.index === 1
                )?.status
              )}
            </StyledQOLContainer>
          )}
          filters={[
            { text: 'Færdig', value: 'FilledOut' },
            { text: 'Klar', value: 'Open' },
            { text: 'Ikke klar', value: 'Closed' },
          ]}
          onFilter={(value, record) =>
            value ===
            record.lifeQualityWorryQuestionnaires?.find(
              (qol) => qol.index === 1
            )?.status
          }
        />
        <Column
          title={'QoL 2'}
          width={100}
          render={(_, user: User) => (
            <StyledQOLContainer>
              {getTag(
                user.lifeQualityWorryQuestionnaires?.find(
                  (qol) => qol.index === 2
                )?.status
              )}
            </StyledQOLContainer>
          )}
          filters={[
            { text: 'Færdig', value: 'FilledOut' },
            { text: 'Klar', value: 'Open' },
            { text: 'Ikke klar', value: 'Closed' },
          ]}
          onFilter={(value, record) =>
            value ===
            record.lifeQualityWorryQuestionnaires?.find(
              (qol) => qol.index === 2
            )?.status
          }
        />
        <Column
          title={'QoL 3'}
          width={100}
          render={(_, user: User) => (
            <StyledQOLContainer>
              {getTag(
                user.lifeQualityWorryQuestionnaires?.find(
                  (qol) => qol.index === 3
                )?.status
              )}
            </StyledQOLContainer>
          )}
          filters={[
            { text: 'Færdig', value: 'FilledOut' },
            { text: 'Klar', value: 'Open' },
            { text: 'Ikke klar', value: 'Closed' },
          ]}
          onFilter={(value, record) =>
            value ===
            record.lifeQualityWorryQuestionnaires?.find(
              (qol) => qol.index === 3
            )?.status
          }
        />
        <Column
          title={'QoL 4'}
          width={100}
          render={(_, user: User) => (
            <StyledQOLContainer>
              {getTag(
                user.lifeQualityWorryQuestionnaires?.find(
                  (qol) => qol.index === 4
                )?.status
              )}
            </StyledQOLContainer>
          )}
          filters={[
            { text: 'Færdig', value: 'FilledOut' },
            { text: 'Klar', value: 'Open' },
            { text: 'Ikke klar', value: 'Closed' },
          ]}
          onFilter={(value, record) =>
            value ===
            record.lifeQualityWorryQuestionnaires?.find(
              (qol) => qol.index === 4
            )?.status
          }
        />
      </PrsonalTable>
    </StyledPadminTable>
  );
}

export default PadminTable;

const StyledQOLContainer = styled.div`
  display: grid;
  gap: ${Sizes.STANDARD_GAP}rem;
`;

const StyledTagContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledPadminTable = styled.div`
  padding: ${Sizes.HUGE_GAP}rem;
  .ant-pagination-options {
    margin-right: ${Sizes.STANDARD_GAP}rem;
  }
`;

const StyledParticipantInfoContainer = styled.div`
  display: grid;
`;

const StyledInputContainer = styled.div`
  padding: ${Sizes.STANDARD_GAP}rem;
  display: flex;
  background-color: var(--neutral-1);
  border: 1px solid var(--neutral-5);
`;

const StyledInput = styled(Input)`
  .ant-input:last-child {
    max-width: 20rem;
    border-end-end-radius: 1rem;
    border-start-end-radius: 1rem;
    padding: 0.5rem;
  }
  .ant-input-group-addon:first-child {
    border-start-start-radius: 1rem;
    border-end-start-radius: 1rem;
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
`;

function getTag(status: QuestionnaireStatusEnum | undefined) {
  if (!status) return null;
  return (
    <StyledTagContainer>
      <Tag
        color={
          status === 'Closed' ? 'red' : status === 'Open' ? 'yellow' : 'green'
        }
      >
        {status === 'Closed'
          ? `Ikke klar`
          : status === 'Open'
          ? `Klar`
          : `Færdig`}
      </Tag>
    </StyledTagContainer>
  );
}

function getUploadProps(dispatch: Dispatch<AnyAction>): UploadProps {
  return {
    name: 'file',
    showUploadList: false,
    action: `${baseEnvironment.API_URL}/api/user/csv`,
    headers: {
      authorization: `Bearer ${window.sessionStorage.getItem(
        '@criipto-verify-react/session'
      )}`,
    },

    onChange(info) {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} blev uploaded korrekt`);
        dispatch(prsonalApi.util.invalidateTags(['user']));
      } else if (info.file.status === 'error') {
        message.error(
          info.file.response['exceptionCode']
            ? info.file.response['exceptionCode']
            : `${info.file.name} kunne ikke uploades.`
        );
      }
    },
  };
}

function getDropDownProps(
  onCSVDownloadClick: (csvType: CsvTypeEnum) => void
): MenuProps['items'] {
  return [
    {
      key: '1',
      label: 'BiRADS CSV',
      onClick() {
        onCSVDownloadClick(CsvTypeEnum.BIRADS);
      },
    },
    {
      key: '2',
      label: 'PRS CSV',
      onClick() {
        onCSVDownloadClick(CsvTypeEnum.PRS);
      },
    },
    {
      key: '3',
      label: 'Alle deltagere CSV',
      onClick() {
        onCSVDownloadClick(CsvTypeEnum.ALL);
      },
    },
  ];
}

function getBirthDayFromSSN(ssn: string) {
  if (ssn?.match('^\\d{10}$')?.length) {
    const day = parseInt(ssn?.substring(0, 2));
    const month = parseInt(ssn?.substring(2, 4));
    const year = getYearFromSSN(ssn);

    return format(new Date(year, month - 1, day), 'dd.MM.yyyy');
  }
  return 'Intet CPR nummer';
}

function getYearFromSSN(ssn: string): number {
  const year = parseInt(ssn?.substring(4, 6));

  const centuryDigit = parseInt(ssn?.substring(6, 7));

  if (centuryDigit >= 0 && centuryDigit <= 3) return 1900 + year;
  else if (centuryDigit === 4) {
    if (year >= 0 && year <= 36) return 2000 + year;
    else if (year > 36 && year <= 99) return 1900 + year;
  } else if (centuryDigit >= 5 && centuryDigit <= 8) {
    if (year >= 0 && year <= 57) return 2000 + year;
    else if (year > 57 && year <= 99) return 1800 + year;
  } else if (centuryDigit === 9) {
    if (year >= 0 && year <= 36) return 2000 + year;
    else if (year > 37 && year <= 99) return 1900 + year;
  }
  return 0;
}
