import styled from 'styled-components';
import { useState } from 'react';
import EQFooter from '../eq-footer/eq-footer';
import { Breakpoints } from '@prsonal/website/shared/utils';
import { PrsonalWrapper } from '@prsonal/website/shared/components';
import Modal from '../modal/modal';

export interface SurveyQuestionProps {
  title: string;
  subTitle?: string;
  options: { text: string; value: string }[];
  onChangePage: (increment: number) => void;
  onValueChange: (value: string) => void;
  currentChecked?: string;
}

export function SurveyQuestion(props: SurveyQuestionProps) {
  const [checked, setChecked] = useState(props.currentChecked ?? -1);
  const [showModal, setShowModal] = useState(false);

  const handleClick = (value: string) => {
    setChecked(value);
    props.onValueChange(value);
  };

  const onClick = (increment: number) => {
    if (checked === -1 && increment === 1) {
      setShowModal(true);
    } else {
      props.onChangePage(increment);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <PrsonalWrapper verticalPadding={0} horizontalPadding={0}>
      <Modal show={showModal} handleClose={handleClose}>
        {}
      </Modal>
      <StyledColumn>
        <StyledContent>
          <StyledParagraph>
            Du bedes trykke på DEN kasse, der bedst beskriver dit helbred I DAG
          </StyledParagraph>
          <StyledTitle>
            {props.title?.toUpperCase()}
            {props.subTitle && (
              <StyledSubtitle>{props.subTitle}</StyledSubtitle>
            )}
          </StyledTitle>
          {props.options?.map((option, index) => (
            <StyledCheckboxContainer key={index}>
              <StyledLabel onClick={() => handleClick(option.value)}>
                {option.text}
              </StyledLabel>
              <StyledInput
                type="checkbox"
                name={`Option ${index}`}
                value={option.value}
                checked={checked === option.value}
                onChange={() => handleClick(option.value)}
              />
            </StyledCheckboxContainer>
          ))}
        </StyledContent>
        <EQFooter onChangePage={onClick} />
      </StyledColumn>
    </PrsonalWrapper>
  );
}

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: calc(100vh - 80px);
  width: 100%;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding: 0 0.63rem;
  box-sizing: border-box;
  width: 100%;
  max-width: 75rem;
  margin-top: 1rem;
  @media screen and (min-width: ${Breakpoints.lg}px) {
    padding: 0 0;
    width: 80%;
    margin: auto;
    margin-top: 0rem;
  }
`;

const StyledParagraph = styled.p`
  font-family: Arial;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  margin-bottom: 1.56rem;
  @media screen and (min-width: ${Breakpoints.lg}px) {
    margin-bottom: 3.44rem;
    margin-top: 7rem;
  }
`;

const StyledTitle = styled.h3`
  display: flex;
  gap: 5px;
  margin: 0 0 0.69rem 0;
  font-family: Arial;
  font-weight: 700;
  line-height: 1.5rem;
  @media screen and (min-width: ${Breakpoints.lg}px) {
    margin-bottom: 0;
  }
`;

const StyledSubtitle = styled.p`
  font-family: Arial;
  font-size: 1rem;
  font-style: italic;
  font-weight: 400;
`;

const StyledCheckboxContainer = styled.div`
  width: 100%;
  max-width: 33rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  @media screen and (min-width: ${Breakpoints.lg}px) {
    padding: 1.25rem 0;
    margin-bottom: 0;
  }
`;

const StyledLabel = styled.label`
  cursor: pointer;
  font-family: Arial;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  flex-grow: 1;
  padding-right: 0.5rem;
`;

const StyledInput = styled.input`
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
  margin: 0;
`;

export default SurveyQuestion;
