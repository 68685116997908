import {
  Alcohol,
  CancerDiagnoses,
  FamilyMember,
  RiskQuestionnaire,
} from '@prsonal/website/shared/data-access';
import { RiskQuestionnaireProps } from '../risk-questionnaire-page.interface';
import { omit } from 'lodash';
import { extractAgeFromRange } from '@prsonal/shared/util-family-member';

export const showDataMissingMessage = (props: RiskQuestionnaireProps) => {
  return props.currentUserIsMedStudOrAdmin &&
    props.answers.created &&
    props.answers.updated
    ? new Date(props.answers.created) < new Date(props.answers.updated)
    : false;
};

export function isAlcoholFilledOut(
  alcoholAnswers: Partial<RiskQuestionnaire> | undefined,
) {
  if (alcoholAnswers?.alcohol == null) {
    return alcoholAnswers?.doYouDrink === false;
  }

  const isBaseQuestionAnswered = alcoholAnswers?.doYouDrink != null;

  type AlcoholKey = keyof Omit<Alcohol, 'id' | 'updated' | 'created'>;

  const answers = omit(alcoholAnswers?.alcohol, ['id', 'created', 'updated']);

  const isAlcoholIntakeFilledOutCorrect = !Object.keys(answers)
    .map((key) =>
      alcoholAnswers?.alcohol?.[key as AlcoholKey] == null
        ? true
        : alcoholAnswers?.alcohol?.[key as AlcoholKey]?.howOften === 'Aldrig'
          ? true
          : alcoholAnswers?.alcohol?.[key as AlcoholKey]?.howOften &&
              alcoholAnswers?.alcohol?.[key as AlcoholKey]?.amount
            ? true
            : false,
    )
    .includes(false);

  return isBaseQuestionAnswered && isAlcoholIntakeFilledOutCorrect;
}

export function isPeriodAnswersFilledOut(
  periodAnswers: Partial<RiskQuestionnaire> | undefined,
) {
  const ifNoIsAnswersFilledOut =
    periodAnswers?.period?.last12Months === 'Nej'
      ? !!periodAnswers?.period.ageEnded
      : true;
  return (
    periodAnswers?.period?.ageBegun &&
    periodAnswers?.period?.last12Months &&
    ifNoIsAnswersFilledOut
  );
}

export function isBirthControlAnswersFilledOut(
  birthControlAnswers: Partial<RiskQuestionnaire> | undefined,
) {
  const isBirthControlBaseQuestionAnswered =
    birthControlAnswers?.birthControl?.everTakenBirthControl != null;

  if (birthControlAnswers?.birthControl?.everTakenBirthControl === false)
    return true;

  return (
    isBirthControlBaseQuestionAnswered &&
    !!birthControlAnswers.birthControl?.years &&
    birthControlAnswers.birthControl.last2Years != null
  );
}

export function isHormoneTreatmentAnswersFilledOut(
  hormoneTreatmentAnswers: Partial<RiskQuestionnaire> | undefined,
) {
  if (
    hormoneTreatmentAnswers?.hormoneTreatment?.treated === 'Nej' ||
    hormoneTreatmentAnswers?.hormoneTreatment?.treated === 'Ved ikke'
  )
    return true;

  const isHormoneTreatmentBaseQuestionAnswered =
    hormoneTreatmentAnswers?.hormoneTreatment?.treated;

  return (
    isHormoneTreatmentBaseQuestionAnswered &&
    hormoneTreatmentAnswers.hormoneTreatment?.last5Years != null &&
    hormoneTreatmentAnswers.hormoneTreatment?.years
  );
}

export function isChildrenAnswersFilledOut(
  ChildrenAnswers: Partial<RiskQuestionnaire> | undefined,
) {
  if (ChildrenAnswers?.hasKids === false) return true;

  return (
    ChildrenAnswers?.hasKids &&
    !!ChildrenAnswers.familyMembers?.filter((member) => member.isChildOfTarget)
      ?.length &&
    !ChildrenAnswers.familyMembers.filter(
      (kid) =>
        kid.isChildOfTarget &&
        (kid.lifeState?.age == null || kid.gender == null),
    ).length
  );
}

export function areFamilyHistoryAnswersFilledOut(
  familyHistoryAnswers: Partial<RiskQuestionnaire> | undefined,
) {
  if (familyHistoryAnswers?.maleCancerHistory == null) return false;

  const familyMembers = familyHistoryAnswers.maleCancerHistory
    ? familyHistoryAnswers.familyMembers
    : familyHistoryAnswers.familyMembers?.filter(
        (member) => member.gender === 'Female',
      );

  /**
   * Age is valid for all family members when:
   * 1. Alive different from null or undefined.
   * 2. If family member had cancer OR age is other wise set:
   *  2.1 Age is not null or undefined
   *  2.2 Age is below 126
   *  2.3 Age is Above -1
   * 3. If family member is dead AND had cancer birth year should be different from null or undefined
   */

  const isAgeSet = familyMembers?.every((familyMember) => {
    if (familyMember.lifeState?.alive == null) return false;
    if (
      familyMember.lifeState.alive === false &&
      familyMember.lifeState.birthYear == null &&
      familyMember.hadCancer === 'Ja'
    )
      return false;

    if (familyMember.hadCancer === 'Ja' || familyMember.lifeState.age != null) {
      if (familyMember.lifeState?.age == null) return false;

      if (familyMember.lifeState.age > 125 || familyMember.lifeState.age < 0)
        return false;
    }
    return true;
  });

  const isCancerSet = familyMembers?.every(
    (familyMember) =>
      (familyMember.hadCancer === 'Ja' &&
        (familyMember.cancerDiagnoses?.breast != null ||
          familyMember.cancerDiagnoses?.otherBreast != null ||
          familyMember.cancerDiagnoses?.ovaries != null)) ||
      familyMember.hadCancer === 'Nej' ||
      familyMember.hadCancer === 'Ved ikke',
  );

  return (
    familyHistoryAnswers?.maleCancerHistory != null &&
    isAgeSet &&
    isCancerSet &&
    isCancerDiagnosisSet('breast', familyMembers) &&
    isCancerDiagnosisSet('otherBreast', familyMembers) &&
    isCancerDiagnosisSet('ovaries', familyMembers)
  );
}

function isCancerDiagnosisSet(
  cancer: keyof Pick<CancerDiagnoses, 'breast' | 'otherBreast' | 'ovaries'>,
  familyMembers: FamilyMember[] | undefined,
) {
  if (!familyMembers) return true;

  return familyMembers.every((familyMember) => {
    const cancerDiagnosis = familyMember.cancerDiagnoses?.[cancer];

    if (!cancerDiagnosis) return true;
    if (familyMember.lifeState?.age == null) return false;

    return (
      (cancerDiagnosis.approximateAge != null &&
        familyMember.lifeState.age >=
          extractAgeFromRange(cancerDiagnosis.approximateAge)) ||
      (cancerDiagnosis.preciseAge != null
        ? (cancerDiagnosis.preciseAge < 126 ||
            cancerDiagnosis.preciseAge >= 0) &&
          familyMember.lifeState.age >= cancerDiagnosis.preciseAge
        : false)
    );
  });
}

export function areAllRiskQAnswered(
  familyHistoryAnswers: Partial<RiskQuestionnaire> | undefined,
  riskQ: Partial<RiskQuestionnaire> | undefined,
) {
  const answers = [
    isAlcoholFilledOut(riskQ),
    isPeriodAnswersFilledOut(riskQ),
    isBirthControlAnswersFilledOut(riskQ),
    isHormoneTreatmentAnswersFilledOut(riskQ),
    isChildrenAnswersFilledOut(riskQ),
    areFamilyHistoryAnswersFilledOut(familyHistoryAnswers),
  ];
  return answers.every((answer) => answer);
}
