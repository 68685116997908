import styled from 'styled-components';
import { Typography, Button } from 'antd';
import { Sizes } from '@prsonal/website/shared/utils';
import { Breakpoints } from '@prsonal/website/shared/utils';

const { Text } = Typography;

export interface CantConsentYetProps {
  onContinue: () => void;
}

export function CantConsentYet(props: CantConsentYetProps) {
  return (
    <StyledCantConsentYet>
    <StyledCantConsentYetContainer>
      <Text>
        Du kan først give samtykke til at deltage senere, men hvis du trykker
        på denne knap, kan du få mere at vide om projektet, og hvad det
        betyder at deltage.{' '}
      </Text>
      <br />
      <br />
      <Text>
        Du har altid mulighed for 24 timers betænkningstid, inden du tilmelder
        dig.
      </Text>
      </StyledCantConsentYetContainer>
      <div>
        <Button type="primary" onClick={props.onContinue}>
          Fortsæt
        </Button>
      </div>
    </StyledCantConsentYet>
    );
}

const StyledCantConsentYet = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  position: fixed;

  @media screen and (max-width: ${Breakpoints.lg}px) {
    top: 15%;
    left: 5%;
    right: 5%;
  }

  @media screen and (min-width: ${Breakpoints.lg}px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const StyledCantConsentYetContainer = styled.div`
  max-width: 50rem;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  padding: ${Sizes.HUGE_GAP}rem;
`;
