import { RoleEnum } from '@prsonal/shared/utils/types';
import { useAuthentication } from '@prsonal/website/feature-auth';
import {
  Loader,
  PrsonalWrapper,
  QuestionnaireTable,
  UserInformation,
} from '@prsonal/website/shared/components';
import { Note, User, prsonalApi } from '@prsonal/website/shared/data-access';
import {
  PadminPageState,
  Sizes,
  useTypedNavigationState,
} from '@prsonal/website/shared/utils';
import { Button, GlobalToken, Typography, theme } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { BiradsInput } from './components/birads-input/birad-input';
import { BMIInput } from './components/bmi-input/bmi-input';
import { ContactNotes } from './components/contact-notes/contact-notes';
import { PRSInput } from './components/prs-input/prs-input';
import { StudyData } from './components/study-data/study-data';
import { useGetPadminPageProps } from './utils/padmin-page.hooks';

const { Text } = Typography;
const { useToken } = theme;

export function PadminPage() {
  const { token } = useToken();
  const user = useAuthentication().user;

  const { userId: participantId } = useParams();
  const navigate = useNavigate();
  const state = useTypedNavigationState(PadminPageState);

  const userIds = state?.userIds;
  const search = state?.search;
  const currentParticipantIdIndex = userIds?.findIndex(
    (id) => id === participantId
  );

  const [participant, questionnaires, fetchingProps, consent, notes] =
    useGetPadminPageProps(participantId, user?.role === 'Admin');

  const [updateUser] = prsonalApi.useUpdateOneUserMutation();
  const [randomizeUser, { isLoading: randomizingUser }] =
    prsonalApi.useRandomizeUserMutation();

  const [createNote] = prsonalApi.useCreateOneNoteMutation();

  function updateUserData(updatedUser: User) {
    if (participant?.id) updateUser({ id: participant.id, user: updatedUser });
  }

  function randomize() {
    if (participant?.id) randomizeUser({ id: participant.id });
  }

  function nextParticipant() {
    if (currentParticipantIdIndex == null || !userIds?.length) return;

    if (userIds.length - 1 >= currentParticipantIdIndex + 1) {
      navigate(`/padmin/${userIds[currentParticipantIdIndex + 1]}`, {
        state: { userIds, search },
      });
    } else {
      navigate(`/padmin/${userIds[0]}`, { state: { userIds, search } });
    }
  }

  function prevParticipant() {
    if (currentParticipantIdIndex == null || !userIds?.length) return;

    if (currentParticipantIdIndex - 1 >= 0) {
      navigate(`/padmin/${userIds[currentParticipantIdIndex - 1]}`, {
        state: { userIds, search },
      });
    } else {
      navigate(`/padmin/${userIds[userIds?.length - 1]}`, {
        state: { userIds, search },
      });
    }
  }

  return participant && user?.role ? (
    <PrsonalWrapper
      backgroundColor="var(--cyan-2)"
      fullHeightBackground
      verticalPadding={0}
      padminView
    >
      <StyledPadminPage>
        <StyledSider key={participant.id}>
          <UserInformation
            onUpdateUser={updateUserData}
            title="Deltagerinformation"
            user={participant}
            admin={user.role as RoleEnum}
            shadow={false}
          />
          <StudyData
            userRole={user.role as RoleEnum}
            user={participant}
            onRandomize={() => randomize()}
            isLoading={randomizingUser}
          />
          <ContactNotes
            notes={notes}
            onNoteCreate={(note: Note) =>
              createNote({ note: { ...note, userId: participant.id } })
            }
          />
        </StyledSider>
        <StyledContent token={token}>
          <StyledAnchorTag href={`/bruger${search ? search : ''}`}>
            <Text>Tilbage til deltager oversigt</Text>
          </StyledAnchorTag>
          <StyledInputContainer key={participant.id}>
            <BMIInput
              userRole={user.role as RoleEnum}
              user={participant}
              onValueChange={updateUserData}
              disabled={!consent?.prsonalConsent}
            />
            {user.role === 'Admin' ? (
              <BiradsInput user={participant} onValueChange={updateUserData} />
            ) : null}
            {user.role === 'Admin' ? (
              <PRSInput user={participant} onValueChange={updateUserData} />
            ) : null}
            <QuestionnaireTable
              questionnaires={questionnaires}
              isLoading={fetchingProps}
            />
          </StyledInputContainer>
          <StyledButtonsContainer>
            <StyledSaveButtonsContainer>
              <Button onClick={prevParticipant} disabled={!userIds?.length}>
                Forrige deltager
              </Button>
              <Button
                type="primary"
                onClick={nextParticipant}
                disabled={!userIds?.length}
              >
                Næste deltager
              </Button>
            </StyledSaveButtonsContainer>
          </StyledButtonsContainer>
        </StyledContent>
      </StyledPadminPage>
    </PrsonalWrapper>
  ) : (
    <Loader />
  );
}

const StyledPadminPage = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
`;

const StyledSider = styled.div`
  display: grid;
  gap: ${Sizes.LARGE_GAP}rem;
  padding: ${Sizes.HUGE_GAP}rem ${Sizes.STANDARD_GAP}rem;
  border: ${Sizes.SMALL_BORDER}rem solid var(--neutral-6);
  border-top: 0;
  background-color: var(--neutral-1);
`;

const StyledContent = styled.div<{ token: GlobalToken }>`
  background-color: var(--cyan-2);
  padding: ${Sizes.LARGE_GAP}rem ${Sizes.HUGE_GAP}rem;
`;

const StyledAnchorTag = styled.a`
  text-decoration: none;
`;

const StyledInputContainer = styled.div`
  display: grid;
  gap: ${Sizes.LARGE_GAP}rem;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${Sizes.EXTRA_LARGE_GAP}rem;
`;

const StyledSaveButtonsContainer = styled.div`
  display: flex;
  gap: ${Sizes.STANDARD_GAP}rem;
`;
