import { PrsonalWrapper } from '@prsonal/website/shared/components';
import { Button, Typography, Grid } from 'antd';
import styled from 'styled-components';
import SurveyTableRow from '../../components/survey-table-row/survey-table-row';
import { QualityWorryQuestionnaireProps } from '../../quality-worry-questionnaire.interfaces';
import { Breakpoints, Sizes } from '@prsonal/website/shared/utils';
import { Promis } from '@prsonal/website/shared/data-access';
const { Title, Text, Paragraph } = Typography;
const { useBreakpoint } = Grid;
/* eslint-disable-next-line */
export interface PromisProps extends QualityWorryQuestionnaireProps {}

const promisQuestions = [
  'Har jeg følt mig bange',
  'Har jeg haft svært ved at fokusere på andet end min angst',
  'Har mine bekymringer overvældet mig',
  'Har jeg følt mig urolig',
  'Har jeg følt mig nervøs',
  'Har jeg følt, at jeg havde brug for hjælp til at håndtere min angst',
  'Har jeg følt mig ængstelig',
  'Har jeg følt mig anspændt',
];

const promisFields = [
  'scared',
  'focus',
  'overwhelmed',
  'uneasy',
  'nervous',
  'neededHelp',
  'anxious',
  'tense',
];

const isDisabled = (promis?: Promis): boolean => {
  if (!promis) return true;
  return (
    promisQuestions.filter(
      (_, index) => promis[promisFields[index] as keyof Promis] == null
    ).length !== 0
  );
};

export function PromisSurvey(props: QualityWorryQuestionnaireProps) {
  const options = ['Aldrig', 'Sjældent', 'Somme tider', 'Ofte', 'Altid'];

  const screens = useBreakpoint();

  return (
    <PrsonalWrapper
      backgroundColor="#F7FBFA"
      verticalPadding={
        screens.lg ? Sizes.PRSONAL_VERTICAL_PADDING_DESKTOP : 0.3
      }
      horizontalPadding={
        screens.lg ? Sizes.PRSONAL_HORIZONTAL_PADDING_DESKTOP : 0.3
      }
      fullHeightBackground
    >
      <StyledPromisContainer>
        <StyledPromisHeader>
          <StyledTitle level={4}>Angst</StyledTitle>
        </StyledPromisHeader>
        <StyledPromisTable>
          <Paragraph>
            <MediumText>Instruktioner</MediumText>
            <Text>
              : Markér de felter, for hvert spørgsmål, der passer dig bedst
            </Text>
          </Paragraph>
          <SurveyTableRow
            title="Inden for de sidste 7 dage ..."
            optionText={options}
            index={0}
            surveyType="promis"
          />
          {promisQuestions.map((question, index) => (
            <SurveyTableRow
              key={index}
              title={question}
              optionText={options}
              index={index + 1}
              $footer={index === promisQuestions.length - 1}
              checked={
                props.answers?.promis?.[promisFields[index] as keyof Promis]
              }
              onValueChange={(value) =>
                props.onValueChange({
                  promis: {
                    ...props.answers?.promis,
                    [promisFields[index]]: value,
                  },
                })
              }
              surveyType="promis"
            />
          ))}
        </StyledPromisTable>
      </StyledPromisContainer>
      <StyledButtonContainer>
        <Button
          type="primary"
          onClick={() => props.onChangePage(1, false)}
          disabled={isDisabled(props.answers?.promis)}
        >
          Næste
        </Button>
      </StyledButtonContainer>
    </PrsonalWrapper>
  );
}

const StyledPromisContainer = styled.div`
  border: 1px solid var(--neutral-6, #bfbfbf);
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.25rem;
`;

const StyledPromisHeader = styled.div`
  border-bottom: 1px solid var(--neutral-5, #d9d9d9);
  @media screen and (min-width: ${Breakpoints.lg}px) {
    padding: 1.33rem 3.44rem;
  }
  padding: 0.625rem;
`;

const StyledTitle = styled(Title)`
  margin-top: 0;
`;

const StyledPromisTable = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${Breakpoints.lg}px) {
    padding: 1.25rem 3.44rem 3.44rem 3.44rem;
  }
  padding: 0.625rem;
`;

const MediumText = styled(Text)`
  font-weight: 500;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.25rem;
`;

export default PromisSurvey;
