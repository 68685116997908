import {
  DayOneControl,
  DayOneInterventions,
  PrsonalTimeline,
  Questionnaire,
  QuestionnaireControl,
  ResultMammography,
  ResultMammographyControl,
  RiskGroup,
  TimeLine,
  TimelineMobile,
} from '@prsonal/website/shared/components';
import { User } from '@prsonal/website/shared/data-access';
import { Breakpoints, Sizes, phoneNumber } from '@prsonal/website/shared/utils';
import { Collapse, CollapseProps, Tabs, Typography, Grid } from 'antd';
import { differenceInCalendarDays } from 'date-fns';
import styled from 'styled-components';
import ParticipantMenuCancerRisk from '../participant-menu-cancer-risk/participant-menu-cancer-risk';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;
export interface ParticipantMenuProps {
  questions: CollapseProps['items'];
  user: User;
}

function hasDaysPassed(days: number, inclusionDate: string) {
  return days <= differenceInCalendarDays(new Date(), new Date(inclusionDate));
}

export function ParticipantMenu(props: ParticipantMenuProps) {
  const screens = useBreakpoint();

  return (
    <StyledParticipantMenu>
      <StyledPrsonalTabs
        defaultActiveKey={
          props.user.riskCalculation?.riskCalculationReleased &&
          props.user?.group === 'InterventionGroup'
            ? '2'
            : '1'
        }
        type="card"
        items={[
          {
            label: 'Forskningsprojektets forløb',
            key: '1',
            children:
              props.user.group == null ? (
                <StyledNoGroupTimelineContainer>
                  <Title level={4}>
                    Forskningsprojektets forløb - tidslinje
                  </Title>
                  {screens.lg ? (
                    <TimeLine width={1300} height={700} />
                  ) : (
                    <img
                      src={TimelineMobile.default}
                      alt="Tidslinje"
                      style={{
                        width: '100%',
                        height: 'auto',
                        marginTop: '1rem',
                      }}
                    />
                  )}
                </StyledNoGroupTimelineContainer>
              ) : props.user.group === 'InterventionGroup' ? (
                <StyledParticipantTimeline
                  title="Forskningsprojektets forløb - tidslinje"
                  steps={[
                    {
                      illustration: (
                        <DayOneInterventions
                          filter={`grayscale(${
                            props.user.bmi &&
                            props.user.riskQuestionnaire?.status ===
                              'FilledOut' &&
                            props.user.lifeQualityWorryQuestionnaires?.find(
                              (loq) => loq.index === 1
                            )?.status === 'FilledOut'
                              ? '0'
                              : '1'
                          })`}
                        />
                      ),
                      description:
                        'Mammografi, spørgeskema, højde, vægt og blodprøve',
                      title: 'Mammografi m.m.',
                      stepName: 'Dag 1',
                      active: true,
                    },
                    {
                      illustration: (
                        <ResultMammography
                          filter={`grayscale(${
                            props.user.birads && props.user.prs ? '0' : '1'
                          })`}
                        />
                      ),
                      description: 'Du får svaret i e-Boks',
                      title: 'Svar på mammografi',
                      stepName: 'Dag 10',
                      active: hasDaysPassed(10, props.user.inclusionDate ?? ''),
                    },
                    {
                      illustration: (
                        <RiskGroup
                          filter={`grayscale(${
                            props.user.riskCalculation?.riskCalculationReleased
                              ? '0'
                              : '1'
                          })`}
                        />
                      ),
                      title: 'Svar på risikogruppe',
                      description:
                        'Du får en SMS/e-mail og skal logge ind med MitID for at få svar på din risiko og hvor ofte, du skal screenes',
                      stepName: '3 måneder',
                      active: hasDaysPassed(90, props.user.inclusionDate ?? ''),
                    },
                    {
                      illustration: (
                        <Questionnaire
                          filter={`grayscale(${
                            props.user.lifeQualityWorryQuestionnaires?.find(
                              (loq) => loq.index === 2
                            )?.status === 'FilledOut'
                              ? '0'
                              : '1'
                          })`}
                        />
                      ),
                      title: 'Spørgeskema',
                      description: 'Du får et spørgeskema med 19 spørgsmål',
                      stepName: '6 måneder',
                      active: hasDaysPassed(
                        180,
                        props.user.inclusionDate ?? ''
                      ),
                    },
                    {
                      illustration: (
                        <Questionnaire
                          filter={`grayscale(${
                            props.user.lifeQualityWorryQuestionnaires?.find(
                              (loq) => loq.index === 3
                            )?.status === 'FilledOut'
                              ? '0'
                              : '1'
                          })`}
                        />
                      ),
                      title: 'Spørgeskema',
                      description: 'Du får et spørgeskema med 19 spørgsmål',
                      stepName: '12 måneder',
                      active: hasDaysPassed(
                        365,
                        props.user.inclusionDate ?? ''
                      ),
                    },
                    {
                      illustration: (
                        <Questionnaire
                          filter={`grayscale(${
                            props.user.lifeQualityWorryQuestionnaires?.find(
                              (loq) => loq.index === 4
                            )?.status === 'FilledOut'
                              ? '0'
                              : '1'
                          })`}
                        />
                      ),
                      title: 'Spørgeskema',
                      description: 'Du får et spørgeskema med 19 spørgsmål',
                      stepName: '26 måneder',
                      active: hasDaysPassed(
                        800,
                        props.user.inclusionDate ?? ''
                      ),
                    },
                  ]}
                />
              ) : (
                <PrsonalTimeline
                  title="Forskningsprojektets forløb - tidslinje"
                  steps={[
                    {
                      illustration: (
                        <DayOneControl
                          filter={`grayscale(${
                            props.user.bmi &&
                            props.user.riskQuestionnaire?.status ===
                              'FilledOut' &&
                            props.user.lifeQualityWorryQuestionnaires?.find(
                              (loq) => loq.index === 1
                            )?.status === 'FilledOut'
                              ? '0'
                              : '1'
                          })`}
                        />
                      ),
                      description: 'Mammografi, spørgeskema, højde og vægt',
                      title: 'Mammografi m.m.',
                      stepName: 'Dag 1',
                      active: true,
                    },
                    {
                      illustration: (
                        <ResultMammographyControl
                          filter={`grayscale(${
                            props.user.birads && props.user.prs ? '0' : '1'
                          })`}
                        />
                      ),
                      description: 'Du får svaret i e-Boks',
                      title: 'Svar på mammografi',
                      stepName: 'Dag 10',
                      active: hasDaysPassed(10, props.user.inclusionDate ?? ''),
                    },
                    {
                      illustration: (
                        <QuestionnaireControl
                          filter={`grayscale(${
                            props.user.lifeQualityWorryQuestionnaires?.find(
                              (loq) => loq.index === 2
                            )?.status === 'FilledOut'
                              ? '0'
                              : '1'
                          })`}
                        />
                      ),
                      title: 'Spørgeskema',
                      description: 'Du får et spørgeskema med 19 spørgsmål',
                      stepName: '6 måneder',
                      active: hasDaysPassed(
                        180,
                        props.user.inclusionDate ?? ''
                      ),
                    },
                    {
                      illustration: (
                        <QuestionnaireControl
                          filter={`grayscale(${
                            props.user.lifeQualityWorryQuestionnaires?.find(
                              (loq) => loq.index === 3
                            )?.status === 'FilledOut'
                              ? '0'
                              : '1'
                          })`}
                        />
                      ),
                      title: 'Spørgeskema',
                      description: 'Du får et spørgeskema med 19 spørgsmål',
                      stepName: '12 måneder',
                      active: hasDaysPassed(
                        365,
                        props.user.inclusionDate ?? ''
                      ),
                    },
                    {
                      illustration: (
                        <QuestionnaireControl
                          filter={`grayscale(${
                            props.user.lifeQualityWorryQuestionnaires?.find(
                              (loq) => loq.index === 4
                            )?.status === 'FilledOut'
                              ? '0'
                              : '1'
                          })`}
                        />
                      ),
                      title: 'Spørgeskema',
                      description: 'Du får et spørgeskema med 19 spørgsmål',
                      stepName: '26 måneder',
                      active: hasDaysPassed(
                        800,
                        props.user.inclusionDate ?? ''
                      ),
                    },
                  ]}
                />
              ),
          },
          props.user?.group === 'InterventionGroup'
            ? {
                label: 'Din risikoberegning',
                key: '2',
                children: <ParticipantMenuCancerRisk user={props.user} />,
              }
            : //eslint-disable-next-line
              ({} as any),
          {
            label: 'Hyppige spørgsmål og svar',
            key: '3',
            children: (
              <StyledMenuContainer>
                <StyledFAQContainer>
                  <Title level={4}>Hyppige spørgsmål og svar</Title>
                  <Text type="secondary">
                    Der kan opstå mange spørgsmål, når man deltager i et
                    forskningsprojekt. Her har vi samlet nogle af de hyppigste.
                  </Text>
                  <StyledCollapse items={props.questions} />
                </StyledFAQContainer>
              </StyledMenuContainer>
            ),
          },
          {
            label: 'Hvem kan jeg tale med?',
            key: '4',
            children: (
              <StyledMenuContainer>
                <StyledAdviceAndHelpContainer>
                  <Title level={4}>Hvem kan jeg tale med?</Title>
                  <Text type="secondary">
                    Hvis du f.eks. gerne vil høre mere om selve projektet eller
                    om din risikoberegning, kan du ringe til projektets
                    telefonlinje.
                  </Text>
                  <StyledInfoContainer>
                    <Text strong>Telefon:</Text>
                    <Text>{phoneNumber}</Text>
                  </StyledInfoContainer>
                  <StyledInfoContainer>
                    <Text strong>Åbningstider:</Text>
                    <Text>8-20</Text>
                  </StyledInfoContainer>
                  <StyledCancerLineContainer>
                    <Text type="secondary">
                      Hvis du er bekymret for at udvikle brystkræft, kan du også
                      ringe til Kræftlinjen, hvor der både sidder læger,
                      diætister og psykologer klar til at tale med dig.
                    </Text>
                    <StyledInfoContainer>
                      <Text strong>Telefon:</Text>
                      <Text> 80 30 10 30</Text>
                    </StyledInfoContainer>
                    <StyledInfoContainer>
                      <Text strong>Åbningstider:</Text>
                      <Text></Text>
                      <Text>Hverdage</Text>
                      <Text>9-21</Text>
                      <Text>Lørdag-søndag</Text>
                      <Text>12-17</Text>
                      <Text>Helligdage</Text>
                      <Text>Lukket</Text>
                    </StyledInfoContainer>
                  </StyledCancerLineContainer>
                </StyledAdviceAndHelpContainer>
              </StyledMenuContainer>
            ),
          },
        ]}
      />
    </StyledParticipantMenu>
  );
}

export default ParticipantMenu;

const StyledParticipantMenu = styled.div`
  margin-bottom: ${Sizes.HUGE_GAP}rem;
`;

const StyledParticipantTimeline = styled(PrsonalTimeline)`
  min-height: ${Sizes.MENU_HEIGHT}rem;
`;

const StyledAdviceAndHelpContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes.LARGE_GAP}rem;
`;

const StyledMenuContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledInfoContainer = styled.div`
  @media screen and (min-width: ${Breakpoints.lg}px) {
    width: 50%;
  }
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${Sizes.STANDARD_GAP}rem;
`;

const StyledCancerLineContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes.LARGE_GAP}rem;
`;

const StyledFAQContainer = styled.div`
  @media screen and (min-width: ${Breakpoints.lg}px) {
    align-items: flex-start;
    min-height: ${Sizes.MENU_HEIGHT}rem;
    min-width: 50rem;
    max-width: 50rem;
  }
  display: flex;
  flex-direction: column;
  gap: ${Sizes.STANDARD_GAP}rem;
`;

const StyledCollapse = styled(Collapse)`
  @media screen and (min-width: ${Breakpoints.lg}px) {
    width: 100%;
  }
`;

const StyledPrsonalTabs = styled(Tabs)`
  .ant-tabs-tab {
    border-radius: 2px 2px 0px 0px !important;
  }
`;

const StyledNoGroupTimelineContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
