import styled from 'styled-components';
import EQButton from '../eq-button/eq-button';
import { Breakpoints } from '@prsonal/website/shared/utils';

export interface EQFooterProps {
  onChangePage: (increment: number) => void;
  onPageBack?: () => void;
}

export function EQFooter(props: EQFooterProps) {
  return (
    <StyledFooter>
      <StyledFooterParagraph>
        © EuroQol Research Foundation. EQ-5D™ is a trade mark of the EuroQol
        Research Foundation. Denmark (Danish) v1.0
      </StyledFooterParagraph>
      <StyledButtonBox>
        <EQButton
          previous
          onClick={
            props.onPageBack ? props.onPageBack : () => props.onChangePage(-1)
          }
        />
        <EQButton next onClick={() => props.onChangePage(1)} />
      </StyledButtonBox>
    </StyledFooter>
  );
}

const StyledButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.3125rem 1.87rem;
  gap: 0.625rem;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e2e2e2;
  width: 100%;
  max-width: 75rem;
  box-sizing: border-box;
  @media screen and (min-width: ${Breakpoints.lg}px) {
    padding: 0.63rem 1.87rem 3.44rem 1.87rem;
    justify-content: space-between;
    width: 80%;
  }
`;

const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
`;

const StyledFooterParagraph = styled.p`
  text-align: center;
  font-size: 0.6875rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.25rem;
  margin-bottom: 0.31rem;
  @media screen and (min-width: ${Breakpoints.lg}px) {
    margin-bottom: 2.5rem;
  }
`;

export default EQFooter;
