import styled from 'styled-components';
import { Layout, Col, Row, Typography, Button, Grid } from 'antd';
import icon from '../../icons/icon.svg';
import ContentLinkButton from './components/content-link-button/content-link-button';
import { PrsonalWrapper } from '@prsonal/website/shared/components';
import { Breakpoints } from '@prsonal/website/shared/utils';

const { Content } = Layout;
const { Title } = Typography;
const { Text } = Typography;
const { useBreakpoint } = Grid;

const ButtonTextArray = [
  {
    text: 'Forskningsprojektets formål',
    link: '#study-purpose',
  },
  {
    text: 'Hvad indebærer det at deltage?',
    link: '#participation-description',
  },
  {
    text: 'Udregningen af risiko for brystkræft',
    link: '#risk-group-section',
  },
  {
    text: 'Fordele og ulemper ved at deltage i PRSONAL',
    link: '#pros-and-cons',
  },
  {
    text: 'Videnskaben bag PRSONAL',
    link: '#science-behind',
  },
  {
    text: 'Du kan altid frammelde dig igen',
    link: '#unsubscribe',
  },
];

export function ContentLinkSection() {
  const screens = useBreakpoint();

  return (
    <div id="content-section">
      {screens.lg ? (
        <PrsonalWrapper>
          <Title level={2}>
            <StyledMagnifyingIcon src={icon} /> Indhold
          </Title>
          <StyledGap />
          <Row
            justify="space-between"
            align="middle"
            gutter={{ lg: 62, xl: 104 }}
          >
            {ButtonTextArray.map(({ text, link }, index) => (
              <Col lg={8}>
                <ContentLinkButton text={text} link={link} />
              </Col>
            ))}
          </Row>
        </PrsonalWrapper>
      ) : (
        <StyledMobileBox>
          <Row>
            <Col xs={3}>
              <StyledMagnifyingIcon src={icon} />
            </Col>
            <Col xs={9}>
              <Title level={5}>Indhold</Title>
            </Col>
          </Row>
          <StyledGap />
          {ButtonTextArray.map(({ text, link }, index) => (
            <StyledButton href={link} type="link">
              <StyledSmallHeadingsMobile>{text}</StyledSmallHeadingsMobile>
            </StyledButton>
          ))}
        </StyledMobileBox>
      )}
    </div>
  );
}

export default ContentLinkSection;

const StyledMagnifyingIcon = styled.img`
  width: 1.63894rem;
  height: 1.58813rem;
`;

const StyledMobileBox = styled(Content)`
  border-top: 3px solid var(--cyan-8);
  background: var(--neutral-1);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  margin: 1.44rem;
  padding-top: 1.06rem;
  padding-bottom: 1.06rem;
  padding-left: 1.25rem;
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled(Button)`
  white-space: break-spaces;
  text-align: left;
  display: flex;
  flex-basis: 100%;
`;

const StyledSmallHeadingsMobile = styled(Text)`
  color: var(--cyan-8);
  word-break: break-word;
`;

const StyledGap = styled.div`
  @media screen and (max-width: ${Breakpoints.lg}px) {
    padding-bottom: 0.5rem;
  }
  @media screen and (min-width: ${Breakpoints.lg}px) {
    padding-top: 2.125rem;
  }
`;
