import styled from 'styled-components';
import { List, Layout, Typography } from 'antd';
import { ConLogo, ProLogo } from '../../../../icons';
const { Content } = Layout;
const { Title, Paragraph } = Typography;

const data = [
  'Kvinder med lav risiko bliver undersøgt sjældnere og udsættes derfor ikke for unødvendig røntgenstråling',
  'Kvinder med høj risiko bliver undersøgt oftere. Derfor har de mulighed for at få stillet diagnosen tidligere, hvor sygdommen ikke har spredt sig og derfor kræver mindre behandling',
  'Det kan vise sig, at nogle få kvinder i gruppen med lav risiko alligevel udvikler brystkræft',
  'Nogle kvinder med høj risiko kan blive følelsesmæssigt påvirket af at få at vide, at deres risiko er høj',
];

export function InfoBox() {
  return (
    <StyledInfoBox>
      <StyledInfoBoxHeader>
        <Title level={4}>Personlig brystkræftscreening</Title>
      </StyledInfoBoxHeader>
      <StyledContent>
        <List
          size="small"
          dataSource={data}
          renderItem={(item, index) => (
            <List.Item>
              <StyledListItem>
                {data.length / 2 > index ? (
                  <ProLogo width={'36'} height={'36'} />
                ) : (
                  <ConLogo width={'36'} height={'36'} />
                )}
                <Paragraph>{item}</Paragraph>
              </StyledListItem>
            </List.Item>
          )}
        />
      </StyledContent>
    </StyledInfoBox>
  );
}

const StyledInfoBox = styled.div`
  border: 1px solid var(--neutral-5);
  background: var(--neutral-1);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
`;

const StyledInfoBoxHeader = styled.div`
  border-bottom: 1px solid var(--neutral-5);
  background: var(--neutral-1);
  padding: 0.75rem 1rem;
`;

const StyledContent = styled(Content)``;

const StyledListItem = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 2fr;
  gap: 0.5rem;
`;

export default InfoBox;
