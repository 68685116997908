import styled from 'styled-components';
import {
  Button,
  Checkbox,
  Col,
  GlobalToken,
  Input,
  Row,
  theme,
  Typography,
} from 'antd';
import { User } from '@prsonal/website/shared/data-access';
import { useState } from 'react';
import { Sizes } from '@prsonal/website/shared/utils';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';

const { useToken } = theme;
const { Text } = Typography;

export interface FillOutInfoProps {
  user: User;
  onUserUpdate: (user: User) => void;
}

export function FillOutInfo(props: FillOutInfoProps) {
  const { token } = useToken();
  const [partialUser, setPartialUser] = useState<Pick<User, 'email' | 'phone'>>(
    { email: props.user.email, phone: props.user.phone }
  );
  const [checkedConsent, setCheckedConsent] = useState(false);
  const [isEmailCorrectFormat, setIsEmailCorrectFormat] = useState(true);
  const [isPhoneNumberCorrectLength, setIsPhoneNumberCorrectLength] =
    useState(true);

  return (
    <StyledFillOutInfoWrapper>
      <StyledFillOutInfoContainer>
        <StyledFillOutInfo>
          <StyledTitleContainer token={token}>
            Personlige data
          </StyledTitleContainer>
          <StyledInputContainer>
            <Row justify={'space-evenly'}>
              <StyledCol>
                <Text strong>E-mail</Text>
                <Input
                  inputMode="email"
                  prefix={<MailOutlined />}
                  status={!isEmailCorrectFormat ? 'error' : ''}
                  onFocus={() => setIsEmailCorrectFormat(true)}
                  onBlur={() =>
                    setIsEmailCorrectFormat(
                      (partialUser?.email?.includes('@') &&
                        partialUser?.email?.includes('.')) ??
                        false
                    )
                  }
                  value={partialUser?.email}
                  placeholder="Indtast e-mail"
                  onChange={(e) =>
                    setPartialUser({ ...partialUser, email: e.target.value })
                  }
                />
              </StyledCol>
              <StyledCol>
                <Text strong>Telefon</Text>
                <Input
                  inputMode="tel"
                  prefix={<PhoneOutlined />}
                  status={!isPhoneNumberCorrectLength ? 'error' : ''}
                  onFocus={() => setIsPhoneNumberCorrectLength(true)}
                  onBlur={() =>
                    setIsPhoneNumberCorrectLength(
                      (partialUser?.phone?.length ?? 0) >= 8
                    )
                  }
                  value={partialUser?.phone}
                  placeholder="Indtast tlf.nr."
                  onChange={(e) =>
                    setPartialUser({ ...partialUser, phone: e.target.value })
                  }
                />
              </StyledCol>
            </Row>
            <StyledTextContainer>
              <Text>
                For at oprette dig med MitID skal du have læst og forstået vores
                <a href="/privat-livs-politik" target="_blank">
                  {' '}
                  privatlivspolitik
                </a>
                .
              </Text>
              <Checkbox
                checked={checkedConsent}
                onChange={() => setCheckedConsent(!checkedConsent)}
              >
                Jeg har læst og forstået privatlivspolitikken
              </Checkbox>
            </StyledTextContainer>
          </StyledInputContainer>
        </StyledFillOutInfo>
        <StyledButtonContainer>
          <Button
            disabled={
              !partialUser?.email ||
              !partialUser?.email?.includes('@') ||
              !partialUser?.email?.includes('.') ||
              !partialUser?.phone ||
              partialUser?.phone.length < 8 ||
              !checkedConsent
            }
            type="primary"
            onClick={() => props.onUserUpdate(partialUser as User)}
          >
            Gem
          </Button>
        </StyledButtonContainer>
      </StyledFillOutInfoContainer>
    </StyledFillOutInfoWrapper>
  );
}

const StyledFillOutInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const StyledFillOutInfoContainer = styled.div`
  max-width: 49rem;
  width: 100%;
`;

const StyledTextContainer = styled.div`
  padding: ${Sizes.EXTRA_LARGE_GAP}rem;
  display: flex;
  flex-direction: column;
  gap: ${Sizes.STANDARD_GAP}rem;
`;

const StyledFillOutInfo = styled.div`
  border: ${Sizes.SMALL_BORDER}rem solid var(--neutral-6);
`;

const StyledTitleContainer = styled.div<{ token: GlobalToken }>`
  background: ${(props) => props.token.colorFillSecondary};
  color: var(--cyan-8);
  font-weight: 500;
  padding: 1rem;
`;

const StyledInputContainer = styled.div`
  border-top: ${Sizes.SMALL_BORDER}rem solid var(--neutral-6);
  padding: ${Sizes.LARGE_GAP}rem ${Sizes.LARGE_GAP}rem;
`;

const StyledCol = styled(Col)`
  display: grid;
  padding: ${Sizes.STANDARD_GAP}rem;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${Sizes.LARGE_GAP}rem;
`;
