export const Sizes = {
  PRSONAL_WIDTH_DESKTOP: 90,
  PADMIN_WIDTH: 120,
  PRSONAL_WIDTH_MOBILE: 30,
  MENU_HEIGHT: 31.875,
  FONT_SIZE_HEADING_1: 38,
  FONT_SIZE_HEADING_2: 30,
  FONT_SIZE_HEADING_3: 24,
  FONT_SIZE_HEADING_4: 20,
  FONT_SIZE_HEADING_5: 16,
  SUBTITLE_SIZE: 0.75,
  LARGE_GAP: 1.25,
  STANDARD_GAP: 0.625,
  PRSONAL_HORIZONTAL_PADDING_DESKTOP: 4.375,
  PRSONAL_VERTICAL_PADDING_DESKTOP: 5,
  PRSONAL_HORIZONTAL_PADDING_MOBILE: 1.5625,
  PRSONAL_VERTICAL_PADDING_MOBILE: 2.5,
  SMALL_BORDER: 0.063,
  HUGE_GAP: 3.4375,
  EXTRA_LARGE_GAP: 1.5,
  PRSONAL_HORIZONTAL_GAP: 4.65625,
  PRSONAL_VERTICAL_GAP: 5,
  BUTTON_PADDING_HORIZONTAL: 0.9375,
  BUTTON_PADDING_VERTICAL: 0.1875,
  PDF_WIDTH: 830,
};
