import { Alert } from 'antd';
import { ReactNode } from 'react';
import styled from 'styled-components';

export function AlertContainer({ text }: { text?: ReactNode }) {
  return (
    <StyledAlertContainer>
      <Alert
        message={text ?? 'Der mangler at blive udfyldt data på denne side'}
        type="error"
      />
    </StyledAlertContainer>
  );
}

const StyledAlertContainer = styled.div`
  display: flex;
  max-width: 40rem;
  padding: 1rem 0;
`;
