import { RequestQueryBuilder } from '@dataui/crud-request';
import { Loader, PrsonalWrapper } from '@prsonal/website/shared/components';
import { User, prsonalApi } from '@prsonal/website/shared/data-access';
import PadminTable from './components/padmin-table/padmin-table';
import { useNavigate } from 'react-router-dom';
import { CsvTypeEnum, RoleEnum } from '@prsonal/shared/utils/types';
import { downloadFile } from '@prsonal/website/shared/utils';
import { useAuthentication } from '@prsonal/website/feature-auth';

export function PadminList() {
  const navigate = useNavigate();
  const [downloadCsv] = prsonalApi.useLazyGetUserCsvQuery();
  const user = useAuthentication().user;

  //Use query builder for better type safety
  const query = RequestQueryBuilder.create().search({
    role: { $or: { $isnull: true, $eq: 'Participant' } },
  }).queryObject;

  const { data: users } = prsonalApi.useGetManyUserQuery({
    join: [
      'riskQuestionnaire',
      'lifeQualityWorryQuestionnaires',
      'notes',
      'riskCalculation',
    ],
    sort: ['created,DESC'],
    s: query.s,
  });

  function onRowClick(
    user: User,
    filteredUsers: User[] | undefined,
    search: string
  ) {
    navigate(`/padmin/${user.id}`, {
      state: {
        userIds:
          filteredUsers?.map((user) => user.id) ??
          users?.map((user) => user.id),
        search,
      },
    });
  }

  function onCSVDownloadClick(csvType: CsvTypeEnum) {
    downloadCsv({ type: csvType })
      .unwrap()
      .then((data) => {
        data &&
          downloadFile(
            `${csvType}-tabel-${new Date()}.csv`,
            data.csv,
            'text/csv'
          );
      });
  }

  return (
    <PrsonalWrapper
      backgroundColor="var(--cyan-2)"
      fullHeightBackground
      verticalPadding={0}
      padminView
    >
      {users && user?.role ? (
        <PadminTable
          userRole={user.role as RoleEnum}
          users={users}
          onRowClick={onRowClick}
          onCSVDownloadClick={onCSVDownloadClick}
        />
      ) : (
        <Loader />
      )}
    </PrsonalWrapper>
  );
}

export default PadminList;
