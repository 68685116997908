import styled from 'styled-components';
import EQButton from '../eq-button/eq-button';
import { PrsonalWrapper } from '@prsonal/website/shared/components';
import closeIcon from '../../assets/Close.svg';
import { Breakpoints } from '@prsonal/website/shared/utils';
import { Grid } from 'antd';
const { useBreakpoint } = Grid;

export interface ModalProps {
  handleClose: () => void;
  show: boolean;
  children: React.ReactNode;
}

export function Modal(props: ModalProps) {
  const screens = useBreakpoint();

  return (
    <StyledModal display={props.show ? 'flex' : 'none'}>
      <PrsonalWrapper
        horizontalPadding={screens.lg ? 0 : 0.63}
        backgroundColor="rgba(0, 0, 0, 0.0)"
      >
        <StyledModalMain>
          <StyledHeader>
            <StyledImage
              src={closeIcon}
              alt="close"
              onClick={props.handleClose}
            />
          </StyledHeader>
          <StyledContent>
            Dette indhold er blankt, udfyld venligst resten.
          </StyledContent>
          <StyledFooter>
            <EQButton onClick={props.handleClose} />
          </StyledFooter>
        </StyledModalMain>
      </PrsonalWrapper>
    </StyledModal>
  );
}

const StyledModal = styled.div<{ display: 'flex' | 'none' }>`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  ${(props) => `display: ${props.display}`};
`;

const StyledModalMain = styled.div`
  display: flex;
  background: white;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 0.125rem;
  max-width: 31.25rem;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  @media screen and (min-width: ${Breakpoints.lg}px) {
    width: 80%;
    margin: auto;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
  box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
  padding: 1rem 1.5rem;
`;

const StyledImage = styled.img`
  cursor: pointer;
`;

const StyledContent = styled.p`
  font-family: Arial;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  padding: 0.1rem 2.15625rem;
`;

const StyledFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  justify-content: flex-end;
  align-items: center;
  padding: 0.625rem 1rem;
`;

export default Modal;
