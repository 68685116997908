import { PrsonalWrapper } from '@prsonal/website/shared/components';
import { Col, Row, Typography, Grid } from 'antd';
import Image from '../../icons/unsubscribe-img.jpg';
import styled from 'styled-components';

const { Title } = Typography;
const { Text } = Typography;
const { useBreakpoint } = Grid;

export function Unsubscribe() {
  const screens = useBreakpoint();

  return (
    <div id="unsubscribe">
      {screens.xl ? (
        <PrsonalWrapper>
          <Row gutter={110}>
            <Col span={12}>
              <Title level={2}>Du kan altid framelde dig igen</Title>
              <br />
              <Text>
                Selvom du har sagt ja til at deltage i forskningsprojektet
                PRSONAL, kan du på et hvilket som helst tidspunkt framelde dig
                igen. Uanset hvilken af de 4 risikogrupper du er kommet i.
                <br />
                <br />
                Derefter vil du blive inviteret til brystkræftscreening hvert
                andet år, ligesom før du kom med i forskningsprojektet.
              </Text>
            </Col>

            <Col span={12}>
              <StyledImage src={Image} alt="" />
            </Col>
          </Row>
        </PrsonalWrapper>
      ) : (
        <PrsonalWrapper>
          <Title level={4}>Du kan altid framelde dig igen</Title>
          <br />
          <Text>
            Selvom du har sagt ja til at deltage i forskningsprojektet PRSONAL,
            kan du på et hvilket som helst tidspunkt framelde dig igen. Uanset
            hvilken af de 4 risikogrupper du er kommet i.
            <br />
            <br />
            Derefter vil du blive inviteret til brystkræftscreening hvert andet
            år, ligesom før du kom med i forskningsprojektet.
          </Text>
        </PrsonalWrapper>
      )}
    </div>
  );
}

export default Unsubscribe;

const StyledImage = styled.img`
  width: 36.21688rem;
  height: 24.14456rem;
  flex-shrink: 0;
  padding-right: 0;
`;
