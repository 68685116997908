import Title from 'antd/es/typography/Title';
import { Grid, Typography } from 'antd';
import styled from 'styled-components';
import { Breakpoints } from '@prsonal/website/shared/utils';

const { useBreakpoint } = Grid;
const { Paragraph, Text } = Typography;

export function TextContent() {
  const screens = useBreakpoint();
  return (
    <>
      <Title level={screens.lg ? 2 : 4}>
        Udregning af risiko for brystkræft
      </Title>
      <StyledGap />
      <Paragraph>
        Når alle data er indsamlet (mammografi, højde/vægt, blodprøve, livsstil
        og familiehistorie med brystkræft), bliver de analyseret.
        {screens.lg ? <br /> : ' '}
        <StyledBold>
          Efter ca. 3 måneder får du svar på, hvilken af de 4 risikogrupper du
          befinder dig i: Lav, mellem, forhøjet eller høj.
        </StyledBold>
      </Paragraph>
      {screens.lg ? (
        <Title level={4}>De 4 risikogrupper</Title>
      ) : (
        <StyledHeadingMobile>De 4 risikogrupper</StyledHeadingMobile>
      )}
      <StyledGap />
    </>
  );
}

export default TextContent;

const StyledHeadingMobile = styled(Text)`
  color: var(--cyan-9, #00474f);
  font-weight: 500;
`;

const StyledGap = styled.div`
  @media screen and (max-width: ${Breakpoints.lg}px) {
    padding-bottom: 0.5rem;
  }
  @media screen and (min-width: ${Breakpoints.lg}px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 1.6875rem;
  }
`;

const StyledBold = styled(Text)`
  color: var(--cyan-9);
  font-weight: 700;
`;
