import styled from 'styled-components';
import { Typography, Grid } from 'antd';
import PrsonalStep, { PrsonalStepProps } from './prsonal-step/prsonal-step';
import { ArrowDown } from '../images';
import { Breakpoints } from '@prsonal/website/shared/utils';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

export interface PrsonalTimelineProps {
  title: string;
  description?: string;
  parallelTimeline?: PrsonalStepProps[];
  steps: PrsonalStepProps[];
}

export function PrsonalTimeline(props: PrsonalTimelineProps) {
  const { lg } = useBreakpoint();
  return (
    <StyledPrsonalTimeline>
      <StyledTitleContainer>
        <Title level={3}>{props.title}</Title>
      </StyledTitleContainer>
      <StyledDescriptionContainer>
        <Text>{props.description}</Text>
      </StyledDescriptionContainer>
      <StyledStepContainer
        parallelTimeline={props.parallelTimeline ? true : false}
      >
        {props.steps?.map((step, index) => (
          <StyledProgressContainer key={index}>
            <PrsonalStep
              illustration={step.illustration}
              title={step.title}
              description={step.description}
              stepName={step.stepName}
              active={step.active}
            />
            {lg ? (
              <StyledProgressDot active={step.active} />
            ) : (
              <StyledMobileProgressContainer>
                <Title level={4}>{step.stepName}</Title>
                <StyledProgressDot active={step.active} />
                <StyledProgressBar active={step.active ? 100 : 0} />
                <StyledMobileIconContainer>
                  <ArrowDown fill={step.active ? '#006d75' : '#b8b8b8'} />
                </StyledMobileIconContainer>
              </StyledMobileProgressContainer>
            )}
          </StyledProgressContainer>
        ))}
      </StyledStepContainer>
      {lg ? (
        <StyledProgressBar
          active={
            (props.steps?.filter((step) => step.active)?.length /
              props.steps?.length) *
              100 || 0
          }
        />
      ) : null}
    </StyledPrsonalTimeline>
  );
}

export default PrsonalTimeline;

const StyledPrsonalTimeline = styled.div`
  display: grid;
`;

const StyledMobileIconContainer = styled.div`
  position: relative;
  top: -0.5rem;
`;

const StyledMobileProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledDescriptionContainer = styled.div`
  padding: 0.313rem 1.563rem;
`;

const StyledStepContainer = styled.div<{ parallelTimeline: boolean }>`
  @media screen and (min-width: ${Breakpoints.lg}px) {
    display: flex;
    justify-content: space-evenly;
  }
`;

const StyledProgressContainer = styled.div`
  @media screen and (min-width: ${Breakpoints.lg}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 12.5rem;
    position: relative;
  }
  display: grid;
  grid-template-columns: 2fr 1fr;
`;

const StyledProgressDot = styled.div<{
  active: boolean;
}>`
  @media screen and (min-width: ${Breakpoints.lg}px) {
    bottom: -1.2rem;
  }
  z-index: 1;
  position: relative;
  background-color: white;
  border: 0.313rem solid
    ${(props) => (props.active ? 'var(--cyan-8, #006d75)' : '#B8B8B8')};
  border-radius: 100%;
  height: 2rem;
  width: 2rem;
`;

const StyledProgressBar = styled.div<{ active: number }>`
  @media screen and (min-width: ${Breakpoints.lg}px) {
    height: 0.375rem;
    width: 100%;
  }
  height: 60%;
  width: 0.175rem;
  ${(props) =>
    props.active >= 50
      ? `background: linear-gradient(
    to right,
    #006d75,
    #006d75 ${props.active}%,
    #b8b8b8,
    #b8b8b8 ${100 - props.active}%
  )`
      : `background: linear-gradient(
    to left,
    #b8b8b8,
    #b8b8b8 ${100 - props.active}%,
    #006d75 ${props.active}%,
    #006d75
  )`};
`;
