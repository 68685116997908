import styled from 'styled-components';
import { Typography } from 'antd';
import React, { ReactNode } from 'react';
import { Breakpoints, Sizes } from '@prsonal/website/shared/utils';
const { Title, Text } = Typography;
/* eslint-disable-next-line */
export interface PrsonalStepProps {
  illustration: ReactNode;
  title: string;
  description: string;
  stepName: string;
  active: boolean;
}

export function PrsonalStep(props: PrsonalStepProps) {
  return (
    <StyledPrsonalStep>
      <StyledContent>{props.illustration}</StyledContent>
      <StyledTitle>
        <Title level={5}>{props.title}</Title>
      </StyledTitle>
      <StyledDescription>{props.description}</StyledDescription>
      <StyledStepName>
        <Title level={4}>{props.stepName}</Title>
      </StyledStepName>
    </StyledPrsonalStep>
  );
}

export default PrsonalStep;

const StyledPrsonalStep = styled.div`
  @media screen and (min-width: ${Breakpoints.lg}px) {
    flex-direction: column;
    display: flex;
  }
  flex-grow: 1;
`;

const StyledContent = styled.div`
  @media screen and (min-width: ${Breakpoints.lg}px) {
    justify-content: center;
  }
  display: flex;
  align-items: end;
  height: 10rem;
`;

const StyledTitle = styled.div`
  @media screen and (min-width: ${Breakpoints.lg}px) {
    display: flex;
    justify-content: center;
    text-align: center;
  }
`;

const StyledStepName = styled.div`
  @media screen and (min-width: ${Breakpoints.lg}px) {
    display: flex;
    justify-content: center;
  }
  display: none;
`;

const StyledDescription = styled(Text)`
  display: flex;
  flex-grow: 1;
  @media screen and (min-width: ${Breakpoints.lg}px) {
    text-align: center;
    padding: 0.313rem ${Sizes.LARGE_GAP}rem 0px;
  }
`;
